import { useMemo, useState } from "react";
import Login from "src/models/Login";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoginConsumer from "src/services/login";
import { iUpdatePassword } from "src/interfaces/updatePassword";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import { Hash } from "src/functions/hash";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function usePasswordReset() {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const query = useQuery();

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmePassword, setShowConfirmePassword] =
    useState<boolean>(false);
  const [validationErrorPassword, setValidationErrorPassword] =
    useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmePassword = () =>
    setShowConfirmePassword(!showConfirmePassword);

  const handleSendMailToPasswordReset = (e: any, token: string) => {
    e.preventDefault();

    try {
      if (!Login.verifyPassword(e.target.password.value))
        return setValidationErrorPassword(true);

      setValidationErrorPassword(false);

      if (
        !Login.checkEqualsPassword(
          e.target.password.value,
          e.target.password_confirm.value
        )
      ) {
        return toast.error(
          `${translations[param]["oops_the_passwords_are_not_the_same"]}`
        );
      }

      const data = {
        password: e.target.password.value,
        confirmPassword: e.target.password_confirm.value,
        token: token,
      };

      handleChangePassword(data, token);
    } catch {
      toast.error(
        `${translations[param]["oops_we_had_a_problem_changing_your_password"]}`
      );
    }
  };

  const handleChangePassword = async (data: any, token: string) => {
    try {
      setLoading(true);

      const responseTokenIsValid =
        await LoginConsumer.validatedTokenToChangePassword(token);

      if (
        responseTokenIsValid.status !== 200 ||
        responseTokenIsValid.data === "Token Inválido"
      )
        throw responseTokenIsValid;

      const body = {
        token: responseTokenIsValid.data,
        password: Hash({ password: `${data.password}` }),
        passwordConfirm: Hash({ password: `${data.confirmPassword}` }),
        username: localStorage.getItem("username"),
      };

      const response = await LoginConsumer.updatePassword(
        body as iUpdatePassword
      );

      if (response.status !== 200) throw response;

      toast.success(`${translations[param]["password_changed_successfully"]}`);
      localStorage.removeItem("username");

      setTimeout(() => {
        router(`/${lang}`);
      }, 2000);
    } catch (error: any) {
      toast.error(
        `${translations[param]["oops_we_had_a_problem_check_if_the_password_is_correct"]}`
      );
    } finally {
      setLoading(false);
    }
  };

  const getTokenInUrl = async (e: any) => {
    e.preventDefault();

    const token = query.get("token");

    if (!token) {
      toast.warning(
        `${translations[param]["failed_to_identify_the_token_in_the_request_url"]}`
      );
      return setTimeout(() => {
        router(`/${lang}`);
      }, 1500);
    }

    handleSendMailToPasswordReset(e, token);
  };

  return {
    loading,
    handleSendMailToPasswordReset,
    validationErrorPassword,
    showPassword,
    handleClickShowPassword,
    showConfirmePassword,
    handleClickShowConfirmePassword,
    getTokenInUrl,
  };
}
