/* eslint-disable react-hooks/exhaustive-deps */
import { DivFormsFieldLabel } from "src/styles/globalStyle";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { palette, theme } from "src/theme";
import { useEffect } from "react";
import useProduct from "src/hooks/useProduct";
import CircularLoading from "src/components/Loading";
import Layout from "src/components/Layout";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import useUnit from "src/hooks/useUnit";
import { useNavigate, useParams } from "react-router-dom";
import useProductCategoryGeneral from "src/hooks/useProductCategoryGeneral";
import SearchIcon from "@mui/icons-material/Search";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import useAttributesProduct from "src/hooks/useAttributesProduct";
import { FormValues } from "src/contexts/productsContext";

const numberSumWithPage = 9999;

const CreateOrUpdate = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const { setOpenDialog, openDialog } = useLayout();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: "Configurações",
      href: `/${lang}/settings/products`,
    },
    {
      name: "Produtos",
      href: `/${lang}/settings/products`,
    },
    {
      name: "Cadastro",
    },
  ];

  const {
    productGeneralCategorysToSearch,
    getCategories,
    description: descriptionProductCategoryGeneral,
    setDescription: setDescriptionProductCategoryGeneral,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useProductCategoryGeneral();

  const {
    loading,
    productSelect,
    code,
    setCode,
    description,
    setDescription,
    unitsId,
    setUnitsId,
    categoryProduct,
    setCategoryProduct,
    active,
    setActive,
    handleSalveNewProduct,
    handleUpdateProduct,
    page: pageProduct,
    setPage,
    cleanFilters,
    itemSections,
    handleAddSection,
    handleRemoveSection,
    handleInputChange,
  } = useProduct();

  const { units, getUnits } = useUnit();

  const { get, attributes } = useAttributesProduct();

  useEffect(() => {
    getUnits();
    get();
  }, []);

  useEffect(() => {
    if (descriptionProductCategoryGeneral?.length > 3) {
      getCategories(numberSumWithPage);
    }
    if (descriptionProductCategoryGeneral?.length === 0) {
      getCategories(numberSumWithPage);
    }
  }, [descriptionProductCategoryGeneral]);

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() => {
        cleanFilters();
        setPage({
          ...pageProduct,
          page: 0,
          total: 0,
        });
      }}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <Card sx={{ padding: 5, width: "100%" }}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              if (productSelect?.id) {
                handleUpdateProduct();
              } else {
                handleSalveNewProduct();
              }
            }}
          >
            <Typography gutterBottom variant="h6" component="div">
              Produto
            </Typography>
            <Divider
              style={{
                backgroundColor: palette.primary.main,
                marginBottom: 15,
              }}
            />
            <Grid container spacing={2}>
              <Grid xs={11} sm={11} md={11} lg={11}>
                <DivFormsFieldLabel>
                  <FormLabel sx={{ width: "12%" }}>Código: </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="code"
                      name="code"
                      label="Código"
                      required
                      variant="outlined"
                      size="small"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </FormControl>
                </DivFormsFieldLabel>
              </Grid>
              <Grid xs={1} sm={1} md={1} lg={1}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    }
                    label="Ativo"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <DivFormsFieldLabel>
                  <FormLabel sx={{ width: "11%" }}>Descrição: </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="description"
                      name="description"
                      label="Descrição"
                      required
                      sx={{ marginRight: "1%" }}
                      variant="outlined"
                      size="small"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </DivFormsFieldLabel>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} display="flex">
                <FormLabel sx={{ width: "10%" }}>
                  Unidade
                  <br />
                  de medida:
                </FormLabel>
                <FormControl sx={{ width: "90%" }}>
                  <Select
                    labelId="unitsId"
                    id="unitsId"
                    value={unitsId}
                    size="small"
                    onChange={(event: SelectChangeEvent) => {
                      setUnitsId(event.target.value as string);
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value="select" disabled>
                      Selecione:{" "}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {units.map((unit) => (
                      <MenuItem key={unit.id} value={`${unit.id}`}>
                        {unit.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <DivFormsFieldLabel>
                  <FormLabel>Categoria: </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="show_select_administrator_company"
                    options={productGeneralCategorysToSearch}
                    value={categoryProduct}
                    getOptionLabel={(option) => {
                      return option?.id
                        ? `${option?.code} - ${option?.label}`
                        : "";
                    }}
                    sx={{ minWidth: "83%" }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setCategoryProduct(value);
                      } else {
                        setCategoryProduct(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        onChange={(e) =>
                          setDescriptionProductCategoryGeneral(e.target.value)
                        }
                      />
                    )}
                  />
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                </DivFormsFieldLabel>
              </Grid>
            </Grid>

            <Divider
              style={{
                backgroundColor: palette.primary.main,
                marginTop: 15,
                marginBottom: 15,
              }}
            />

            <Grid
              container
              spacing={0}
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginTop: 2 }}
            >
              <Grid>
                <Typography gutterBottom variant="h6" component="div">
                  Atributos
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  color="primary"
                  onClick={() => handleAddSection("attributtes")}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {itemSections?.map((section: any, index: any) => (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                key={index}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {[
                      { label: "Descrição", name: "atributosProdutoId" },
                      { label: "Valor", name: "valor" },
                    ].map((field, idx) => {
                      return (
                        <Grid xs={11} sm={5.6} key={idx}>
                          {field.name === "atributosProdutoId" && (
                            <Select
                              labelId="attribute"
                              id="attribute"
                              name="atributosProdutoId"
                              value={
                                section[field.name as keyof FormValues] ||
                                "select"
                              }
                              fullWidth
                              size="small"
                              onChange={(event: SelectChangeEvent) => {
                                handleInputChange(event, index, "attributtes");
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              <MenuItem value="select" disabled>
                                Selecione:{" "}
                              </MenuItem>
                              <MenuItem value="">...</MenuItem>
                              {attributes.map((value: any) => (
                                <MenuItem key={value.id} value={`${value.id}`}>
                                  {value.descricao}
                                </MenuItem>
                              ))}
                            </Select>
                          )}

                          {field.name === "valor" && (
                            <TextField
                              placeholder={field.label}
                              fullWidth
                              size="small"
                              required
                              name={field.name}
                              value={section[field.name as keyof FormValues]}
                              onChange={(e) => {
                                handleInputChange(e, index, "attributtes");
                              }}
                            />
                          )}
                        </Grid>
                      );
                    })}
                    <Grid xs={1} sm={0.2}>
                      <IconButton
                        onClick={() =>
                          handleRemoveSection(index, "attributtes")
                        }
                        style={{ marginTop: theme.spacing(1) }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}

            <Divider
              style={{
                backgroundColor: palette.primary.main,
                marginTop: 15,
                marginBottom: 15,
              }}
            />

            <Grid
              container
              spacing={0}
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ marginTop: 5 }}
            >
              <FormControl sx={{ marginRight: 1 }}>
                <ButtonClosedForm
                  label="Voltar"
                  onClick={() => {
                    router(-1);
                  }}
                />
              </FormControl>
              <FormControl>
                {productSelect?.id ? (
                  <ButtonSalveForm label="Alterar" type="submit" />
                ) : (
                  <ButtonInclude label="Incluir" type="submit" />
                )}
              </FormControl>
            </Grid>
          </form>
        </Card>
      )}

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Categorias</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loading ? (
            <CircularLoading />
          ) : productGeneralCategorysToSearch?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "Código",
                },
                {
                  label: "Descrição",
                },
              ]}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {productGeneralCategorysToSearch.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item?.id) {
                      setCategoryProduct(item);
                    } else {
                      setCategoryProduct(null);
                    }

                    setOpenDialog(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault label="Fechar" onClick={() => setOpenDialog(false)} />
        </DialogActions>
      </DialogComponent>
    </Layout>
  );
};

export default CreateOrUpdate;
