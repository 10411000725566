import { iShoppingProccessRate } from "src/interfaces/shoppingProccessRate";

export default class ShoppingProccessRate implements iShoppingProccessRate {
  id: string;
  taxasId: string;
  dataInicio: string;
  dataFim: string;
  valorTaxa: number;
  processoCompraId: string;
  descricao?: string;

  constructor(
    id: string,
    taxasId: string,
    dataInicio: string,
    dataFim: string,
    valorTaxa: number,
    processoCompraId: string,
    descricao?: string
  ) {
    this.id = id;
    this.taxasId = taxasId;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.valorTaxa = valorTaxa;
    this.processoCompraId = processoCompraId;
    this.descricao = descricao;
  }

  public static adapterToClass(shoppingProccessRate: any) {
    return new ShoppingProccessRate(
      shoppingProccessRate.id,
      shoppingProccessRate.taxasId,
      shoppingProccessRate.dataInicio,
      shoppingProccessRate.dataFim,
      shoppingProccessRate.valorTaxa,
      shoppingProccessRate.processoCompraId,
      shoppingProccessRate.taxa
    );
  }

  public static adapterToBody(shoppingProcessRate: iShoppingProccessRate) {
    const data: any = {
      taxasId: shoppingProcessRate.taxasId,
      dataInicio: shoppingProcessRate.dataInicio,
      dataFim: shoppingProcessRate.dataFim,
      valorTaxa: shoppingProcessRate.valorTaxa,
      processoCompraId: shoppingProcessRate.processoCompraId,
    };

    if (shoppingProcessRate?.id) {
      data["id"] = shoppingProcessRate.id;
    }

    if (
      shoppingProcessRate?.taxasId &&
      shoppingProcessRate?.taxasId !== "select"
    ) {
      data["taxasId"] = shoppingProcessRate.taxasId;
    }

    return {
      ...data,
    };
  }
}
