import {
  iProductCategory,
  iProductCategoryPage,
} from "src/interfaces/products";
import ProductCategory from "src/models/ProductCategory";
import httpClientRegister from "src/http/register";

class productCategoryConsumer {
  static get(
    idCompany: string,
    page: iProductCategoryPage,
    productCategoryVinculateInCompany?: boolean,
    description?: string
  ) {
    const search = new URLSearchParams();

    search.append("empresaId", idCompany);
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);

    if (productCategoryVinculateInCompany) {
      search.append("ListarTodasCategoriasSemDePara", "true");
    }

    if (description) {
      search.append("Descricao", `${description}`);
    }

    return httpClientRegister.get(
      `/CategoriaProdutoEmpresa?${search.toString()}`
    );
  }

  static created(category: iProductCategory) {
    return httpClientRegister.post(
      `/CategoriaProdutoEmpresa`,
      ProductCategory.adapterToBody(category)
    );
  }

  static updated(category: iProductCategory) {
    return httpClientRegister.put(
      `/CategoriaProdutoEmpresa`,
      ProductCategory.adapterToBody(category)
    );
  }

  static fromTo(body: any) {
    return httpClientRegister.post(`/CategoriaProdutoEmpresa/depara`, body);
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/CategoriaProdutoEmpresa?id=${id}`);
  }
}

export default productCategoryConsumer;
