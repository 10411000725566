import styled from "styled-components";

export const DropdownMenuStyle = styled.div`
  position: absolute;
  top: 300px;
  left: 65px;

  ul {
    list-style: none;
    padding: unset;
    margin: unset;
  }

  li {
    padding: 14px;
    transition: all linear 0.3s;
  }

  li:hover {
    background: #f2f2f2;
    cursor: pointer;
    color: #3b9db4;
  }

  .dropdown-menu {
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
  }
`;
