import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { LayoutProvider } from "src/contexts/layoutContext";
import Routes from "src/routes/index";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LayoutProvider>
          <Routes />
        </LayoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
