import { Grid, TableCell, TableRow } from "@mui/material";
import React from "react";
import { ButtonDefault, ButtonInclude } from "src/components/ButtonsCommons";
import { ItemCustomStart } from "src/components/ItemCustom";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";

const GridDemands: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    loading,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    demands,
    handleSelected,
    setIsDialogOpen,
    handleDelete,
  } = useShoppingProccessDemand();

  const {
    setDialogImport,
    shoppingProcesSelect,
    handleNewSalve: handleNewSalveShoppingProcess,
  } = useShoppingProcess();

  const header = [
    {
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
    {
      id: "code",
      label: translations[param]["description"] + " Detalhada",
      isOrder: true,
      order: 3,
    },
  ];

  return (
    <>
      <Grid sx={{ marginBottom: 2 }}>
        <ItemCustomStart>
          <ButtonInclude
            label={translations[param]["include"]}
            onClick={async () => {
              if (shoppingProcesSelect?.id) {
                setIsDialogOpen(true);
              } else {
                const response = await handleNewSalveShoppingProcess();
                if (shoppingProcesSelect?.id || response?.id) {
                  setIsDialogOpen(true);
                }
              }
            }}
          />
          <ButtonDefault
            label="Importar"
            onClick={() => setDialogImport(true)}
            disabled={shoppingProcesSelect?.id ? false : true}
          />
        </ItemCustomStart>
      </Grid>
      {loading ? (
        <CircularLoading />
      ) : demands.length === 0 ? (
        <Empty />
      ) : (
        <TableComponent
          header={header}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          funtionToOrder={(item) => {}}
        >
          {demands.map((item: any) => (
            <TableRow
              hover
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                onClick={() => {
                  handleSelected(item);
                  setIsDialogOpen(true);
                }}
              >
                {item?.produtoCodigo ?? "Não informado"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                onClick={() => {
                  handleSelected(item);
                  setIsDialogOpen(true);
                }}
              >
                {item?.produtoDescricao ?? "Não informado"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                onClick={() => {
                  handleSelected(item);
                  setIsDialogOpen(true);
                }}
              >
                {item?.descricaoDetalhada ?? "Não informado"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                onClick={() => {
                  Swal.fire({
                    title: `${translations[param]["attention"]}!`,
                    text: `Deseja deletar a demanda ? `,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: palette.primary.main,
                    cancelButtonColor: palette.error.main,
                    confirmButtonText: `${translations[param]["yes"]}`,
                    cancelButtonText: `${translations[param]["no"]}`,
                  }).then((result) => {
                    if (result.isConfirmed) handleDelete(item?.id);
                  });
                }}
              >
                <DeleteIcon color="error" />
              </TableCell>
            </TableRow>
          ))}
        </TableComponent>
      )}
    </>
  );
};

export default GridDemands;
