import React, { useEffect } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Checkbox, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { iRequests } from "src/interfaces/requests";
import format from "date-fns/format";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import { addHours } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useRequest from "src/hooks/useRequest";
import useLayout from "src/hooks/useLayout";
import useTable from "src/hooks/useTable";

const Table: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { setDisableButtons } = useLayout();
  const { setFieldOrder } = useTable();

  const header = [
    {
      id: "shoppingProccess?.numero",
      label: `N° ${translations[param]["proccess"]}`,
      isOrder: false,
      order: 1,
    },
    {
      id: "number",
      label: `N° ${translations[param]["request"]}`,
      isOrder: false,
      order: 2,
    },
    {
      id: "emissionDate",
      label: translations[param]["date"],
      isOrder: false,
      order: 3,
    },
    {
      id: "buyer?.razaoSocial",
      label: `${translations[param]["buyer"]}`,
      isOrder: false,
      order: 4,
    },
    {
      id: "supplier?.razaoSocial",
      label: translations[param]["supplier"],
      isOrder: false,
      order: 5,
    },
    {
      id: "value",
      label: translations[param]["value"],
      isOrder: false,
      order: 6,
    },
    {
      id: "statusCurrent?.observacao",
      label: translations[param]["status"],
      isOrder: false,
      order: 7,
    },
  ];

  const {
    loading,
    requestSelect,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    requests,
    handleSelected,
    setOrderBy,
    setRequestSelect,
  } = useRequest();

  useEffect(() => {
    setFieldOrder({
      id: "emissionDate",
      label: translations[param]["date"],
      isOrder: false,
      order: 3,
    });
  }, []);

  return (
    <Grid container spacing={2} sx={{ marginTop: 5 }}>
      <Card sx={{ padding: 2, width: "100%" }}>
        {loading ? (
          <CircularLoading />
        ) : requests.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            funtionToOrder={(item) =>
              item?.orderBackend &&
              setOrderBy({
                number: item?.orderBackend,
                order: item?.order === "asc" ? true : false,
              })
            }
          >
            {requests?.map((item: iRequests) => (
              <TableRow
                hover
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                >
                  {item?.shoppingProccess?.numero ?? (
                    <DoDisturbAltOutlinedIcon color="error" />
                  )}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                  sx={{ width: "15%" }}
                >
                  {item.number}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                  sx={{ width: "20%" }}
                >
                  {format(
                    addHours(new Date(item.emissionDate), 3),
                    "dd/MM/yyyy"
                  )}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                >
                  {item.buyer.razaoSocial}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                >
                  {item.supplier.razaoSocial}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                >
                  {item.value?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelected(item);
                    router(`/${lang}/request/register`);
                  }}
                >
                  {item?.statusCurrent?.pedidoStatus?.descricao ??
                    "Não informado"}
                </TableCell>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={item.id === requestSelect?.id}
                    size="small"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setRequestSelect(null);
                        setDisableButtons(true);
                      }
                    }}
                    onClick={() => {
                      setRequestSelect(item);
                      setDisableButtons(false);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>
    </Grid>
  );
};

export default Table;
