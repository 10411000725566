/* eslint-disable jsx-a11y/alt-text */
import {
  Backdrop,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import DialogComponent from "src/components/Dialog";
import InputImport from "src/components/InputImport";
import Layout from "src/components/Layout";
import useImport from "src/hooks/useImport";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { palette } from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";

import FILE_CATEGORY_PRODUCT from "src/assets/files/Supply4Med - Modelo de importação Categoria de Produto.xlsx";
import FILE_PAYMENT_CONDITIONS from "src/assets/files/Supply4Med - Modelo de importação Condição de Pagamento.xlsx";
import FILE_COMPANYS from "src/assets/files/Supply4Med - Modelo de importação Empresa.xlsx";
import FILE_REQUESTS from "src/assets/files/Supply4Med - Modelo de importação Pedido.xlsx";
import FILE_REQUESTS_V2 from "src/assets/files/Supply4Med - Modelo de importação Pedido V2.xlsx";
import FILE_SHOPPING_PROCCESS from "src/assets/files/Supply4Med - Modelo de importação Processo de Compras.xlsx";
import FILE_SHOPPING_PROCCESS_V2 from "src/assets/files/Supply4Med - Modelo de importação Processo de Compras V2.xlsx";
import FILE_PRODUCTS from "src/assets/files/Supply4Med - Modelo de importação Produto.xlsx";
import FILE_PRODUCTS_COMPANY from "src/assets/files/Supply4Med - Modelo de importação Produto Empresa.xlsx";
import FILE_CATEGORY_PRODUCTS_COMPANY from "src/assets/files/Supply4Med - Modelo de importação Categoria de Produto.xlsx";

import CircularLoading from "src/components/Loading";
import { addHours, format } from "date-fns";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const ImportExport: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    openDialog,
    setOpenDialog,
    handleSelect,
    logs,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleUpload,
    selectedFile,
    loading,
    logSelect,
    setLogs,
    handleDownloadLog,
    fileType,
    setFileType,
  } = useImport();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["import"],
    },
  ];

  const headerOfDialog = [
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["module"],
    },
    {
      label: translations[param]["status"],
    },
    {
      label: translations[param]["initial_date"],
    },
    {
      label: translations[param]["the_end_date"],
    },
    {
      label: translations[param]["user"],
    },
  ];

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100,
        }}
        open={loading}
        onClick={() => {}}
      >
        <CircularLoading />
      </Backdrop>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Typography gutterBottom variant="h6" component="div">
          {translations[param]["import"]}
        </Typography>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 15,
          }}
        />
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <InputImport
            description={translations[param]["company"]}
            file={FILE_COMPANYS}
            name="Supply4Med - Modelo de importação Empresa.xlsx"
            type="Empresa"
          />
          <InputImport
            description={translations[param]["products"]}
            file={FILE_PRODUCTS}
            name="Supply4Med - Modelo de importação Produto.xlsx"
            type="Produto"
          />
          <InputImport
            description={`${translations[param]["products"]}/${translations[param]["company"]}`}
            file={FILE_PRODUCTS_COMPANY}
            name="Supply4Med - Modelo de importação Produto Empresa.xlsx"
            type="ProdutoEmpresa"
          />
          <InputImport
            description={translations[param]["product_category"]}
            file={FILE_CATEGORY_PRODUCT}
            name="Supply4Med - Modelo de importação Categoria de Produto.xlsx"
            type="CategoriaProduto"
          />
          <InputImport
            description={`${translations[param]["product_category"]}/${translations[param]["company"]}`}
            file={FILE_CATEGORY_PRODUCTS_COMPANY}
            name="Supply4Med - Modelo de importação Categoria de Produto Empresa.xlsx"
            type="CategoriaProdutoEmpresa"
          />
          <InputImport
            description={translations[param]["payment_condition"]}
            file={FILE_PAYMENT_CONDITIONS}
            name="Supply4Med - Modelo de importação Condição de Pagamento.xlsx"
            type="CondicaoPagamento"
          />
          <InputImport
            description={translations[param]["purchasing_processes"]}
            file={FILE_SHOPPING_PROCCESS}
            name="Supply4Med - Modelo de importação Processo de Compras.xlsx"
            type="ProcessoCompra"
          />
          <InputImport
            description={`${translations[param]["purchasing_processes"]} V2`}
            file={FILE_SHOPPING_PROCCESS_V2}
            name="Supply4Med - Modelo de importação Processo de Compras V2.xlsx"
            type="ProcessoCompraV2"
          />
          <InputImport
            description={translations[param]["requests"]}
            file={FILE_REQUESTS}
            name="Supply4Med - Modelo de importação Pedido.xlsx"
            type="Pedido"
          />
          <InputImport
            description={`${translations[param]["requests"]} V2`}
            file={FILE_REQUESTS_V2}
            name="Supply4Med - Modelo de importação Pedido V2.xlsx"
            type="PedidoV2"
          />
        </Grid>

        <Grid
          container
          xs={10}
          display="flex"
          justifyContent="end"
          sx={{ marginTop: 2 }}
        >
          <ButtonSalveForm
            label="Importar"
            onClick={() => handleUpload({ shoppingProcesSelect: null })}
            disabled={selectedFile !== null ? false : true}
          />
        </Grid>
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          Logs {translations[param]["import"]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialog(false);
            handleSelect(null);
            setFileType("");
            setLogs([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {logs.length === 0 ? (
            <Empty />
          ) : (
            <>
              <TableComponent
                header={headerOfDialog}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {logs.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      backgroundColor: logSelect === item ? "#e0e0e0" : "",
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    <TableCell component="th" scope="row">
                      {item?.codigo}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.modulo}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.auditoriaStatus?.descricao}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.dataInicio
                        ? format(
                            new Date(item?.dataInicio),
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.dataFim
                        ? format(
                            addHours(new Date(item?.dataFim), 3),
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.usuario}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>

              <FormControl fullWidth sx={{ marginTop: 10 }}>
                <TextField
                  minRows={5}
                  multiline
                  placeholder="Observações"
                  disabled
                  value={
                    logSelect?.observacao
                      ?.replaceAll("Pagina:", "\n \n Pagina:")
                      ?.replace("\n \n Pagina:", "Pagina:") ?? ""
                  }
                />
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialog(false);
              handleSelect(null);
              setFileType("");
              setLogs([]);
            }}
          />
          <ButtonDefault
            label="Salvar log"
            onClick={() => handleDownloadLog(headerOfDialog, fileType)}
          />
        </DialogActions>
      </DialogComponent>
    </Layout>
  );
};

export default ImportExport;
