/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import useRequest from "src/hooks/useRequest";
import { iPage } from "src/interfaces/layout";
import {
  iRequestItem,
  iRequestItemContextProps,
} from "src/interfaces/requestItem";
import RequestItem from "src/models/RequestItem";
import requestItemConsumer from "src/services/requestItem";

const RequestItemContext = createContext<iRequestItemContextProps>(
  {} as iRequestItemContextProps
);

export function RequestItemProvider({ children }: { children: any }) {
  const { setDisableButtons, setOpenDialog } = useLayout();
  const { setValue, value } = useRequest();

  const [loading, setLoading] = useState<boolean>(false);
  const [requestItem, setRequestItems] = useState<Array<iRequestItem>>([]);
  const [requestItemSelect, setRequestItemSelect] =
    useState<iRequestItem | null>(null);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [shoppingProccessItem, setShoppingProccessItem] = useState<any>(null);
  const [dialogProductsSearch, setDialogProductsSearch] =
    useState<boolean>(false);
  const [shoppingProccessItemId, setShoppingProccessItemId] =
    useState<string>("");
  const [shoppingProccessItemLabel, setShoppingProccessItemLabel] =
    useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeItem, setCodeItem] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [descriptionItem, setDescriptionItem] = useState<any>(null);
  const [unit, setUnit] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [unitValue, setUnitValue] = useState<string>("R$ 0");
  const [totalValue, setTotalValue] = useState<string>("R$ 0");
  const [itemsToSearch, setItemsToSearch] = useState<Array<any>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getRequestItem = async (requestId: string) => {
    setLoading(true);
    try {
      const response = await requestItemConsumer.get(page, requestId);

      if (response.status !== 200) throw response;

      const data = response.data?.items?.map((item: any) =>
        RequestItem.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response?.data?.totalItems ?? 0,
        });
      }

      setRequestItems(data);
      setItemsToSearch(
        response.data?.items?.map((item: any) => {
          return {
            id: item?.id,
            code: item?.produtoEmpresa?.codigoProdutoEmpresa,
            label: item?.produtoEmpresa?.descricao,
            ...item,
          };
        })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os itens cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (item: iRequestItem | null) => {
    setRequestItemSelect(item ?? null);

    if (item) {
      setShoppingProccessItem({
        id: item?.id,
        code: item?.produtoEmpresa?.codigoProdutoEmpresa,
        label: item?.produtoEmpresa?.descricao,
      });
    } else {
      setShoppingProccessItem(null);
    }

    setShoppingProccessItemId(item?.id ?? "");
    setCode(item?.produtoEmpresa?.codigoProdutoEmpresa ?? "");
    setDescription(item?.produtoEmpresa?.descricao ?? "");
    setUnit(item?.produtoEmpresa?.unidade ?? "");
    setQuantity(item?.quantidade?.toString() ?? "");
    setUnitValue(
      parseFloat(item?.valorUnitario ?? "0.0").toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "R$ 0"
    );

    if (item?.produtoEmpresa?.codigoProdutoEmpresa) {
      setCodeItem({
        code: item?.produtoEmpresa?.codigoProdutoEmpresa ?? "",
        label: item?.produtoEmpresa?.descricao ?? "",
      });
    }

    if (item?.produtoEmpresa?.descricao) {
      setDescriptionItem({
        code: item?.produtoEmpresa?.codigoProdutoEmpresa ?? "",
        label: item?.produtoEmpresa?.descricao ?? "",
      });
    }

    if (item?.produtoEmpresa?.id) {
      setShoppingProccessItemLabel(
        `${item.produtoEmpresa.codigoProdutoEmpresa} - ${item.produtoEmpresa.descricao}`
      );
    }
  };

  const handleSelectedChecked = (data: any) => {
    if (data?.id === requestItemSelect?.id) {
      setDisableButtons(true);
      return setRequestItemSelect(null);
    }

    setDisableButtons(false);
    setRequestItemSelect(data);
  };

  const handleDelete = async (item: any, requestId: string) => {
    try {
      setLoading(true);

      const response = await requestItemConsumer.deleted(`${item?.id}`);

      if (response.status !== 200 && response.status !== 204) throw response;

      const sumTotalRequest = value - item.valorTotal;

      setValue(sumTotalRequest);

      toast.success("Item deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelectedChecked(item);
      handleSelect(null);
      getRequestItem(requestId);
    } catch {
      toast.error("Erro ao deletar o pedido!");
    } finally {
      setLoading(false);
    }
  };

  const handleSalve = async (requestId: string) => {
    try {
      setLoading(true);

      const item = new RequestItem(
        "",
        parseInt(quantity),
        unitValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        (
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          ) * parseInt(quantity)
        ).toFixed(2),
        requestId,
        shoppingProccessItem?.produtoEmpresaId,
        shoppingProccessItem?.processoCompraItemId
      );

      const response = await requestItemConsumer.created(item);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Item cadastrado com sucesso!");

      const sumTotalRequest =
        value +
        parseInt(quantity) *
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          );

      setValue(sumTotalRequest);

      setOpenDialog(false);
      getRequestItem(requestId);
      handleSelect(null);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar o novo item!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (requestId: string) => {
    try {
      setLoading(true);

      const item = new RequestItem(
        requestItemSelect?.id ?? "",
        parseInt(quantity),
        unitValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        (
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          ) * parseInt(quantity)
        ).toFixed(2),
        requestId,
        requestItemSelect?.produtoEmpresaId ?? "",
        requestItemSelect?.processoCompraItemId ?? ""
      );

      const response = await requestItemConsumer.created(item);

      if (response.status !== 200 && response.status !== 201) throw response;

      if (requestItemSelect?.valorTotal) {
        const totalRequest = value - parseFloat(requestItemSelect?.valorTotal);

        const sumTotalRequest =
          totalRequest +
          parseInt(quantity) *
            parseFloat(
              unitValue
                .replace("R$", "")
                .replaceAll(".", "")
                .replace(",", ".")
                .trim()
            );

        setValue(sumTotalRequest);
      }

      toast.success("Item alterado com sucesso!");

      setOpenDialog(false);
      getRequestItem(requestId);
      handleSelect(null);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o item!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      setPage,
      getRequestItem,
      loading,
      setLoading,
      requestItem,
      handleChangePage,
      handleChangeRowsPerPage,
      handleSelect,
      shoppingProccessItemId,
      setShoppingProccessItemId,
      code,
      setCode,
      codeItem,
      setCodeItem,
      description,
      setDescription,
      descriptionItem,
      setDescriptionItem,
      unit,
      setUnit,
      quantity,
      setQuantity,
      unitValue,
      setUnitValue,
      requestItemSelect,
      setRequestItemSelect,
      setRequestItems,
      handleSelectedChecked,
      handleDelete,
      totalValue,
      setTotalValue,
      handleSalve,
      handleUpdate,
      shoppingProccessItemLabel,
      setShoppingProccessItemLabel,
      shoppingProccessItem,
      setShoppingProccessItem,
      itemsToSearch,
      setItemsToSearch,
      dialogProductsSearch,
      setDialogProductsSearch,
    };
  }, [
    page,
    loading,
    requestItem,
    shoppingProccessItemId,
    code,
    codeItem,
    description,
    descriptionItem,
    unit,
    quantity,
    unitValue,
    requestItemSelect,
    totalValue,
    shoppingProccessItemLabel,
    shoppingProccessItem,
    itemsToSearch,
    dialogProductsSearch,
  ]);

  return (
    <RequestItemContext.Provider value={contextValue}>
      {children}
    </RequestItemContext.Provider>
  );
}

export default RequestItemContext;
