/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iAddressContextProps,
  iAddress,
  iAddressPage,
  iAddressType,
} from "src/interfaces/address";
import useCompany from "src/hooks/useCompany";
import Address from "src/models/Address";
import addressConsumer from "src/services/address";
import viaCepConsumer from "src/services/cepService";
import httpClientStateService from "src/services/statesService";
import addressTypeConsumer from "src/services/addressType";
import AddressType from "src/models/AddressType";
import { iCompany } from "src/interfaces/company";
import { toast } from "react-toastify";

const AdressContext = createContext<iAddressContextProps>(
  {} as iAddressContextProps
);

export function AdressProvider({ children }: { children: any }) {
  const { companySelected } = useCompany();

  const [loading, setLoading] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<Array<iAddress>>([]);
  const [page, setPage] = useState<iAddressPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [addressSelect, setAddressSelect] = useState<iAddress | null>(null);
  const [addressToSearch, setAddressToSearch] = useState<Array<any>>([]);
  const [cep, setCep] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("select");
  const [country, setCountry] = useState<string>("select");
  const [neighborhood, setNeighborhood] = useState<string>("");
  const [complement, setComplement] = useState<string>("");
  const [typeAddressId, setTypeAddressId] = useState<string>("select");
  const [main, setMain] = useState<boolean>(false);

  const [statesOfCountry, setStatesOfCountry] = useState<Array<any>>([]);
  const [addressTypes, setAddressTypes] = useState<Array<iAddressType>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getTypesId = async (companyId?: string) => {
    try {
      setLoading(true);
      const response = await addressTypeConsumer.get(
        companyId ?? companySelected?.id,
        page
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        AddressType.adapterToClass(item)
      );

      setAddressTypes(data);

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      return response.data.items;
    } catch {
      toast.error("Ops... Não foi possível obter os tipos de endereço!");
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      setLoading(true);

      const response = await httpClientStateService.get();
      setStatesOfCountry(
        response.data.sort(function (a: any, b: any) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        })
      );
    } catch {
      toast.error("Ops... Não foi possível obter os estados brasileiros!");
    } finally {
      setLoading(false);
    }
  };

  const getCep = async (cep: string) => {
    try {
      setLoading(true);
      const response = await viaCepConsumer.get(cep);

      const data = response.data;

      setCep(data.cep);
      setAddress(data.logradouro);
      setCity(data.localidade);
      setState(data.uf.toUpperCase());
      setNeighborhood(data.bairro);
      setComplement(data.complemento);
    } catch {
      toast.error("Ops... erro ao buscar o endereço!");
    } finally {
      setLoading(false);
    }
  };

  const getAddress = async (company: iCompany, typeAddress?: string) => {
    setLoading(true);
    try {
      const response = await addressConsumer.get(
        `${company?.id}`,
        page,
        typeAddress
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        Address.adapterToClass(item)
      );

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      setAddressToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            label: `${item?.endereco}, NÚMERO: ${item?.numero}, ${item?.bairro}, CEP: ${item?.cep}`,
          };
        })
      );

      setAddresses(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os endereços cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalve = async () => {
    if (!cep) return toast.info("O CEP não pode ser nulo!");
    if (!typeAddressId) return toast.info("O Tipo não pode ser nulo!");
    if (!country) return toast.info("O País não pode ser nulo!");
    if (!state) return toast.info("O Estado não pode ser nulo!");

    try {
      setLoading(true);

      const addressData = new Address(
        "",
        cep,
        address,
        number,
        city,
        state,
        country,
        neighborhood,
        complement,
        companySelected?.id,
        typeAddressId,
        "",
        main
      );

      const response = await addressConsumer.created(addressData);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Novo endereço cadastrado com sucesso!");

      handleSelected(null);
      handleCleanForm();
      getAddress(companySelected);
    } catch (e: any) {
      toast.error(
        e?.response?.data ??
          "Ops... tivemos um problema ao cadastrar o novo endereço!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalveApi = async (companyId?: string) => {
    if (!cep) return toast.info("O CEP não pode ser nulo!");
    if (!typeAddressId) return toast.info("O Tipo não pode ser nulo!");
    if (!country) return toast.info("O País não pode ser nulo!");
    if (!state) return toast.info("O Estado não pode ser nulo!");

    try {
      setLoading(true);

      const dataTypesAddress = await getTypesId(companyId);
      const typeAddress = dataTypesAddress.find(
        (e: any) => e.descricao === "Faturamento"
      );

      const addressData = new Address(
        "",
        cep,
        address,
        number,
        city,
        state,
        country,
        neighborhood,
        "ENDEREÇO EMPRESARIAL",
        companyId ?? companySelected?.id,
        typeAddress?.id ?? "",
        "",
        main
      );

      const response = await addressConsumer.created(addressData);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Novo endereço cadastrado com sucesso!");

      handleSelected(null);
      handleCleanForm();
      getAddress(companySelected);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar o novo endereço!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const addressData = new Address(
        addressSelect?.id ?? "",
        cep,
        address,
        number,
        city,
        state,
        country,
        neighborhood,
        complement,
        companySelected?.id,
        typeAddressId,
        "",
        main
      );

      const response = await addressConsumer.updated(addressData);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Endereço alterado com sucesso!");

      handleSelected(null);
      handleCleanForm();
      getAddress(companySelected);
    } catch (e: any) {
      toast.error(
        e?.response?.data ??
          "Ops... tivemos um problema ao cadastrar o novo endereço!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (address: iAddress) => {
    try {
      setLoading(true);

      const response = await addressConsumer.deleted(`${address?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Endereço deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelected(null);
      handleCleanForm();
      getAddress(companySelected);
    } catch {
      toast.error("Erro ao deletar o endereço!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (address: iAddress | null) => {
    setAddressSelect(address);
    setCep(address?.cep ?? "");
    setAddress(address?.address ?? "");
    setNumber(address?.number ?? "");
    setCity(address?.city ?? "");
    setState(address?.state ?? "select");
    setCountry(address?.country ?? "select");
    setNeighborhood(address?.neighborhood ?? "");
    setComplement(address?.complement ?? "");
    setMain(address?.main ?? false);
    setTypeAddressId(address?.typeAddressId ?? "select");
  };

  const handleCleanForm = () => {
    setAddressSelect(null);
    setCep("");
    setAddress("");
    setNumber("");
    setCity("");
    setState("select");
    setCountry("select");
    setNeighborhood("");
    setComplement("");
    setMain(false);
    setTypeAddressId("select");
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getAddress,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      addressSelect,
      setAddressSelect,
      cep,
      setCep,
      address,
      setAddress,
      number,
      setNumber,
      city,
      setCity,
      state,
      setState,
      country,
      setCountry,
      neighborhood,
      setNeighborhood,
      complement,
      setComplement,
      main,
      setMain,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      handleSelected,
      addresses,
      typeAddressId,
      setTypeAddressId,
      getCep,
      getStates,
      statesOfCountry,
      addressTypes,
      getTypesId,
      handleCleanForm,
      handleNewSalveApi,
      addressToSearch,
      setAddressToSearch,
    };
  }, [
    page,
    loading,
    addresses,
    addressSelect,
    cep,
    address,
    number,
    city,
    state,
    country,
    neighborhood,
    complement,
    main,
    typeAddressId,
    statesOfCountry,
    addressTypes,
    addressToSearch,
  ]);

  return (
    <AdressContext.Provider value={contextValue}>
      {children}
    </AdressContext.Provider>
  );
}

export default AdressContext;
