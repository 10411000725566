import {
  Grid,
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";

const SuggestedItemForm = () => {
  const { setOpenDialog } = useConsolidateDemand();

  const attributes = [
    {
      description: "Atributo 1",
      content: "Atributo 1",
      type: "Atributo 1",
      mandatory: false,
    },
    {
      description: "Atributo 2",
      content: "Atributo 2",
      type: "Atributo 2",
      mandatory: false,
    },
    {
      description: "Atributo 3",
      content: "Atributo 3",
      type: "Atributo 3",
      mandatory: false,
    },
    {
      description: "Atributo 4",
      content: "Atributo 4",
      type: "Atributo 4",
      mandatory: false,
    },
  ];

  return (
    <>
      <DialogTitle id="alert-dialog-title">Adicionar Item Sugerido</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialog(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Código</InputLabel>
              <Select label="Código">
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {/* Adicione mais opções aqui */}
                <MenuItem value={1}>Código 1</MenuItem>
                <MenuItem value={2}>Código 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Descrição</InputLabel>
              <Select label="Descrição">
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {/* Adicione mais opções aqui */}
                <MenuItem value={1}>Descrição 1</MenuItem>
                <MenuItem value={2}>Descrição 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Código Item Comprador" fullWidth size="small" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descrição Item Comprador"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descrição Detalhada"
              fullWidth
              multiline
              rows={4}
              size="small"
            />
          </Grid>

          {/* Campo de pesquisa alinhado à direita */}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <TextField label="Pesquisar atributos" size="small" />
            <IconButton>
              <SearchIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>

        {/* Tabela de Atributos */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          Atributos
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Conteúdo</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Obrigatório</TableCell>
                <TableCell>Selecionar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes.map((attr, index) => (
                <TableRow key={index}>
                  <TableCell>{attr.description}</TableCell>
                  <TableCell>{attr.content}</TableCell>
                  <TableCell>{attr.type}</TableCell>
                  <TableCell>
                    <Checkbox checked={attr.mandatory} />
                  </TableCell>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              size="small"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              Fechar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              size="small"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default SuggestedItemForm;
