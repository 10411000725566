import { iUser } from "src/interfaces/user";

export default class User implements iUser {
  id;
  name;
  login;
  email;
  password;
  active;
  countId;
  companyId;
  isDeleted;
  isMain;
  phone;

  constructor(
    id: string | null,
    name: string,
    login: string | null,
    email: string | null,
    password: string | null,
    active: boolean,
    countId: string | null,
    companyId: string,
    isDeleted: boolean,
    isMain: boolean,
    phone: string
  ) {
    this.id = id;
    this.name = name;
    this.login = login;
    this.email = email;
    this.password = password;
    this.active = active;
    this.countId = countId;
    this.companyId = companyId;
    this.isDeleted = isDeleted;
    this.isMain = isMain;
    this.phone = phone;
  }

  public static adapterToClass(user: any) {
    return new User(
      user.id,
      user.nome,
      user.login,
      user.email,
      user.password,
      user.ativo,
      user.contaId,
      user.empresaId,
      user.isDeleted,
      user.isPrincipal,
      user.telefone
    );
  }

  public static adapterToJson(user: string) {
    return this.adapterToClass(JSON.parse(user));
  }

  public static adapterToString(user: any) {
    return JSON.stringify(user);
  }

  public static adapterToBodyCreate(user: iUser) {
    return {
      nome: user?.name,
      login: user?.login,
      email: user?.email,
      ativo: user?.active,
      contaId: user?.companyId,
      empresaId: user?.companyId,
      isDeleted: false,
      telefone: user.phone ? user.phone : null,
      senha: user.password ? user.password : null,
      isPrincipal: user.isMain,
    };
  }

  public static adapterToBodyUpdate(user: iUser) {
    return {
      id: user.id ?? null,
      nome: user?.name,
      login: user?.login,
      email: user?.email,
      ativo: user?.active,
      contaId: user?.companyId,
      empresaId: user?.companyId,
      isDeleted: false,
      telefone: user.phone,
      senha: user.password ?? "",
      isPrincipal: user.isMain,
    };
  }
}
