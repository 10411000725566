import httpClientRegister from "src/http/register";
import { iPage } from "src/interfaces/layout";

class AttributesProductConsumer {
  static get(page: iPage, orderBy?: any, description?: string | undefined) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (description) search.append("Descricao", `${description}`);

    return httpClientRegister.get(`/AtributosProduto?${search.toString()}`);
  }

  static async created(body: any) {
    const response = await httpClientRegister.post(`/AtributosProduto`, body);

    return response;
  }

  static updated(body: any) {
    return httpClientRegister.put(`/AtributosProduto`, body);
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/AtributosProduto?id=${id}`);
  }

  static getDataType(page: iPage, orderBy?: any) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return httpClientRegister.get(`/AtributosDadosTipo?${search.toString()}`);
  }
}

export default AttributesProductConsumer;
