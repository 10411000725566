import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import IconExcel from "src/assets/images/IconExcel.svg";

const ExportToExcel = ({
  data,
  fileName,
  buttonName,
  variant = "contained",
}: {
  data: any;
  fileName: any;
  buttonName: string;
  variant?: "text" | "outlined" | "contained";
}) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Ajuste de largura das colunas
    worksheet["!cols"] = [
      { wpx: 110 },
      { wpx: 110 },
      { wpx: 110 },
      { wpx: 110 },
      { wpx: 110 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button
      onClick={exportToExcel}
      variant={variant}
      size="small"
      color="secondary"
      startIcon={
        <img
          src={IconExcel}
          width="15"
          alt="icon_excel"
          style={{ cursor: "pointer" }}
        />
      }
    >
      {buttonName}
    </Button>
  );
};

export default ExportToExcel;
