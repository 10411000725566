import shoppingProcess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";

class ShoppingProcessDemandClientConsumer {
  static get(
    page: iPage,
    shoppingProccessId: string,
    companyId?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);
    search.append("ProcessoCompraId", shoppingProccessId);

    if (companyId) search.append("EmpresaId", companyId);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return shoppingProcess.get(`/ProcessoCompraDemanda?${search.toString()}`);
  }
}

export default ShoppingProcessDemandClientConsumer;
