import { iAddressPage } from "src/interfaces/address";
import httpClientRegister from "src/http/register";

class addressTypeConsumer {
  static get(idCompany: string, page: iAddressPage) {
    return httpClientRegister.get(
      `/EnderecoTipo?empresaId=${idCompany}&PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }
}

export default addressTypeConsumer;
