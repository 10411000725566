import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Unstable_Grid2";

import Typography from "@mui/material/Typography";

import useLayout from "src/hooks/useLayout";
import AppBar from "src/components/AppBar";
import MessageAlert from "src/components/MessageAlert";
import Drawer, { DrawerHeader } from "src/components/Drawer";

import {
  BreadcrumbLink,
  ButtonsLayout,
  DivContent,
  DivMessageAlert,
  LayoutBreadCrumbAndButtons,
  LayoutdButton,
} from "./style";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownMenu from "../DropdownMenu";
import SizeScreenNotSettingsIcon from "../SizeScreenNotSettingsIcon";
import DialogComponent from "../Dialog";
import { DialogContent, IconButton } from "@mui/material";
import translations from "src/_i18n/translations.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import CloseIcon from "@mui/icons-material/Close";
import { palette } from "src/theme";

export default function Layout({
  children,
  buttons,
  breadcrumbs,
  callMenu,
}: {
  children: any;
  buttons?: Array<any> | null;
  breadcrumbs?: Array<iBreadcrumbs> | null;
  callMenu?: () => void;
}) {
  const {
    message,
    emptyMessage,
    drawerWidth,
    openDialogAlert,
    selectMenuDrawer,
    width,
    openDialogLanguage,
    setOpenDialogLanguage,
  } = useLayout();
  const router = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const ComponentBreadCrumb = ({
    breadcrumb,
  }: {
    breadcrumb: iBreadcrumbs;
  }) => {
    if (breadcrumb.href) {
      return (
        <BreadcrumbLink onClick={() => router(`${breadcrumb.href}`)}>
          <Typography sx={{ fontWeight: "bold", color: "secondary" }}>
            {breadcrumb.name}
          </Typography>
        </BreadcrumbLink>
      );
    }

    return (
      <Typography
        color="text.primary"
        sx={{ fontWeight: "bold", color: "secondary" }}
      >
        {breadcrumb.name}
      </Typography>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar />
      <Drawer callMenu={() => callMenu && callMenu()} />
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <DrawerHeader />
        <DivMessageAlert>
          <MessageAlert currentMessage={message} handleOnClose={emptyMessage} />
        </DivMessageAlert>
        <LayoutBreadCrumbAndButtons>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={12} sm={12} md={12} lg={6}>
              <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs?.map((breadcrumb, index) => (
                  <ComponentBreadCrumb breadcrumb={breadcrumb} key={index} />
                ))}
              </Breadcrumbs>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={6}>
              {width >= 1250 && (
                <ButtonsLayout>
                  {buttons?.map((button, index) => (
                    <LayoutdButton key={index}>{button}</LayoutdButton>
                  ))}
                </ButtonsLayout>
              )}
            </Grid>
          </Grid>
        </LayoutBreadCrumbAndButtons>
        <DivContent>
          {openDialogAlert && <DropdownMenu menu={selectMenuDrawer} />}
          {width < 1280 ? <SizeScreenNotSettingsIcon /> : children}
        </DivContent>
      </Box>

      <DialogComponent open={openDialogLanguage} handleClose={() => {}}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogLanguage(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Typography
              noWrap
              component="span"
              sx={{
                cursor: "pointer",
                color:
                  lang === "pt" ? palette.primary.main : palette.secondary.dark,
              }}
              onClick={() =>
                router(
                  `${location?.pathname.replace(/\/(pt|en|es)\//, "/pt/")}`
                )
              }
            >
              {translations[param]["portugues"]}
            </Typography>
            |
            <Typography
              noWrap
              component="span"
              sx={{
                cursor: "pointer",
                color:
                  lang === "en" ? palette.primary.main : palette.secondary.dark,
              }}
              onClick={() =>
                router(
                  `${location?.pathname.replace(/\/(pt|en|es)\//, "/en/")}`
                )
              }
            >
              {translations[param]["english"]}
            </Typography>
            |
            <Typography
              noWrap
              component="span"
              sx={{
                cursor: "pointer",
                color:
                  lang === "es" ? palette.primary.main : palette.secondary.dark,
              }}
              onClick={() =>
                router(
                  `${location?.pathname.replace(/\/(pt|en|es)\//, "/es/")}`
                )
              }
            >
              {translations[param]["spanish"]}
            </Typography>
          </Grid>
        </DialogContent>
      </DialogComponent>
    </Box>
  );
}
