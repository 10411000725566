import { iNetwork } from "src/interfaces/network";

export default class NetWork implements iNetwork {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  public static adapterToClass(network: any) {
    return new NetWork(network.id, network.nome);
  }

  public static adapterToJson(network: any) {
    return JSON.parse(network);
  }

  public static adapterToBody(network: iNetwork) {
    return {
      id: network.id,
      nome: network.name,
    };
  }
}
