import { iAddress } from "src/interfaces/address";

export default class Address implements iAddress {
  id;
  cep;
  address;
  number;
  city;
  state;
  country;
  neighborhood;
  complement;
  companyId;
  typeAddressId;
  typeAddress;
  main;

  constructor(
    id: string,
    cep: string,
    address: string,
    number: string,
    city: string,
    state: string,
    country: string,
    neighborhood: string,
    complement: string,
    companyId: string,
    typeAddressId: string,
    typeAddress: string,
    main: boolean
  ) {
    this.id = id;
    this.cep = cep;
    this.address = address;
    this.number = number;
    this.city = city;
    this.state = state;
    this.country = country;
    this.neighborhood = neighborhood;
    this.complement = complement;
    this.companyId = companyId;
    this.typeAddressId = typeAddressId;
    this.typeAddress = typeAddress;
    this.main = main;
  }

  public static adapterToClass(address: any) {
    return new Address(
      address.id,
      address.cep,
      address.endereco,
      address.numero,
      address.cidade,
      address.estado,
      address.pais,
      address.bairro,
      address.complemento,
      address.empresaId,
      address.enderecoTipoId,
      address.enderecoTipo,
      address.principal
    );
  }

  public static adapterToJson(address: any) {
    return JSON.parse(address);
  }

  public static adapterToBody(address: iAddress) {
    const data: any = {
      cep: address.cep,
      endereco: address.address,
      numero: address.number,
      cidade: address.city,
      estado: address.state,
      bairro: address.neighborhood,
      pais: address.country,
      complemento: address.complement,
      empresaId: address.companyId,
      principal: address.main,
    };

    if (address.id) {
      data["id"] = address.id;
    }

    if (address.typeAddressId && address.typeAddressId !== "select") {
      data["enderecoTipoId"] = address.typeAddressId;
    }

    return data;
  }
}
