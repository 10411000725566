/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import { iCompanyPage } from "src/interfaces/company";
import { iImportContextProps } from "src/interfaces/import";
import importConsumer from "src/services/import";
import { saveAs } from "file-saver";
import { addHours, format } from "date-fns";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";

const ImportContext = createContext<iImportContextProps>(
  {} as iImportContextProps
);

export function ImportProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { openDialog, setOpenDialog } = useLayout();
  const { setDialogImport } = useShoppingProcess();

  const { getDemands } = useShoppingProccessDemand();

  const [loading, setLoading] = useState<boolean>(false);
  const [callItems, setCallItems] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [logs, setLogs] = useState<any[]>([]);
  const [logSelect, setLogSelect] = useState<any>(null);
  const [page, setPage] = useState<iCompanyPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const handleSelect = (data: any) => {
    setLogSelect(data);
  };

  const handleFileChange = (event: any, type: string) => {
    const file = event.target.files[0];

    setFileType(type);
    setFileName(file?.name);
    setSelectedFile(file);
  };

  const handleUpload = ({
    shoppingProcesSelect = null,
  }: {
    shoppingProcesSelect?: any;
  }) => {
    setLoading(true);

    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("arquivo", selectedFile);

        importConsumer
          .upload(formData, fileType, shoppingProcesSelect)
          .then((e) => {
            toast.success(e.data);
          })
          .catch((e: any) => {
            toast.error(
              e?.response?.data?.message ?? translations[param]["error_general"]
            );
          })
          .finally(async () => {
            setFileType("");
            setFileName("");
            setSelectedFile(null);
            setLoading(false);

            setCallItems(true);
            await getDemands(shoppingProcesSelect.id);

            setDialogImport(false);
          });
      }
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    }
  };

  const getAudits = async (type: string) => {
    setLoading(true);

    try {
      const response = await importConsumer.audit(type);

      if (response.status !== 200) throw response;

      setLogs(response.data.sort((a: any, b: any) => a.codigo - b.codigo));
    } catch (e: any) {
      toast.warning(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const generateHeader = (header: any[]) =>
    header.map((item) => item.label).join(", || ");

  const generateLines = (data: any[]) =>
    data
      ?.map((item) => {
        return [
          item.codigo,
          item.modulo,
          item?.auditoriaStatus?.descricao,
          format(addHours(new Date(item.dataInicio), 3), "dd/MM/yyy"),
          item.dataFim
            ? format(addHours(new Date(item.dataFim), 3), "dd/MM/yyy")
            : "Não informado",
          item.usuario,
          item.observacao,
        ]?.join(", || ");
      })
      ?.join("\n");

  const handleDownloadLog = (header: any[], type: string) => {
    if (!logSelect) return toast.warning("Data log está vazio!");

    header.push({ label: "Observações" });

    try {
      const file = new Blob(
        [generateHeader(header), "\n", generateLines([logSelect])],
        {
          type: "text/plain;charset=utf-8",
        }
      );

      saveAs(
        file,
        `log_${type}_data_${format(addHours(new Date(), 3), "dd/MM/yyyy")}.txt`
      );
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    }
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      openDialog,
      setOpenDialog,
      handleSelect,
      logs,
      setLogs,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      selectedFile,
      setSelectedFile,
      handleFileChange,
      handleUpload,
      fileName,
      setFileName,
      fileType,
      setFileType,
      getAudits,
      logSelect,
      setLogSelect,
      handleDownloadLog,
      callItems,
      setCallItems,
    };
  }, [
    loading,
    openDialog,
    logs,
    page,
    selectedFile,
    fileName,
    fileType,
    logSelect,
    callItems,
    setCallItems,
  ]);

  return (
    <ImportContext.Provider value={contextValue}>
      {children}
    </ImportContext.Provider>
  );
}

export default ImportContext;
