/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { iTabs, iContentTabs } from "src/interfaces/iTabs";
import useCompany from "src/hooks/useCompany";
import { useEffect } from "react";
import useAddress from "src/hooks/useAddress";
import useUser from "src/hooks/useUser";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import useProductCompany from "src/hooks/useProductCompany";
import useProductCategory from "src/hooks/useProductCategory";

interface iMenuTab {
  tabs: Array<iTabs>;
  contentTabs: Array<iContentTabs>;
  isDisabled: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MenuTabs({ tabs, contentTabs, isDisabled }: iMenuTab) {
  const { value, setValue } = useCompany();
  const { handleCleanForm } = useAddress();
  const { toFillUSerDataOnForm } = useUser();
  const { handleSelected } = usePaymentCondition();
  const { toFillUSerDataOnForm: toFillUSerDataOnFormProductCompany } =
    useProductCompany();
  const { toFillUSerDataOnForm: toFillUSerDataOnFormProductCategory } =
    useProductCategory();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    handleCleanForm();
    toFillUSerDataOnForm(null);
    handleSelected(null);
    toFillUSerDataOnFormProductCompany(null);
    toFillUSerDataOnFormProductCategory(null);
  }, [value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} textColor="secondary">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              disabled={isDisabled}
              sx={{
                "&.Mui-selected": {
                  background: "#3B9DB4",
                  borderRadius: 1,
                  color: "#ffffff",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {contentTabs.map((content, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {content.value}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
