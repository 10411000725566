/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import Layout from "src/components/Layout";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import {
  Autocomplete,
  Card,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import TableComponent from "src/components/TableComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import useRate from "src/hooks/useRate";
import {
  ButtonClosedForm,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import useNetwork from "src/hooks/useNetwork";
import useCompany from "src/hooks/useCompany";
import Empty from "src/components/Empty";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const numberSumWithPage = 40;

const Rate: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    rateSelect,
    loading,
    getRates,
    rates,
    handleSelected,
    handleNewSalve,
    handleUpdate,
    handleDelete,
    page,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
    networkId,
    setNetworkId,
    description,
    setDescription,
    rate,
    setRate,
    companyId,
    setCompanyId,
    setCompanyReceivedId,
    handleSortData,
    companyAdministrator,
    setCompanyAdministrator,
    partnerCompany,
    setPartnerCompany,
  } = useRate();

  const { loading: loadingNetwork, getNetworks, networks } = useNetwork();
  const {
    companysToSearchBuyer,
    companysToSearch,
    getCompanyBuyer,
    setName,
    name,
    nameBuyer,
    setNameBuyer,
    loadingSelectSearch,
    getCompanys,
  } = useCompany();

  useEffect(() => {
    setName("");
    setNameBuyer("");
    getRates();
    getNetworks();
  }, []);

  useEffect(() => {
    if (name?.length >= 4) {
      getCompanys({ isFilter: true });
    } else if (name?.length === 0) {
      getCompanys({ isFilter: false });
    }
  }, [name]);

  useEffect(() => {
    if (nameBuyer?.length >= 4) {
      getCompanyBuyer(numberSumWithPage);
    } else if (nameBuyer?.length === 0) {
      getCompanyBuyer(numberSumWithPage);
    }
  }, [nameBuyer]);

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["rates"],
    },
  ];

  const header = [
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
    },
    {
      id: "rate",
      label: translations[param]["rate"],
      isOrder: true,
    },
    {
      id: "networkDescription",
      label: translations[param]["network"],
      isOrder: false,
    },
    {
      id: "companyDescription",
      label: translations[param]["management_company"],
      isOrder: false,
    },
    {
      id: "companyReceived",
      label: translations[param]["partner_company"],
      isOrder: false,
    },
  ];

  if ((loading || loadingNetwork || loadingSelectSearch) && !partnerCompany) {
    return (
      <Layout buttons={[]} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() =>
        setPage({
          ...page,
          page: 0,
          total: 0,
        })
      }
    >
      <Card sx={{ padding: 2, width: "100%" }}>
        <FormLabel>Taxas: </FormLabel>
        <Divider sx={{ backgroundColor: palette.primary.main, marginY: 1 }} />
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (rateSelect?.id) {
              handleUpdate();
            } else {
              handleNewSalve();
            }
          }}
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid xs={6} sm={8} md={8}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "5%" }}>
                  {translations[param]["description"]}:
                </FormLabel>
                <TextField
                  size="small"
                  id="description"
                  name="description"
                  label={translations[param]["description"]}
                  variant="outlined"
                  required
                  sx={{ width: "82%" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </ItemCustom>
            </Grid>
            <Grid xs={6} sm={4} md={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "30%" }}>
                  {translations[param]["value"]}
                  {translations[param]["rate"]} (%):
                </FormLabel>
                <TextField
                  size="small"
                  id="rateValue"
                  name="rateValue"
                  label={`${translations[param]["value"]} ${translations[param]["rate"]} (%)`}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  required
                  sx={{ minWidth: "70%" }}
                  value={rate}
                  onChange={(e) => setRate(parseInt(e.target.value))}
                />
              </ItemCustom>
            </Grid>
            <Grid xs={6} sm={4} md={4}>
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "36%" }}>
                  {translations[param]["network"]}
                  <br />
                  {translations[param]["management"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <Select
                    labelId="show_select_network"
                    id="show_select_network"
                    value={networkId}
                    size="small"
                    disabled={companyId !== null && !rateSelect?.id}
                    onChange={(event: SelectChangeEvent) =>
                      setNetworkId(event.target.value as string)
                    }
                  >
                    <MenuItem value="select" disabled>
                      {translations[param]["select"]}:
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {networks.map((network) => (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ItemCustomStart>
            </Grid>
            <Grid xs={6} sm={4} md={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "38%" }}>
                  {translations[param]["company"]}
                  <br />
                  {translations[param]["management"]}:
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={companysToSearchBuyer}
                  value={companyAdministrator}
                  disabled={networkId !== "select" && !rateSelect?.id}
                  getOptionLabel={(option) => {
                    if (option) {
                      return option.label;
                    }
                    return "";
                  }}
                  sx={{ minWidth: "62%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setCompanyId(value?.id);
                      setCompanyAdministrator(value);
                    } else {
                      setCompanyId("");
                      setCompanyAdministrator(null);
                    }
                  }}
                  renderInput={(params) =>
                    loadingSelectSearch ? (
                      <CircularLoading />
                    ) : (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        onChange={(e: any) => setNameBuyer(e.target.value)}
                      />
                    )
                  }
                />
              </ItemCustom>
            </Grid>
            <Grid xs={6} sm={4} md={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "30%" }}>
                  {translations[param]["partner_company"]}:
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={companysToSearch}
                  value={partnerCompany}
                  getOptionLabel={(option) => {
                    if (option) {
                      return option.label;
                    }
                    return "";
                  }}
                  sx={{ minWidth: "70%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setCompanyReceivedId(value?.id);
                      setPartnerCompany(value);
                    } else {
                      setCompanyReceivedId("");
                      setPartnerCompany(null);
                    }
                  }}
                  renderInput={(params) =>
                    loadingSelectSearch ? (
                      <CircularLoading />
                    ) : (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        onChange={(e: any) => setName(e.target.value)}
                      />
                    )
                  }
                />
              </ItemCustom>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ marginTop: "2%" }}
            >
              <ButtonClosedForm
                label="Limpar formulário"
                onClick={() => handleSelected(null)}
                disabled={loading}
              />
              {rateSelect?.id ? (
                <ButtonSalveForm
                  label="Salvar"
                  type="submit"
                  disabled={loading}
                />
              ) : (
                <ButtonSalveForm
                  label="Incluir"
                  type="submit"
                  disabled={loading}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Card sx={{ padding: 2, width: "100%", marginTop: "2%" }}>
        {loading ? (
          <CircularLoading />
        ) : rates.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            funtionToOrder={(data) => handleSortData(data)}
          >
            {rates.map((rate) => (
              <TableRow
                hover
                key={rate.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelected(rate)}
                >
                  {rate.description}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelected(rate)}
                >
                  {rate.rate}%
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelected(rate)}
                >
                  {rate.networkDescription}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelected(rate)}
                >
                  {rate.companyDescription}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelected(rate)}
                >
                  {rate.companyReceived}
                </TableCell>
                <TableCell
                  onClick={() =>
                    Swal.fire({
                      title: "Atenção!",
                      text: "A taxa será deletada, deseja continuar ? ",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: palette.primary.main,
                      cancelButtonColor: palette.error.main,
                      confirmButtonText: "Sim, deletar!",
                      cancelButtonText: "Não",
                    }).then((result) => {
                      if (result.isConfirmed) handleDelete(rate);
                    })
                  }
                >
                  <DeleteIcon color="error" />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>
    </Layout>
  );
};

export default Rate;
