import Dialog from "@mui/material/Dialog";
interface iDialog {
  children: any;
  open: boolean;
  handleClose: () => void;
}

export default function DialogComponent(props: iDialog) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-component"
        aria-describedby="alert-dialog-description-component"
        maxWidth="xl"
        fullWidth={true}
      >
        {props.children}
      </Dialog>
    </div>
  );
}
