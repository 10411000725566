/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useUser from "src/hooks/useUser";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputMask from "react-input-mask";
import {
  ButtonActive,
  ButtonClosedForm,
  ButtonDefault,
  ButtonInactive,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import { DivFormsFieldLabel, MaskStyle } from "src/styles/globalStyle";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { iUser } from "src/interfaces/user";
import { maskForManyTypesString } from "src/functions/text";
import useCompany from "src/hooks/useCompany";
import Empty from "src/components/Empty";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import usePasswordRecovery from "src/hooks/usePasswordRecovery";

const UserComponent: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { companySelected } = useCompany();
  const { sendMail } = usePasswordRecovery();

  const {
    validationErrorEmail,
    validationErrorPassword,
    showPassword,
    handleClickShowPassword,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    active,
    setActive,
    main,
    setMain,
    username,
    setUsername,
    phone,
    setPhone,
    handleSalveNewUser,
    loading,
    toFillUSerDataOnForm,
    visibleRowsTableUSer,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getUsersOfCompany,
    dataUserSelect,
    handleUpdateUser,
    handleDeleteUser,
  } = useUser();

  const header = [
    {
      label: translations[param]["name"],
    },
    {
      label: translations[param]["phone"],
    },
    {
      label: translations[param]["email"],
    },
    {
      label: translations[param]["active"],
    },
    {
      label: translations[param]["main"],
    },
  ];

  useEffect(() => {
    getUsersOfCompany(companySelected?.id);
  }, []);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!dataUserSelect?.id) {
              handleSalveNewUser();
            } else {
              handleUpdateUser();
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={9} md={9} lg={9}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ minWidth: "6%" }}>
                  {translations[param]["name"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    label={translations[param]["name"]}
                    required
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            <Grid xs={6} sm={1} md={1} lg={1}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      disabled={dataUserSelect?.id ? true : false}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} sm={1} md={1} lg={1}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="main"
                      name="main"
                      size="small"
                      checked={main}
                      onChange={(e) => setMain(e.target.checked)}
                    />
                  }
                  label={translations[param]["main"]}
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={3} md={3} lg={3}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ minWidth: "18%" }}>
                  {translations[param]["user"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="username"
                    name="username"
                    label={translations[param]["user"]}
                    required
                    variant="outlined"
                    size="small"
                    disabled={dataUserSelect?.id ? true : false}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            <Grid xs={12} sm={3} md={3} lg={3}>
              <DivFormsFieldLabel>
                <FormLabel sx={{ marginRight: 1 }}>
                  {translations[param]["email"]}:{" "}
                </FormLabel>
                <FormControl>
                  <TextField
                    id="email"
                    name="email"
                    label={translations[param]["email"]}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    size="small"
                    error={validationErrorEmail}
                    helperText={
                      validationErrorEmail
                        ? translations[param][
                            "oops_check_if_your_email_is_correct"
                          ]
                        : ""
                    }
                  />
                </FormControl>
              </DivFormsFieldLabel>
            </Grid>
            {!dataUserSelect?.id && (
              <Grid xs={12} sm={3} md={3} lg={3}>
                <DivFormsFieldLabel>
                  <FormLabel sx={{ marginRight: 1 }}>
                    {translations[param]["password"]}:{" "}
                  </FormLabel>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="password"
                      name="password"
                      size="small"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      error={validationErrorPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {validationErrorPassword && (
                      <FormHelperText error={validationErrorPassword}>
                        {translations[param]["invalid_password_message"]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </DivFormsFieldLabel>
              </Grid>
            )}
            <Grid xs={12} sm={3} md={3} lg={3}>
              <MaskStyle>
                <DivFormsFieldLabel>
                  <FormLabel sx={{ marginRight: 1 }}>
                    {translations[param]["phone"]}:{" "}
                  </FormLabel>
                  <InputMask
                    mask="(99) 99999-9999"
                    name="phone"
                    placeholder={translations[param]["phone"]}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </DivFormsFieldLabel>
              </MaskStyle>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: 3 }}
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => toFillUSerDataOnForm(null)}
            />
            <FormControl>
              {dataUserSelect?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {visibleRowsTableUSer.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {visibleRowsTableUSer?.map((data: iUser) => (
                <TableRow
                  hover
                  key={data.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => toFillUSerDataOnForm(data)}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {maskForManyTypesString(
                      data?.phone ?? "",
                      "(##) #####-####"
                    )}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.email}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.isMain ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    {!data.active ? (
                      <ButtonActive
                        label={translations[param]["activate"]}
                        onClick={() => handleDeleteUser(data)}
                      />
                    ) : (
                      <ButtonInactive
                        label={translations[param]["inactive"]}
                        onClick={() => handleDeleteUser(data)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <ButtonDefault
                      label={translations[param]["change_password"]}
                      onClick={() =>
                        sendMail({
                          username: `${data.email}`,
                        })
                      }
                      fontSize="10px"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default UserComponent;
