/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { Order, iTableContextProps } from "src/interfaces/layout";

const TableContext = createContext<iTableContextProps>(
  {} as iTableContextProps
);

export function TableProvider({ children }: { children: any }) {
  const [order, setOrder] = useState<Order>("desc");
  const [fieldOrder, setFieldOrder] = useState<any>(null);

  const handleRequestSort = (order: Order) => setOrder(order);

  const contextValue = useMemo(() => {
    return {
      order,
      setOrder,
      handleRequestSort,
      fieldOrder,
      setFieldOrder,
    };
  }, [order, fieldOrder]);

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
}

export default TableContext;
