import httpClientShoppingProcess from "src/http/shoppingProcess";
import { iShoppingProccessStatusPage } from "src/interfaces/shoppingProccessStatus";

class shoppingProccessStatusPageConsumer {
  static get(page: iShoppingProccessStatusPage) {
    return httpClientShoppingProcess.get(
      `/ProcessoCompraStatus?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }
}

export default shoppingProccessStatusPageConsumer;
