import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

const SizeScreenNotSettingsIcon: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid>
          <ScreenshotMonitorIcon color="primary" sx={{ fontSize: 120 }} />
        </Grid>
        <Grid sx={{ marginBottom: 3, textAlign: "center" }}>
          <h3>Tamanho de tela não disponível no momento!</h3>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SizeScreenNotSettingsIcon;
