import { iProduct, iProductCategoryPage } from "src/interfaces/products";
import Product from "src/models/Product";
import httpClientRegister from "src/http/register";

class productConsumer {
  static get(
    page: iProductCategoryPage,
    code?: string,
    description?: string,
    activeSearch?: string,
    orderBy?: any,
    codeCategory?: any,
    descriptionCategory?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (code) search.append("Codigo", code);
    if (description) search.append("Descricao", description);
    if (activeSearch && activeSearch !== "select" && activeSearch !== "all")
      search.append("Ativo", activeSearch);

    if (codeCategory) search.append("CategoriaCodigo", codeCategory);
    if (descriptionCategory)
      search.append("CategoriaDescricao", descriptionCategory);

    return httpClientRegister.get(`/Produto?${search.toString()}`);
  }

  static getProductsOrderByCategories(
    page: iProductCategoryPage,
    code?: string,
    description?: string,
    activeSearch?: string,
    orderBy?: any,
    codeCategory?: any,
    descriptionCategory?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (code) search.append("Codigo", code);
    if (description) search.append("Descricao", description);
    if (activeSearch && activeSearch !== "select" && activeSearch !== "all")
      search.append("Ativo", activeSearch);

    if (codeCategory) search.append("CategoriaCodigo", codeCategory);
    if (descriptionCategory)
      search.append("CategoriaDescricao", descriptionCategory);

    return httpClientRegister.get(`/Produto/categoria?${search.toString()}`);
  }

  static created(product: iProduct) {
    return httpClientRegister.post(`/Produto`, Product.adapterToBody(product));
  }

  static updated(product: iProduct) {
    return httpClientRegister.put(`/Produto`, Product.adapterToBody(product));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Produto?id=${id}`);
  }
}

export default productConsumer;
