import httpClientRegister from "src/http/register";
import { iUnit, iunitPage } from "src/interfaces/unit";
import Unit from "src/models/Unit";

class unitConsumer {
  static get(page: iunitPage) {
    return httpClientRegister.get(
      `/Unidade?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static created(unit: iUnit) {
    return httpClientRegister.post(`/Unidade`, Unit.adapterToBody(unit));
  }

  static updated(unit: iUnit) {
    return httpClientRegister.put(`/Unidade`, Unit.adapterToBody(unit));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Unidade?id=${id}`);
  }
}

export default unitConsumer;
