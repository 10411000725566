/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { format, addHours } from "date-fns";
import React, { useEffect } from "react";
import { ButtonClosedForm, ButtonInclude } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useRate from "src/hooks/useRate";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import { iShoppingProccessRate } from "src/interfaces/shoppingProccessRate";
import { MaskStyle } from "src/styles/globalStyle";
import { palette } from "src/theme";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import useLayout from "src/hooks/useLayout";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const header = [
  {
    label: "Descrição",
  },
  {
    label: "Valor taxa",
  },
  {
    label: "Data Inicio",
  },
  {
    label: "Data Fim",
  },
];

const Rate: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { width } = useLayout();
  const { getRates, rates, loading } = useRate();
  const {
    getTaxaPc,
    shoppingProccessRates,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    taxasId,
    setTaxasId,
    valorTaxa,
    setValorTaxa,
    dataInicio,
    setDataInicio,
    dataFim,
    setDataFim,
    handleSelect,
    handleSalve,
    handleUpdate,
    handleDelete,
    shoppingProccessRateSelect,
    valorTaxaTotal,
  } = useShoppingProccessRate();

  useEffect(() => {
    getRates();
    getTaxaPc();
  }, []);

  return (
    <>
      <Card sx={{ padding: 2, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (shoppingProccessRateSelect?.id) {
              handleUpdate();
            } else {
              handleSalve();
            }
          }}
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container alignItems="center" spacing={2}>
                {width >= 1500 ? (
                  <Grid item xs={3} sm={2} md={2.5}>
                    <FormLabel>{translations[param]["rate"]}:</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={9} sm={10} md={width >= 1500 ? 9.5 : 12}>
                  <FormControl fullWidth>
                    <Select
                      labelId="show_select_type"
                      id="show_select_type"
                      value={taxasId}
                      size="small"
                      onChange={(event: SelectChangeEvent) => {
                        setTaxasId(event.target.value as string);
                        setValorTaxa(
                          parseInt(event.target.value?.split("_")[1])
                        );
                      }}
                    >
                      <MenuItem value="select" disabled>
                        {width <= 1500
                          ? `${translations[param]["rate"]} - ${translations[param]["select"]}`
                          : translations[param]["select"]}
                        :
                      </MenuItem>
                      <MenuItem value="">...</MenuItem>
                      {rates.map((item) => (
                        <MenuItem
                          value={`${item.id}_${item.rate}`}
                          key={item.id}
                        >
                          {item.description} - {item.rate}%
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Grid container alignItems="center" spacing={2}>
                {width >= 1500 ? (
                  <Grid item xs={6}>
                    <FormLabel>Valor Taxa (%):</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={6} md={width >= 1500 ? 6 : 12}>
                  <TextField
                    size="small"
                    id="monthNumber"
                    name="monthNumber"
                    label="Valor Taxa (%)"
                    variant="outlined"
                    value={`${valorTaxa}%`}
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Grid container alignItems="center" spacing={2}>
                {width >= 1500 ? (
                  <Grid item xs={5}>
                    <FormLabel>Data Inicio:</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={7} md={width >= 1500 ? 7 : 12}>
                  <Box sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <MaskStyle>
                        <input
                          type="date"
                          name="begin_date"
                          placeholder="dd/mm/yyyy"
                          min="1970-01-01"
                          value={dataInicio}
                          onChange={(e) => setDataInicio(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </MaskStyle>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                {width >= 1500 ? (
                  <Grid item xs={5}>
                    <FormLabel>Data Fim:</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={7} md={width >= 1500 ? 7 : 12}>
                  <Box sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <MaskStyle>
                        <input
                          type="date"
                          name="end_date"
                          placeholder="dd/mm/yyyy"
                          min="1970-01-01"
                          value={dataFim}
                          onChange={(e) => setDataFim(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </MaskStyle>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Grid container alignItems="center" spacing={2}>
                {width >= 1500 ? (
                  <Grid item xs={6}>
                    <FormLabel>Valor Total Taxa (%):</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={6} md={width >= 1500 ? 6 : 12}>
                  <TextField
                    size="small"
                    id="totalTaxa"
                    name="totalTaxa"
                    label="Valor Total Taxa (%)"
                    variant="outlined"
                    disabled
                    value={`${valorTaxaTotal}%`}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ marginBottom: 2 }}
              display="flex"
              justifyContent="end"
            >
              <ButtonClosedForm
                label="Limpar formulário"
                disabled={loading}
                onClick={() => handleSelect(null)}
              />
              {shoppingProccessRateSelect?.id ? (
                <ButtonInclude
                  label="Salvar"
                  disabled={loading}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label="Incluir"
                  disabled={loading}
                  type="submit"
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Card sx={{ marginTop: 2 }}>
        {loading ? (
          <CircularLoading />
        ) : shoppingProccessRates.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {shoppingProccessRates.map((item: iShoppingProccessRate) => (
              <TableRow
                hover
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelect(item)}
                >
                  {item.descricao}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelect(item)}
                >
                  {item.valorTaxa}%
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelect(item)}
                >
                  {item.dataInicio
                    ? format(
                        addHours(new Date(item.dataInicio), 3),
                        "dd/MM/yyyy"
                      )
                    : null}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleSelect(item)}
                >
                  {item.dataFim
                    ? format(addHours(new Date(item.dataFim), 3), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() =>
                    Swal.fire({
                      title: "Atenção!",
                      text: "A taxa será deletado, deseja continuar ? ",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: palette.primary.main,
                      cancelButtonColor: palette.error.main,
                      confirmButtonText: "Sim, deletar!",
                      cancelButtonText: "Não",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(item);
                      }
                    })
                  }
                >
                  <DeleteIcon color="error" />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>
    </>
  );
};

export default Rate;
