import { iShoppingProcessPageType } from "src/interfaces/shoppingProcessType";
import shoppingProcess from "src/http/shoppingProcess";

class shoppingProcessPhaseTypeConsumer {
  static get(page: iShoppingProcessPageType) {
    return shoppingProcess.get(
      `/ProcessoCompraEtapaTipo?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }
}

export default shoppingProcessPhaseTypeConsumer;
