import { iLogin } from "src/interfaces/login";

export default class Login implements iLogin {
  email?: String;
  username?: String;
  password?: String;
  remember?: boolean;
  validation?: String;

  constructor(
    email: String,
    username: String,
    password: String,
    remember: boolean,
    validation: String
  ) {
    this.email = email;
    this.username = username;
    this.password = password;
    this.remember = remember;
    this.validation = validation;
  }

  public static verifyEmail(email: String): boolean {
    if (typeof email !== "string") return false;

    if (email === "") return false;

    let reg =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (reg.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  public static verifyPassword(password: String): boolean {
    if (typeof password !== "string") return false;
    if (password === "") return false;
    if (password.length < 8) return false;

    let uppercase_letters = /[A-Z]/;
    let lowercase_letters = /[a-z]/;
    let numbers = /[0-9]/;
    let special_characters = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    if (
      !uppercase_letters.test(password) ||
      !lowercase_letters.test(password) ||
      !numbers.test(password) ||
      !special_characters.test(password)
    ) {
      return false;
    }

    return true;
  }

  public static checkEqualsPassword(
    password: String,
    confirmPassword: String
  ): boolean {
    if (password.length !== confirmPassword.length) return false;
    if (password !== confirmPassword) return false;

    return true;
  }
}
