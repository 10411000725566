import { iPaymentCondition } from "src/interfaces/paymentCondition";

export default class PaymentCondition implements iPaymentCondition {
  id;
  code;
  description;
  daysNumber;
  installments;
  active;
  companyId;

  constructor(
    id: string | null,
    code: string,
    description: string,
    daysNumber: number,
    installments: number,
    active: boolean,
    companyId: string
  ) {
    this.id = id;
    this.code = code;
    this.description = description;
    this.daysNumber = daysNumber;
    this.installments = installments;
    this.active = active;
    this.companyId = companyId;
  }

  public static adapterToClass(paymentCondition: any) {
    return new PaymentCondition(
      paymentCondition.id,
      paymentCondition.codigo,
      paymentCondition.descricao,
      paymentCondition.numeroDias,
      paymentCondition.parcelas,
      paymentCondition.ativo,
      paymentCondition.empresaId
    );
  }

  public static adapterToJson(paymentCondition: any) {
    return JSON.parse(paymentCondition);
  }

  public static adapterToBody(paymentCondition: iPaymentCondition) {
    return {
      id: paymentCondition.id,
      codigo: paymentCondition.code,
      descricao: paymentCondition.description,
      numeroDias: paymentCondition.daysNumber,
      parcelas: paymentCondition.installments,
      ativo: paymentCondition.active,
      empresaId: paymentCondition.companyId,
    };
  }
}
