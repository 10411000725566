import httpClientRequest from "src/http/request";
import { iPage } from "src/interfaces/layout";
import { iRequestItem } from "src/interfaces/requestItem";
import RequestItem from "src/models/RequestItem";

class requestItemConsumer {
  static get(page: iPage, requestId: string) {
    const search = new URLSearchParams();
    search.append("Pagina", `${page.page}`);
    search.append("TamanhoPagina", page.rowsPerPage.toPrecision());

    search.append("PedidoId", `${requestId}`);

    return httpClientRequest.get(`/PedidoItem?${search.toString()}`);
  }

  static created(request: iRequestItem) {
    return httpClientRequest.post(
      `/PedidoItem`,
      RequestItem.adapterToBody(request)
    );
  }

  static updated(request: iRequestItem) {
    return httpClientRequest.put(
      `/PedidoItem/${request?.id}`,
      RequestItem.adapterToBody(request)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRequest.delete(`/PedidoItem/${id}`);
  }
}

export default requestItemConsumer;
