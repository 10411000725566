/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DocumentFactory from "src/factories/DocumentFactory";
import { iPage } from "src/interfaces/layout";
import Documents from "src/services/documents";
import { palette } from "src/theme";
import Swal from "sweetalert2";

const DocumentsContext = createContext<any>({} as any);

interface sendDocument {
  empresaId: string;
  documentosTipoId: string;
  nomeArquivo: string;
  documentoBase64: string | ArrayBuffer | null;
}

export function DocumentsProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [documentsSelect, setDocumentsSelect] = useState<any>(null);
  const [typesDocuments, setTypesDocuments] = useState<any>(null);
  const [documents, setDocuments] = useState<any>(null);
  const [documentInput, setDocumentInput] = useState<sendDocument | null>({
    empresaId: "",
    documentosTipoId: "",
    nomeArquivo: "Nenhum arquivo...",
    documentoBase64: "",
  });

  const [pageType, setPageType] = useState<iPage>({
    page: 0,
    rowsPerPage: 100,
    total: 0,
  });

  const getDocuments = async ({ idCompany }: { idCompany: string }) => {
    try {
      setLoading(true);

      const response = await Documents.getDocuments({
        empresaId: idCompany,
      });

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      setDocuments(response.data);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar o documento desejado!"
      );
    }
  };

  const getDocumentById = async (id: string) => {
    const response = await Documents.downloadDocumentById({ documentoId: id });

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      const { data } = response;

      const link = document.createElement("a");
      link.href = data.documento;
      link.download = data.nomeArquivo;

      const newTab = window.open();
      newTab?.document.write(
        `<img src="data:image/png;base64,${data.documento}" />`
      );
    }

    setLoading(false);
  };

  const getDocumentTypes = async () => {
    try {
      setLoading(true);

      const response = await Documents.getTypes({ page: pageType });

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      setTypesDocuments(response?.data?.items ?? []);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar o documento desejado!"
      );
    } finally {
      setLoading(false);
    }
  };

  const sendDocuments = async ({
    code,
    cnpj,
    companyId,
    typeDocumentId,
  }: {
    code: number;
    cnpj: string;
    companyId: string;
    typeDocumentId: string;
  }) => {
    try {
      setLoading(true);
      await DocumentFactory.send({
        code,
        cnpj,
        companyId,
        typeDocumentId,
      });

      Swal.fire({
        title: "Requisição iniciada!",
        text: `O robô iniciou a requisição para buscar o documento solicitado, favor aguarde alguns minutos e em seguida tenta fazer o download do documento!`,
        icon: "success",
        confirmButtonColor: palette.primary.main,
        confirmButtonText: "Ok",
      });
    } catch {
      toast.error(
        "Ops... identificamos um erro para encontrar o documento desejado!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (
    event: any,
    company: any,
    typeDocumentId: any
  ) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        toast.warning(
          "Atenção, só é permitido arquivos dos tipos PNG, JPG, JPEG ou PDF."
        );
        return;
      }

      const regex = /^.*(?=base64,)base64,/;

      const result = await getFileBase64(file);
      const base64 = result as string;

      setDocumentInput({
        empresaId: `${company?.id}`,
        documentosTipoId: `${typeDocumentId}`,
        nomeArquivo: file.name,
        documentoBase64: base64?.replace(regex, ""),

        // ^.*(?=base64,)base64,
      });
    }
  };

  const getFileBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileClear = () => {
    setDocumentInput({
      empresaId: "",
      documentosTipoId: "",
      nomeArquivo: "Nenhum arquivo...",
      documentoBase64: "",
    });
  };

  const upload = async () => {
    setLoading(true);

    try {
      const response = await Documents.uploadDocument({ body: documentInput });

      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 204
      )
        throw response;

      toast.success("Upload feito com sucesso!");
      handleFileClear();
    } catch (error: any) {
      toast.warning(
        error?.response?.data?.message ??
          "Ops. Tivemos um problema ao fazer o upload do arquivo!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      getDocuments,
      getDocumentTypes,
      loading,
      setLoading,
      sendDocuments,
      documentsSelect,
      setDocumentsSelect,
      typesDocuments,
      setPageType,
      getDocumentById,
      documents,
      handleFileChange,
      documentInput,
      setDocumentInput,
      handleFileClear,
      upload,
    };
  }, [loading, documentsSelect, typesDocuments, documents, documentInput]);

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
    </DocumentsContext.Provider>
  );
}

export default DocumentsContext;
