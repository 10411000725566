import { experimentalStyled as styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export const ItemCustom = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ItemCustomStart = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
}));
