import { localStorageStrings } from "src/constants/localStorageStings";
import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE_AUTH_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem(localStorageStrings.apiToken);

  if (
    config.url === "/api/v1/auth/login" ||
    config.url === "/api/v1/users/updateuserpassword"
  )
    return config;

  if (token) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
