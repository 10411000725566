import { iProductCompany } from "src/interfaces/products";

export default class ProductCompany implements iProductCompany {
  id;
  productId;
  companyId;
  codeProductCompany;
  description;
  unit;
  category;
  highCubage;
  categoryProductId;
  sku;
  factorCash;
  active;
  defaultProd;
  produtoPrincipalDescricao?: boolean;
  categoryProduct?: any;

  constructor(
    id: string | null,
    productId: string,
    companyId: string,
    codeProductCompany: string,
    description: string,
    unit: string,
    category: string,
    highCubage: boolean,
    categoryProductId: any,
    sku: string,
    factorCash: number,
    active: boolean,
    defaultProd: boolean,
    produtoPrincipalDescricao?: boolean,
    categoryProduct?: any
  ) {
    this.id = id;
    this.productId = productId;
    this.companyId = companyId;
    this.codeProductCompany = codeProductCompany;
    this.description = description;
    this.unit = unit;
    this.category = category;
    this.highCubage = highCubage;
    this.categoryProductId = categoryProductId;
    this.sku = sku;
    this.factorCash = factorCash;
    this.active = active;
    this.defaultProd = defaultProd;
    this.produtoPrincipalDescricao = produtoPrincipalDescricao;
    this.categoryProduct = categoryProduct;
  }

  public static adapterToClass(product: any) {
    return new ProductCompany(
      product.id,
      product.produtoId,
      product.empresaId,
      product.codigoProdutoEmpresa,
      product.descricao,
      product.unidade,
      product.categoriaProduto,
      product.altaCubagem,
      product.categoriaProdutoEmpresaId,
      product.sku,
      product.fatorCaixa,
      product.ativo,
      product.padrao,
      product.produtoPrincipalDescricao,
      product.categoriaProdutoEmpresa
    );
  }

  public static adapterToJson(product: any) {
    return JSON.parse(product);
  }

  public static adapterToBody(product: iProductCompany) {
    const data: any = {
      empresaId: product.companyId,
      descricao: product.description,
      unidade: product.unit,
      altaCubagem: product.highCubage,
      fatorCaixa: product.factorCash,
      ativo: product.active,
      padrao: product.defaultProd,
    };

    if (product?.id) data["id"] = product.id;

    if (product?.productId) {
      data["produtoId"] = product.productId;
    }

    if (product?.codeProductCompany)
      data["codigoProdutoEmpresa"] = product.codeProductCompany;

    if (product?.category && product?.category !== "select")
      data["categoria"] = product.category;

    if (product?.categoryProductId)
      data["CategoriaProdutoEmpresaId"] = product.categoryProductId;

    if (product?.sku) data["sku"] = product.sku;

    return data;
  }
}
