import { styled } from "@mui/material/styles";

export const TitleHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

export const AvatarClick = styled("div")(() => ({
  cursor: "pointer",
}));
