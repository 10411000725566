import { iRequestStatus } from "src/interfaces/requestStatus";

export default class RequestStatus implements iRequestStatus {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    this.id = id;
    this.description = description;
  }

  public static adapterToClass(requestStatus: any) {
    return new RequestStatus(requestStatus.id, requestStatus.descricao);
  }
}
