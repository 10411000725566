import EngineeringIcon from "@mui/icons-material/Engineering";
import translations from "src/_i18n/translations.json";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const UnderConstruction: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid>
          <EngineeringIcon color="primary" sx={{ fontSize: 120 }} />
        </Grid>
        <Grid sx={{ marginBottom: 3, textAlign: "center" }}>
          <h3>{translations[param]["page_under_construction"]}!</h3>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnderConstruction;
