/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  Button,
  Divider,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";

import { marginTop } from "./style";

import LOGO from "src/assets/images/Logo.svg";
import { useWindowSize } from "@react-hook/window-size";
import { palette } from "src/theme";
import CircularLoading from "src/components/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import usePasswordReset from "src/hooks/usePasswordReset";
import { ToastContainer } from "react-toastify";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const PasswordReset: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    loading,
    validationErrorPassword,
    showPassword,
    handleClickShowPassword,
    showConfirmePassword,
    handleClickShowConfirmePassword,
    getTokenInUrl,
  } = usePasswordReset();

  const [width] = useWindowSize();
  const [sizeWidthCard, setSizeWidthCard] = useState<number>(345);

  useEffect(() => {
    calculateSize();
  }, [width]);

  const calculateSize = () => {
    if (width >= 1024) {
      setSizeWidthCard(360);
    } else if (width >= 600) {
      setSizeWidthCard(600);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 2, background: palette.primary.main }}
    >
      <ToastContainer />
      <Card sx={{ padding: 2, maxWidth: sizeWidthCard }}>
        <CardContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {translations[param]["set_your_new_password"]}
            </Typography>
          </Grid>
          <Divider style={{ backgroundColor: palette.primary.main }} />
          <form onSubmit={(e) => getTokenInUrl(e)}>
            <FormControl fullWidth sx={marginTop}>
              <InputLabel htmlFor="password">
                {translations[param]["password"]}
              </InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                label={translations[param]["password"]}
                type={showPassword ? "text" : "password"}
                error={validationErrorPassword}
                disabled={loading}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {validationErrorPassword && (
                <FormHelperText error={validationErrorPassword}>
                  {
                    translations[param][
                      "the_password_you_entered_is_not_valid_please_try_again"
                    ]
                  }
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth sx={marginTop}>
              <InputLabel htmlFor="password">
                {translations[param]["confirm_the_password"]}
              </InputLabel>
              <OutlinedInput
                id="password_confirm"
                name="password_confirm"
                label={translations[param]["confirm_the_password"]}
                type={showConfirmePassword ? "text" : "password"}
                disabled={loading}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmePassword}
                    >
                      {showConfirmePassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl fullWidth sx={marginTop}>
              <Button variant="contained" type="submit" disabled={loading}>
                {translations[param]["change_password"]}
              </Button>
            </FormControl>
          </form>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <img alt="logo" src={LOGO} />
          </Grid>

          <br />
          {loading && <CircularLoading />}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PasswordReset;
