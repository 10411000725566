import { iMenuRoutes } from "src/interfaces/menuRoutes";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import Home from "src/pages/Home";
import ShoppingProcess from "src/pages/ShoppingProcess";
import Requests from "src/pages/Requests";
import Invoicing from "src/pages/Invoicing";
import Reports from "src/pages/Reports";
import Company from "src/pages/Company";
import Products from "src/pages/Products";
import CreateOrUpdate from "src/pages/Products/CreateOrUpdate";
import StepsCompany from "src/pages/Company/Steps";
import StepsShoppingProcess from "src/pages/ShoppingProcess/Steps";
import Rate from "src/pages/Rate";
import ImportExport from "src/pages/ImportExport";
import ProductCategoryGeneral from "src/pages/ProductCategoryGeneral";
import Routine from "src/pages/Routine";
import FromToProducts from "src/pages/FromToProducts";
import PowerBI from "src/pages/PowerBI";
import PowerBiEinsten from "src/pages/PowerBiEinsten";
import Cnes from "src/pages/Cnes";
import PowerBiInvoice from "src/pages/PowerBiInvoice";
import FromToCategories from "src/pages/FromToCategories";
import RegisterSteps from "src/pages/Requests/Steps";
import MenuChoosen from "src/pages/MenuChoosen";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import CreateOrUpdateInvoicing from "src/pages/Invoicing/CreateOrUpdate";
import ShoppingProccessPdf from "src/pages/pdfs/ShoppingProccessPdf";
import DemandCollectionForm from "src/pages/DemandCollectionForm";
import AttributesProduct from "src/pages/AttributesProduct";
import BatchBilling from "src/pages/Invoicing/BatchBilling";

export const MenuRoutes = (): Array<iMenuRoutes> => {
  const { lang } = useParams();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const data = param === undefined ? "pt" : param;

  const subMenusPowerBI = [
    {
      path: "/:lang/power/bi/einsten",
      name: `${translations[data]["order_management"]} - Einstein`,
      component: <PowerBiEinsten />,
      icon: <DvrOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/power/bi/requests",
      name: `${translations[data]["order_management"]}`,
      component: <PowerBI />,
      icon: <BarChartOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/power/bi/cnes",
      name: "CNES",
      component: <Cnes />,
      icon: <InsertChartOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/power/bi/invoce",
      name: `${translations[data]["invoicing"]}`,
      component: <PowerBiInvoice />,
      icon: <MonetizationOnOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
  ];

  const subMenusRoutines = [
    {
      path: "/:lang/routines/requests",
      name: `${translations[data]["from_to_orders"]}`,
      component: <Routine />,
      icon: <DescriptionOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/routines/products",
      name: `${translations[data]["from_to_products"]}`,
      component: <FromToProducts />,
      icon: <AddShoppingCartOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/routines/category",
      name: `${translations[data]["from_to_category"]}`,
      component: <FromToCategories />,
      icon: <FactCheckOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
  ];

  const subMenusSettings = [
    {
      path: "/:lang/settings/companys",
      name: `${translations[data]["companys"]}`,
      component: <Company />,
      icon: <ApartmentOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/settings/products",
      name: `${translations[data]["products"]}`,
      component: <Products />,
      icon: <ShoppingCartOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/settings/rates",
      name: `${translations[data]["rates"]}`,
      component: <Rate />,
      icon: <ArticleOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/settings/product/category",
      name: `${translations[data]["product_category"]}`,
      component: <ProductCategoryGeneral />,
      icon: <InventoryOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/settings/attributes",
      name: `${translations[data]["attributes"]} ${translations[data]["product"]}`,
      component: <AttributesProduct />,
      icon: <CategoryOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/settings/import",
      name: `${translations[data]["import"]}`,
      component: <ImportExport />,
      icon: <UploadFileOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: false,
    },
    {
      path: "/:lang/routines",
      name: `${translations[data]["routines"]}`,
      component: <MenuChoosen subMenus={subMenusRoutines} />,
      icon: <BallotOutlinedIcon color="primary" />,
      isSubmenu: true,
      isSubmenuFromSubmenu: true,
      expanded: true,
      submenus: [...subMenusRoutines],
    },
  ];

  return [
    ...subMenusSettings,
    ...subMenusRoutines,
    // ...subMenusPowerBI,
    {
      path: "/:lang/home",
      name: `${translations[data]["home"]}`,
      component: <Home />,
      icon: <HomeOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/shopping/process",
      name: `${translations[data]["purchasing_processes"]}`,
      component: <ShoppingProcess />,
      icon: <CreditCardOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/shopping/process/pdf",
      name: "",
      component: <ShoppingProccessPdf />,
      icon: null,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/requests",
      name: `${translations[data]["requests"]}`,
      component: <Requests />,
      icon: <DescriptionOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/invoicing",
      name: `${translations[data]["invoicing"]}`,
      component: <Invoicing />,
      icon: <AttachMoneyOutlinedIcon color="primary" />,
      expanded: false,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/invocing/register",
      name: "",
      component: <CreateOrUpdateInvoicing />,
      icon: null,
      expanded: true,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/invocing/register/lote",
      name: "",
      component: <BatchBilling />,
      icon: null,
      expanded: true,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    // {
    //   path: "/:lang/reports",
    //   name: `${translations[data]["reports"]}`,
    //   component: <Reports />,
    //   icon: (
    //     <NewspaperOutlinedIcon
    //       color="primary"
    //       sx={{
    //         transform: "rotate(180deg)",
    //       }}
    //     />
    //   ),
    //   expanded: false,
    //   isSubmenu: false,
    //   isSubmenuFromSubmenu: false,
    //   submenus: [],
    // },
    // {
    //   path: "/:lang/power/bi",
    //   name: "PowerBI",
    //   component: <></>,
    //   icon: <ComputerOutlinedIcon color="primary" />,
    //   expanded: true,
    //   isSubmenu: false,
    //   isSubmenuFromSubmenu: false,
    //   submenus: [...subMenusPowerBI],
    // },
    {
      path: "/:lang/settings",
      name: `${translations[data]["settings"]}`,
      component: <></>,
      icon: <SettingsOutlinedIcon color="primary" />,
      expanded: true,
      isSubmenu: false,
      isSubmenuFromSubmenu: false,
      submenus: [...subMenusSettings],
    },
    {
      path: "/:lang/settings/companys/register",
      name: "",
      component: <StepsCompany />,
      icon: null,
      expanded: true,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/settings/products/create",
      name: "",
      component: <CreateOrUpdate />,
      icon: null,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      expanded: true,
      submenus: [],
    },
    {
      path: "/:lang/shopping/process/register",
      name: "",
      component: <StepsShoppingProcess />,
      icon: null,
      expanded: true,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/request/register",
      name: "",
      component: <RegisterSteps />,
      icon: null,
      expanded: true,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
    {
      path: "/:lang/shopping/process/demand/collection/form",
      name: "",
      component: <DemandCollectionForm />,
      icon: null,
      expanded: false,
      isSubmenu: true,
      isSubmenuFromSubmenu: false,
      submenus: [],
    },
  ];
};
