/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect } from "react";
import DialogComponent from "src/components/Dialog";
import { ItemCustom } from "src/components/ItemCustom";
import useLayout from "src/hooks/useLayout";
import { palette } from "src/theme";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import { formatCurrency } from "src/functions/text";
import CloseIcon from "@mui/icons-material/Close";
import useRequestItem from "src/hooks/useRequestItem";
import useRequest from "src/hooks/useRequest";
import CircularLoading from "src/components/Loading";
import useProductCompany from "src/hooks/useProductCompany";
import SearchIcon from "@mui/icons-material/Search";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";

const ItemRequest: React.FC = () => {
  const { openDialog, setOpenDialog } = useLayout();

  const {
    loading,
    handleSelect,
    codeItem,
    setCodeItem,
    setCode,
    setDescription,
    descriptionItem,
    setDescriptionItem,
    unit,
    setUnit,
    quantity,
    setQuantity,
    unitValue,
    setUnitValue,
    shoppingProccessItem,
    setShoppingProccessItemId,
    setShoppingProccessItemLabel,
    setTotalValue,
    handleSalve,
    handleUpdate,
    setShoppingProccessItem,
    requestItemSelect,
    dialogProductsSearch,
    setDialogProductsSearch,
  } = useRequestItem();

  const { itemsPhaseShoppingProccess, requestSelect, buyerId } = useRequest();

  const {
    getProducts,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    productsToSearch,
    setProductsToSearch,
    loading: loadingProductCompany,
    codeProductCompany,
    setCodeProductCompany,
    description: descriptionProduct,
    setDescription: setDescriptionProduct,
  } = useProductCompany();

  useEffect(() => {
    if (requestSelect?.buyerId) {
      if (codeProductCompany.length >= 4) {
        getProducts(requestSelect.buyerId, page.page, page.rowsPerPage, false);
      } else if (descriptionProduct.length >= 4) {
        getProducts(requestSelect.buyerId, page.page, page.rowsPerPage, false);
      } else if (
        codeProductCompany.length === 0 &&
        descriptionProduct.length === 0
      ) {
        getProducts(requestSelect.buyerId, page.page, page.rowsPerPage, false);
      }
    }
  }, [descriptionProduct, codeProductCompany]);

  useEffect(() => {
    if (requestSelect?.buyerId) {
      getProducts(requestSelect.buyerId, page.page, page.rowsPerPage, false);
    }
  }, [page]);

  return (
    <>
      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Item do pedido</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialog(false);
            handleSelect(null);
            setShoppingProccessItem(null);
            setProductsToSearch([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ backgroundColor: palette.primary.main }} />
        {loading ? (
          <CircularLoading />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (requestItemSelect?.id) {
                handleUpdate(requestSelect?.id ?? "");
              } else {
                handleSalve(requestSelect?.id ?? "");
              }
            }}
          >
            <DialogContent>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid xs={12} sm={12} md={12}>
                  <ItemCustom>
                    <FormLabel sx={{ width: "19%" }}>
                      Item do processo de compra
                    </FormLabel>

                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="show_select_item"
                      options={itemsPhaseShoppingProccess?.filter(
                        (e) => e.compradorId === buyerId
                      )}
                      value={shoppingProccessItem}
                      getOptionLabel={(option) => {
                        if (option?.code) {
                          return `${option.code} - ${option?.label}`;
                        }

                        return "";
                      }}
                      onChange={(_: any, value: any) => {
                        if (value?.id) {
                          setShoppingProccessItem(value);
                          setShoppingProccessItemId(value.id);
                          setShoppingProccessItemLabel(value?.label);
                          setCodeItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setDescriptionItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setCode(value.code);
                          setDescription(value.label);
                          setUnit(value.unidade);
                          setQuantity(value.quantidade);
                          setUnitValue(
                            value.valorUnitario.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          );
                        } else {
                          setShoppingProccessItem(null);
                          setShoppingProccessItemId("");
                          setShoppingProccessItemLabel("");
                          setCodeItem(null);
                          setDescriptionItem(null);
                          setCode("");
                          setDescription("");
                          setUnit("select");
                          setQuantity("");
                          setTotalValue("");
                          setUnitValue("R$ 0");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione:"
                          size="small"
                        />
                      )}
                    />
                  </ItemCustom>
                </Grid>
                <Grid xs={12} sm={12} md={12}>
                  <ItemCustom>
                    <FormLabel sx={{ minWidth: "15.5%" }}>Código</FormLabel>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="show_select_item"
                      options={productsToSearch}
                      value={codeItem}
                      getOptionLabel={(option) => {
                        if (option) {
                          return `${option?.code} - ${option.label}`;
                        }

                        return "";
                      }}
                      onChange={(_: any, value: any) => {
                        if (value?.id) {
                          setCodeItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setDescriptionItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setShoppingProccessItem({
                            produtoEmpresaId: value.id,
                          });
                          setCode(value.code);
                          setDescription(value.label);
                          setUnit(value.unidade);
                        } else {
                          setCodeItem(null);
                          setDescriptionItem(null);
                          setShoppingProccessItem(null);
                          setCode("");
                          setDescription("");
                          setUnit("");
                        }
                      }}
                      renderInput={(params) =>
                        loadingProductCompany ? (
                          <CircularLoading />
                        ) : (
                          <TextField
                            {...params}
                            label="Selecione o código do item:"
                            size="small"
                            onChange={(e) =>
                              setCodeProductCompany(e.target.value)
                            }
                          />
                        )
                      }
                    />
                    <IconButton
                      aria-label="search-secondary"
                      color="primary"
                      onClick={() => {
                        setDialogProductsSearch(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </ItemCustom>
                </Grid>
                <Grid xs={12} sm={12} md={12}>
                  <ItemCustom>
                    <FormLabel sx={{ minWidth: "15.5%" }}>Descrição</FormLabel>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="show_select_item"
                      options={productsToSearch}
                      value={descriptionItem}
                      getOptionLabel={(option) => {
                        if (option) {
                          return `${option?.code} - ${option.label}`;
                        }

                        return "";
                      }}
                      onChange={(_: any, value: any) => {
                        if (value?.id) {
                          setDescriptionItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setCodeItem({
                            code: value?.code,
                            label: value.label,
                          });
                          setShoppingProccessItem({
                            produtoEmpresaId: value.id,
                          });
                          setCode(value.code);
                          setDescription(value.label);
                          setUnit(value.unidade);
                        } else {
                          setDescriptionItem(null);
                          setCodeItem(null);
                          setShoppingProccessItem(null);
                          setCode("");
                          setDescription("");
                          setUnit("");
                        }
                      }}
                      renderInput={(params) =>
                        loadingProductCompany ? (
                          <CircularLoading />
                        ) : (
                          <TextField
                            {...params}
                            label="Selecione a descrição do item:"
                            size="small"
                            onChange={(e) =>
                              setDescriptionProduct(e.target.value)
                            }
                          />
                        )
                      }
                    />
                    <IconButton
                      aria-label="search-secondary"
                      color="primary"
                      onClick={() => {
                        setDialogProductsSearch(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </ItemCustom>
                </Grid>
                <Grid xs={12} sm={4} md={4} lg={4}>
                  <ItemCustom>
                    <FormLabel sx={{ minWidth: "47%" }}>Unidade:</FormLabel>
                    <FormControl fullWidth>
                      <FormControl fullWidth>
                        <TextField
                          label="Unidade"
                          size="small"
                          value={unit}
                          disabled
                        />
                      </FormControl>
                    </FormControl>
                  </ItemCustom>
                </Grid>
                <Grid xs={12} sm={4} md={4}>
                  <ItemCustom>
                    <FormLabel sx={{ minWidth: "30%" }}>Quantidade</FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        label="Quantidade"
                        size="small"
                        required
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </FormControl>
                  </ItemCustom>
                </Grid>
                <Grid xs={12} sm={4} md={4} display="flex" justifyContent="end">
                  <ItemCustom>
                    <FormLabel sx={{ minWidth: "40%" }}>
                      Valor Unitário:{" "}
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="value_unit"
                        name="value_unit"
                        required
                        variant="outlined"
                        value={unitValue}
                        onChange={(e) => {
                          e = formatCurrency(e);
                          setUnitValue(e.target.value);
                        }}
                      />
                    </FormControl>
                  </ItemCustom>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ marginBottom: "5%" }}>
              <ButtonDefault
                label="Fechar"
                onClick={() => {
                  setOpenDialog(false);
                  handleSelect(null);
                  setShoppingProccessItem(null);
                  setProductsToSearch([]);
                }}
              />
              {requestItemSelect?.id ? (
                <ButtonSalveForm
                  label="Alterar"
                  colorIcon="success"
                  type="submit"
                />
              ) : (
                <ButtonSalveForm
                  label="Incluir"
                  colorIcon="success"
                  type="submit"
                />
              )}
            </DialogActions>
          </form>
        )}
      </DialogComponent>

      <DialogComponent open={dialogProductsSearch} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Itens</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogProductsSearch(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingProductCompany ? (
            <CircularLoading />
          ) : productsToSearch.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "Código",
                },
                {
                  label: "Descrição",
                },
              ]}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {productsToSearch.map((product) => (
                <TableRow
                  hover
                  key={product.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (product?.id) {
                      setCodeItem({
                        code: product?.code,
                        label: product.label,
                      });
                      setDescriptionItem({
                        code: product?.code,
                        label: product.label,
                      });
                      setShoppingProccessItem({
                        produtoEmpresaId: product.id,
                      });
                      setCode(product.code);
                      setDescription(product.label);
                      setUnit(product.unidade);
                      setDialogProductsSearch(false);
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    {product.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setDialogProductsSearch(false);
            }}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default ItemRequest;
