/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { iUnit, iUnitContextProps, iunitPage } from "src/interfaces/unit";
import unitConsumer from "src/services/unit";
import Unit from "src/models/Unit";
import { toast } from "react-toastify";

const UnitContext = createContext<iUnitContextProps>({} as iUnitContextProps);

export function UnitProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<Array<iUnit>>([]);
  const [unitsToSearch, setUnitsToSearch] = useState<Array<any>>([]);
  const [page, setPage] = useState<iunitPage>({
    page: 0,
    rowsPerPage: 50,
    total: 0,
    change: true,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getUnits = async () => {
    setLoading(true);
    try {
      const response = await unitConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        Unit.adapterToClass(item)
      );

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      setUnits(data);
      setUnitsToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item.id,
            label: item.descricao,
          };
        })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as unidades cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getUnits,
      loading,
      setLoading,
      units,
      handleChangePage,
      handleChangeRowsPerPage,
      unitsToSearch,
    };
  }, [page, loading, units, unitsToSearch]);

  return (
    <UnitContext.Provider value={contextValue}>{children}</UnitContext.Provider>
  );
}

export default UnitContext;
