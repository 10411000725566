import NetWork from "src/models/NetWork";
import httpClientRegister from "src/http/register";
import { iNetwork, iNetworkPage } from "src/interfaces/network";

class networkConsumer {
  static get(page: iNetworkPage) {
    return httpClientRegister.get(
      `/Rede?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static created(network: iNetwork) {
    return httpClientRegister.post(`/Rede`, NetWork.adapterToBody(network));
  }

  static updated(network: iNetwork) {
    return httpClientRegister.put(`/Rede`, NetWork.adapterToBody(network));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Rede?id=${id}`);
  }
}

export default networkConsumer;
