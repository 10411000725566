import httpClientRegister from "src/http/register";
import { iPage } from "src/interfaces/layout";

class companyGroupConsumer {
  static get(page: iPage, orderBy?: any) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return httpClientRegister.get(`/GrupoEmpresa?${search.toString()}`);
  }
}

export default companyGroupConsumer;
