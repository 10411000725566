/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useProductCategory from "src/hooks/useProductCategory";
import useProductCategoryGeneral from "src/hooks/useProductCategoryGeneral";
import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";
import { iCompany } from "src/interfaces/company";
import { iPage } from "src/interfaces/layout";
import { iProductCategory } from "src/interfaces/products";
import productCategoryConsumer from "src/services/productCategory";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const FromToCategoryContext = createContext<any>({} as any);

export function FromToCategoryProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { getCategories } = useProductCategoryGeneral();

  const {
    getCategories: getCategoriesCompanyProduct,
    page: pageProductCategory,
  } = useProductCategory();

  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [companySelect, setCompanySelect] = useState<iCompany | null>(null);
  const [categoryProductSelect, setCategoryProductSelect] =
    useState<iCategoryProductGeneral | null>(null);
  const [categoryCompanyProductSelect, setCategoryCompanyProductSelect] =
    useState<iProductCategory | null>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleLink = async () => {
    setLoading(true);

    try {
      const body = {
        categoriaProdutoDeParaEmpresas: [
          {
            id: categoryCompanyProductSelect?.id,
            CategoriasProdutoId: categoryProductSelect?.id,
          },
        ],
      };

      const response = await productCategoryConsumer.fromTo(body);

      if (response.status !== 200) throw response;

      getCategories();

      if (companySelect?.id) {
        getCategoriesCompanyProduct(
          companySelect?.id,
          pageProductCategory.page,
          pageProductCategory.rowsPerPage,
          true
        );
      }

      setCategoryProductSelect(null);
      setCategoryCompanyProductSelect(null);

      toast.success(translations[param]["success_general"]);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      handleLink,
      companySelect,
      setCompanySelect,
      categoryProductSelect,
      setCategoryProductSelect,
      categoryCompanyProductSelect,
      setCategoryCompanyProductSelect,
    };
  }, [
    page,
    loading,
    companySelect,
    categoryProductSelect,
    categoryCompanyProductSelect,
  ]);

  return (
    <FromToCategoryContext.Provider value={contextValue}>
      {children}
    </FromToCategoryContext.Provider>
  );
}

export default FromToCategoryContext;
