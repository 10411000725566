import { Navigate, Outlet } from "react-router-dom";
import { LANGUAGES } from "src/_i18n/languages";

export const MultiLanguageRoute = (props: any) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === "/";

  if (isBasePathWithoutLang) return <Navigate to={`/${defaultLanguage}`} />;
  if (!is404Page) return <Navigate to={`/${defaultLanguage}`} />;

  return <Outlet />;
};
