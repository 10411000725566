import { Card, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { DropdownMenuStyle } from "./style";
import useLayout from "src/hooks/useLayout";
import { useNavigate } from "react-router-dom";

const DropdownMenu = (props: any) => {
  const router = useNavigate();
  const { setOpenDialogAlert } = useLayout();

  return (
    <DropdownMenuStyle
      className="dropdown-menu"
      onMouseEnter={() => setOpenDialogAlert(true)}
      onMouseLeave={() => setOpenDialogAlert(false)}
    >
      <Card>
        {props.menu?.submenus?.map((itemMenu: any, _: any) => (
          <MenuItem
            key={itemMenu?.path}
            onClick={() => {
              router(itemMenu?.path ?? "");
              setOpenDialogAlert(false);
            }}
          >
            <ListItemIcon>{itemMenu?.icon}</ListItemIcon>
            <ListItemText primary={itemMenu?.name} />
          </MenuItem>
        ))}
      </Card>
    </DropdownMenuStyle>
  );
};

export default DropdownMenu;
