import {
  iShoppingProcessType,
  iShoppingProcessPageType,
} from "src/interfaces/shoppingProcessType";
import shoppingProcess from "src/http/shoppingProcess";
import ShoppingProcessType from "src/models/ShoppingProcessType";

class shoppingProcessConsumerType {
  static get(page: iShoppingProcessPageType) {
    return shoppingProcess.get(
      `/ProcessoCompraTipo?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static created(shoppingProcessType: iShoppingProcessType) {
    return shoppingProcess.post(
      `/ProcessoCompraTipo`,
      ShoppingProcessType.adapterToBody(shoppingProcessType)
    );
  }

  static updated(shoppingProcessType: iShoppingProcessType) {
    return shoppingProcess.put(
      `/ProcessoCompraTipo`,
      ShoppingProcessType.adapterToBody(shoppingProcessType)
    );
  }

  static deleted(id: string | null | undefined) {
    return shoppingProcess.delete(`/ProcessoCompraTipo?id=${id}`);
  }
}

export default shoppingProcessConsumerType;
