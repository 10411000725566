import httpClientShoppingProcess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";
import { iShoppingProccessRate } from "src/interfaces/shoppingProccessRate";
import ShoppingProccessRate from "src/models/ShoppingProccessRate";

class ShoppingProccessRateConsumer {
  static get(page: iPage, shoppingProccessId: string) {
    return httpClientShoppingProcess.get(
      `/TaxaPC?PageNumber=${page.page}&PageSize=${page.rowsPerPage}&ProcessoCompraId=${shoppingProccessId}`
    );
  }

  static created(shoppingProccessRate: iShoppingProccessRate) {
    return httpClientShoppingProcess.post(
      `/TaxaPC`,
      ShoppingProccessRate.adapterToBody(shoppingProccessRate)
    );
  }

  static updated(shoppingProccessRate: iShoppingProccessRate) {
    return httpClientShoppingProcess.put(
      `/TaxaPC`,
      ShoppingProccessRate.adapterToBody(shoppingProccessRate)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientShoppingProcess.delete(`/TaxaPC?id=${id}`);
  }
}

export default ShoppingProccessRateConsumer;
