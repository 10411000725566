import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

interface iEmpty {
  message?: string;
}

const Empty: React.FC = ({ message }: iEmpty) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid>
          <ContentPasteSearchOutlinedIcon
            color="primary"
            sx={{ fontSize: 120 }}
          />
        </Grid>
        <Grid sx={{ marginBottom: 3, textAlign: "center" }}>
          <h3>{message ?? "Não existem dados cadastrados!"}</h3>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Empty;
