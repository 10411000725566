import { useState } from "react";
import { iLogin } from "src/interfaces/login";
import useLayout from "./useLayout";
import { toast } from "react-toastify";
import LoginConsumer from "src/services/login";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { eMessage } from "src/enums/messages";

export default function usePasswordRecovery() {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { message, setMessage, emptyMessage } = useLayout();
  const [isSendMessage, setIsSendMessage] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSendMailToPasswordRecovery = (e: any) => {
    e.preventDefault();

    try {
      const data = {
        username: e.target.username.value,
      };

      localStorage.setItem("username", data.username);

      sendMail(data);
    } catch {
      toast.error(
        `${translations[param]["oops_we_had_a_problem_sending_the_email"]}`
      );
    }
  };

  const sendMail = async (data: iLogin) => {
    try {
      setLoading(true);

      const response = await LoginConsumer.getTokenToChangePassword(
        `${data?.username}`
      );

      setIsSendMessage(true);

      if (response.status !== 200) throw response;
      if (!response.data) throw response;

      toast.success(
        `${translations[param]["password_recovery_email_sent_successfully"]}`
      );

      setMessage({
        message: `${translations[param]["password_recovery_email_sent_successfully"]}`,
        type: eMessage.success,
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          `${translations[param]["oops_check_if_your_email_is_correct"]}`
      );

      setMessage({
        message: `${
          error?.response?.data?.message ??
          translations[param]["oops_check_if_your_email_is_correct"]
        }`,
        type: eMessage.error,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSendMailToPasswordRecovery,
    message,
    emptyMessage,
    isSendMessage,
    setIsSendMessage,
    sendMail,
  };
}
