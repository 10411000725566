import httpClientRegister from "src/http/register";
import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";
import { iProductCategoryPage } from "src/interfaces/products";
import CategoryProductGeneral from "src/models/CategoryProductGeneral";

class productCategoryGeneralConsumer {
  static get(page: iProductCategoryPage, description?: string, orderBy?: any) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (description) {
      search.append("Descricao", `${description}`);
    }

    return httpClientRegister.get(`/CategoriaProduto?${search.toString()}`);
  }

  static getForCategoryFather(
    page: iProductCategoryPage,
    description?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (description) {
      search.append("Descricao", `${description}`);
    }

    return httpClientRegister.get(
      `/CategoriaProduto/categoriaPai?${search.toString()}`
    );
  }

  static created(category: iCategoryProductGeneral) {
    return httpClientRegister.post(
      `/CategoriaProduto`,
      CategoryProductGeneral.adapterToBody(category)
    );
  }

  static updated(category: iCategoryProductGeneral) {
    return httpClientRegister.put(
      `/CategoriaProduto`,
      CategoryProductGeneral.adapterToBody(category)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/CategoriaProduto?id=${id}`);
  }
}

export default productCategoryGeneralConsumer;
