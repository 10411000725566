/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { iRatePCItem, iRatePCItemProps, iRatePage } from "src/interfaces/rate";
import RatePCItem from "src/models/RatePCItem";
import TaxaPCItem from "src/services/taxaPCItem";

const RatePCItemContext = createContext<iRatePCItemProps>(
  {} as iRatePCItemProps
);

export function RatePCItemProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [ratesPCItem, setRatesPCItem] = useState<Array<iRatePCItem>>([]);

  const [taxaPCId, setTaxaPCId] = useState<string>("select");
  const [processoCompraItensId, setProcessoCompraItensId] =
    useState<string>("select");
  const [dataInicio, setDataInicio] = useState<string>("");
  const [dataFim, setDataFim] = useState<string>("");
  const [valorTaxa, setValorTaxa] = useState<number>(0);

  const [page, setPage] = useState<iRatePage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: false,
  });

  const [ratePCItemSelect, setRatePCItemSelect] = useState<iRatePCItem | null>(
    null
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: 20,
      change: true,
    });
  };

  const getRatesPCItem = async (shoppingProccessItemId: string) => {
    setLoading(true);
    try {
      const response = await TaxaPCItem.get(page, shoppingProccessItemId);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        RatePCItem.adapterToClass(item)
      );

      if (response.data.items === 0 && page.total === 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setRatesPCItem(data);
      handleSelected(null);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as taxas cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (rate: iRatePCItem | null) => {
    setRatePCItemSelect(rate);
    setTaxaPCId(rate?.taxaPCId ?? "select");
    setDataInicio(rate?.dataInicio ?? "");
    setDataFim(rate?.dataFim ?? "");
    setValorTaxa(rate?.valorTaxa ?? 0);
  };

  const handleNewSalve = async (shoppingProccessItemId: string) => {
    try {
      setLoading(true);

      const body = new RatePCItem(
        null,
        taxaPCId,
        processoCompraItensId,
        dataInicio,
        dataFim,
        valorTaxa
      );

      const response = await TaxaPCItem.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Nova taxa cadastrada com sucesso!");

      getRatesPCItem(shoppingProccessItemId);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar a taxa!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (shoppingProccessItemId: string) => {
    try {
      setLoading(true);

      const body = new RatePCItem(
        ratePCItemSelect?.id ?? "",
        taxaPCId,
        processoCompraItensId,
        dataInicio,
        dataFim,
        valorTaxa
      );

      const response = await TaxaPCItem.update(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Taxa alterada com sucesso!");

      getRatesPCItem(shoppingProccessItemId);
    } catch {
      toast.error("Ops... tivemos um problema ao alterar a taxa!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (
    ratePCItem: iRatePCItem,
    shoppingProccessItemId: string
  ) => {
    try {
      setLoading(true);

      const response = await TaxaPCItem.deleted(`${ratePCItem?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Taxa deletada com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      getRatesPCItem(shoppingProccessItemId);
    } catch {
      toast.error("Erro ao deletar a taxa!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getRatesPCItem,
      loading,
      setLoading,
      ratesPCItem,
      handleChangePage,
      handleChangeRowsPerPage,
      ratePCItemSelect,
      setRatePCItemSelect,
      handleSelected,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      taxaPCId,
      setTaxaPCId,
      processoCompraItensId,
      setProcessoCompraItensId,
      dataInicio,
      setDataInicio,
      dataFim,
      setDataFim,
      valorTaxa,
      setValorTaxa,
    };
  }, [
    page,
    loading,
    ratesPCItem,
    ratePCItemSelect,
    taxaPCId,
    processoCompraItensId,
    dataInicio,
    dataFim,
    valorTaxa,
  ]);

  return (
    <RatePCItemContext.Provider value={contextValue}>
      {children}
    </RatePCItemContext.Provider>
  );
}

export default RatePCItemContext;
