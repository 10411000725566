/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  iRequestStatus,
  iRequestStatusContextProps,
  iRequestStatusPage,
} from "src/interfaces/requestStatus";
import RequestStatus from "src/models/RequestStatus";
import requestStatusPageConsumer from "src/services/requestStatus";

const RequestStatusContext = createContext<iRequestStatusContextProps>(
  {} as iRequestStatusContextProps
);

export function RequestStatusProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [requestStatus, setRequestStatuss] = useState<Array<iRequestStatus>>(
    []
  );
  const [page, setPage] = useState<iRequestStatusPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getRequestStatus = async () => {
    setLoading(true);
    try {
      const response = await requestStatusPageConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data?.map((item: any) =>
        RequestStatus.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response?.data?.totalItems ?? 0,
        });
      }

      setRequestStatuss(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os status cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getRequestStatus,
      loading,
      setLoading,
      requestStatus,
      handleChangePage,
      handleChangeRowsPerPage,
    };
  }, [page, loading, requestStatus]);

  return (
    <RequestStatusContext.Provider value={contextValue}>
      {children}
    </RequestStatusContext.Provider>
  );
}

export default RequestStatusContext;
