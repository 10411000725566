import {
  iPaymentCondition,
  iPaymentConditionPage,
} from "src/interfaces/paymentCondition";
import httpClientRegister from "src/http/register";
import PaymentCondition from "src/models/PaymentCondition";

class PaymentConditionConsumer {
  static get(idCompany: string, page: iPaymentConditionPage) {
    return httpClientRegister.get(
      `/CondicaoPagamento?empresaId=${idCompany}&PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static getPaymentConditionAdministrator(page: iPaymentConditionPage) {
    return httpClientRegister.get(
      `/CondicaoPagamento/Administradora?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static created(paymentCondition: iPaymentCondition) {
    return httpClientRegister.post(
      `/CondicaoPagamento`,
      PaymentCondition.adapterToBody(paymentCondition)
    );
  }

  static updated(paymentCondition: iPaymentCondition) {
    return httpClientRegister.put(
      `/CondicaoPagamento`,
      PaymentCondition.adapterToBody(paymentCondition)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/CondicaoPagamento?id=${id}`);
  }
}

export default PaymentConditionConsumer;
