import { iProductCategory } from "src/interfaces/products";

export default class ProductCategory implements iProductCategory {
  id;
  code;
  description;
  companyId;
  categoryProductId;
  categoryProduct;
  active;
  productCategoryGeneral;
  categoriaProdutoEmpresaCode?: string;
  categoriaProdutoPrincipal?: string;
  categoriaProdutoCodigo?: string;

  constructor(
    id: string | null,
    code: string,
    description: string,
    companyId: string,
    categoryProductId: string,
    categoryProduct: string,
    active: boolean,
    productCategoryGeneral: string,
    categoriaProdutoEmpresaCode?: string,
    categoriaProdutoPrincipal?: string,
    categoriaProdutoCodigo?: string
  ) {
    this.id = id;
    this.code = code;
    this.description = description;
    this.companyId = companyId;
    this.categoryProductId = categoryProductId;
    this.categoryProduct = categoryProduct;
    this.active = active;
    this.productCategoryGeneral = productCategoryGeneral;
    this.categoriaProdutoEmpresaCode = categoriaProdutoEmpresaCode;
    this.categoriaProdutoPrincipal = categoriaProdutoPrincipal;
    this.categoriaProdutoCodigo = categoriaProdutoCodigo;
  }

  public static adapterToClass(productCategory: any) {
    return new ProductCategory(
      productCategory.id,
      productCategory.codigo,
      productCategory.descricao,
      productCategory.empresaId,
      productCategory.categoriasProdutoEmpresaId ?? "",
      productCategory.categoriaProdutoEmpresaDescricao,
      productCategory.ativo ?? false,
      productCategory.categoriasProdutoId ?? "",
      productCategory.categoriaProdutoEmpresaCodigo ?? "",
      productCategory.categoriaProdutoDescricao ?? "",
      productCategory.categoriaProdutoCodigo ?? ""
    );
  }

  public static adapterToJson(productCategory: any) {
    return JSON.parse(productCategory);
  }

  public static adapterToBody(productCategory: iProductCategory) {
    const data: any = {
      codigo: productCategory.code,
      descricao: productCategory.description,
      empresaId: productCategory.companyId,
      ativo: productCategory.active,
    };

    if (productCategory?.id) data["id"] = productCategory.id;

    if (
      productCategory?.categoryProductId &&
      productCategory?.categoryProductId !== "select"
    )
      data["categoriasProdutoEmpresaId"] = productCategory.categoryProductId;

    if (
      productCategory?.productCategoryGeneral &&
      productCategory?.productCategoryGeneral !== "select"
    )
      data["categoriasProdutoId"] = productCategory.productCategoryGeneral;

    return data;
  }
}
