import { iRequests, iRequestsItems } from "src/interfaces/requests";

export default class Requests implements iRequests {
  id: string;
  number: string;
  value: number;
  emissionDate: Date;
  deliveryDate: Date;
  paymentPrevisionDate: Date;
  supplierId: string;
  buyerId: string;
  invoicingId: string;
  shoppingProccessId: string;
  invoceId: string;
  items?: iRequestsItems[];
  supplier?: any;
  buyer?: any;
  statusRequest?: any;
  invocesRequest?: any;
  shoppingProccess?: any;
  statusCurrent?: any;
  paymentConditionId?: string;
  paymentCondition?: any;
  pedidoItens?: any[];

  constructor(
    id: string,
    number: string,
    value: number,
    emissionDate: Date,
    deliveryDate: Date,
    paymentPrevisionDate: Date,
    supplierId: string,
    buyerId: string,
    invoicingId: string,
    shoppingProccessId: string,
    invoceId: string,
    items?: iRequestsItems[],
    supplier?: any,
    buyer?: any,
    statusRequest?: any,
    invocesRequest?: any,
    shoppingProccess?: any,
    statusCurrent?: any,
    paymentConditionId?: string,
    paymentCondition?: string,
    pedidoItens?: any[]
  ) {
    this.id = id;
    this.number = number;
    this.value = value;
    this.emissionDate = emissionDate;
    this.deliveryDate = deliveryDate;
    this.paymentPrevisionDate = paymentPrevisionDate;
    this.supplierId = supplierId;
    this.buyerId = buyerId;
    this.invoicingId = invoicingId;
    this.shoppingProccessId = shoppingProccessId;
    this.invoceId = invoceId;
    this.items = items;
    this.supplier = supplier;
    this.buyer = buyer;
    this.statusRequest = statusRequest;
    this.invocesRequest = invocesRequest;
    this.shoppingProccess = shoppingProccess;
    this.statusCurrent = statusCurrent;
    this.paymentConditionId = paymentConditionId;
    this.paymentCondition = paymentCondition;
    this.pedidoItens = pedidoItens;
  }

  public static adapterToClass(requests: any) {
    return new Requests(
      requests.id,
      requests.numero,
      requests.valor,
      requests.dataEmissao,
      requests.dataEntrega,
      requests.dataPrevisaoPagamento,
      requests.fornecedorId,
      requests.compradorId,
      requests.faturamentoId,
      requests.processoCompraId,
      requests.notaFiscalId,
      requests.itens ?? [],
      requests.fornecedor,
      requests.comprador,
      requests.andamentoStatusPedidos,
      requests.notaFiscalPedidos,
      requests.processoCompra,
      requests.ultimoAndamentoStatusPedido,
      requests.condicoesPagamentoId,
      requests.condicaoPagamento,
      requests.pedidoItens
    );
  }

  public static adapterToJson(requests: any) {
    return JSON.parse(requests);
  }

  public static adapterToBody(requests: iRequests) {
    const data: any = {
      numero: requests.number,
      valor: requests.value === null ? 0 : requests.value,
      dataEmissao: requests.emissionDate,
      fornecedorId: requests.supplierId,
      compradorId: requests.buyerId,
      processoCompraId: requests.shoppingProccessId,
    };

    if (requests.id) {
      data["id"] = requests.id;
    }

    if (requests.deliveryDate) {
      data["dataEntrega"] = requests.deliveryDate;
    }

    if (requests.paymentPrevisionDate) {
      data["dataPrevisaoPagamento"] = requests.paymentPrevisionDate;
    }

    if (
      requests?.paymentConditionId &&
      requests?.paymentConditionId !== "select"
    ) {
      data["condicoesPagamentoId"] = requests.paymentConditionId;
    }

    return data;
  }
}
