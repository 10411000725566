export function stringAvatar(name: string) {
  let nameAvatar: string = `${name.split(" ")[0][0]}${name.split(" ")[0][0]}`;

  if (name.indexOf(" ") >= 0)
    nameAvatar = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;

  return {
    sx: {
      bgcolor: "#FFF",
      color: "#3f51b5",
    },
    children: nameAvatar,
  };
}
