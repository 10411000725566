import httpClientRequest from "src/http/request";
import { iRequestStatusPage } from "src/interfaces/requestStatus";

class requestStatusPageConsumer {
  static get(page: iRequestStatusPage) {
    const search = new URLSearchParams();
    search.append("page", `${page.page}`);
    search.append("pageSize", `${page.rowsPerPage}`);

    return httpClientRequest.get(`/PedidoStatus?${search.toString()}`);
  }
}

export default requestStatusPageConsumer;
