import Documents from "src/models/Documents";

export default class DocumentFactory {
  static async send({
    code,
    cnpj,
    companyId,
    typeDocumentId,
  }: {
    code: number;
    cnpj: string;
    companyId: string;
    typeDocumentId: string;
  }) {
    const documents = new Documents(cnpj, companyId, typeDocumentId);

    switch (code) {
      case 6:
        documents.fgtsRun();
        break;

      case 5:
        documents.regularityFgtsRun();
        break;

      default:
        break;
    }
  }
}
