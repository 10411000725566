import { iShoppingProcessPhasesItem } from "src/interfaces/shoppingProcessPhasesItem";

export default class ShoppingProcessPhasesItem
  implements iShoppingProcessPhasesItem
{
  id?: string | null;
  quantity: number;
  baseValue: string;
  targetValue: string;
  unitValue: string;
  totalValue: string;
  buyerId: string;
  supplierId: string;
  unitId: string;
  proccessBuyerPhaseId: string;
  paymentConditionId: string;
  shoppingProccessItemId: string;
  buyerResponsibleId: string;
  supplierResponsibleId: string;
  buyer?: string;
  supplier?: string;
  paymentCondition?: string;
  unit?: string;
  codeProduct?: string;
  descriptionProduct?: string;
  buyerResponsible?: string;
  supplierResponsible?: string;

  constructor(
    id: string,
    quantity: number,
    baseValue: string,
    targetValue: string,
    unitValue: string,
    totalValue: string,
    buyerId: string,
    supplierId: string,
    unitId: string,
    proccessBuyerPhaseId: string,
    paymentConditionId: string,
    shoppingProccessItemId: string,
    buyerResponsibleId: string,
    supplierResponsibleId: string,
    buyer?: string,
    supplier?: string,
    paymentCondition?: string,
    unit?: string,
    codeProduct?: string,
    descriptionProduct?: string,
    buyerResponsible?: string,
    supplierResponsible?: string
  ) {
    this.id = id;
    this.quantity = quantity;
    this.baseValue = baseValue;
    this.targetValue = targetValue;
    this.unitValue = unitValue;
    this.totalValue = totalValue;
    this.buyerId = buyerId;
    this.supplierId = supplierId;
    this.unitId = unitId;
    this.proccessBuyerPhaseId = proccessBuyerPhaseId;
    this.paymentConditionId = paymentConditionId;
    this.shoppingProccessItemId = shoppingProccessItemId;
    this.buyerResponsibleId = buyerResponsibleId;
    this.supplierResponsibleId = supplierResponsibleId;
    this.buyer = buyer;
    this.supplier = supplier;
    this.paymentCondition = paymentCondition;
    this.unit = unit;
    this.codeProduct = codeProduct;
    this.descriptionProduct = descriptionProduct;
    this.buyerResponsible = buyerResponsible;
    this.supplierResponsible = supplierResponsible;
  }

  public static adapterToClass(shoppingProcessPhasesItem: any) {
    return new ShoppingProcessPhasesItem(
      shoppingProcessPhasesItem.id,
      shoppingProcessPhasesItem.quantidade,
      shoppingProcessPhasesItem.valorBaseline,
      shoppingProcessPhasesItem.valorAlvo,
      shoppingProcessPhasesItem.valorUnitario,
      shoppingProcessPhasesItem.valorTotal,
      shoppingProcessPhasesItem.compradorId,
      shoppingProcessPhasesItem.fornecedorId,
      shoppingProcessPhasesItem.unidadeId,
      shoppingProcessPhasesItem.processoCompraEtapaId,
      shoppingProcessPhasesItem.condicaoPagamentoId,
      shoppingProcessPhasesItem.processoCompraItemId,
      shoppingProcessPhasesItem.responsavelCompradorId,
      shoppingProcessPhasesItem.responsavelFornecedorId,
      shoppingProcessPhasesItem.comprador,
      shoppingProcessPhasesItem.fornecedor,
      shoppingProcessPhasesItem.condicaoPagamento,
      shoppingProcessPhasesItem.unidade,
      shoppingProcessPhasesItem.codigoProduto,
      shoppingProcessPhasesItem.descricaoProduto,
      shoppingProcessPhasesItem.responsavelComprador,
      shoppingProcessPhasesItem.responsavelFornecedor
    );
  }

  public static adapterToJson(shoppingProcessPhasesItem: any) {
    return JSON.parse(shoppingProcessPhasesItem);
  }

  public static adapterToBody(
    shoppingProcessPhasesItem: iShoppingProcessPhasesItem
  ) {
    const data: any = {
      quantidade: shoppingProcessPhasesItem.quantity,
      valorBaseline: shoppingProcessPhasesItem.baseValue,
      valorAlvo: shoppingProcessPhasesItem.targetValue,
      valorUnitario: shoppingProcessPhasesItem.unitValue,
      valorTotal: shoppingProcessPhasesItem.totalValue,
      compradorId: shoppingProcessPhasesItem.buyerId,
      fornecedorId: shoppingProcessPhasesItem.supplierId,
      processoCompraEtapaId: shoppingProcessPhasesItem.proccessBuyerPhaseId,
      condicaoPagamentoId: shoppingProcessPhasesItem.paymentConditionId,
      processoCompraItemId: shoppingProcessPhasesItem.shoppingProccessItemId,
      responsavelCompradorId: shoppingProcessPhasesItem.buyerResponsibleId,
      responsavelFornecedorId: shoppingProcessPhasesItem.supplierResponsibleId,
    };

    if (shoppingProcessPhasesItem.id) {
      data["id"] = shoppingProcessPhasesItem.id;
    }

    if (shoppingProcessPhasesItem.unitId) {
      data["unidadeId"] = shoppingProcessPhasesItem.unitId;
    }

    return data;
  }
}
