import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import Layout from "src/components/Layout";
import { iMenuRoutes } from "src/interfaces/menuRoutes";

const MenuChoosen = ({ subMenus }: { subMenus: Array<iMenuRoutes> }) => {
  const router = useNavigate();

  return (
    <Layout>
      <Grid container spacing={2}>
        {subMenus?.map((item, index) => (
          <Grid key={index} xs={12} sm={6} md={4}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item.icon}
                </Typography>
                <Typography variant="h5" component="div">
                  {item.name}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => router(item.path)}>
                  Ir para tela
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default MenuChoosen;
