/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { palette } from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import InputImport from "src/components/InputImport";
import useImport from "src/hooks/useImport";

import FILE_CATEGORY_SHOPPING_PROCCESS_ITEMS from "src/assets/files/Supply4Med - Importação Processo Compra Itens.xlsx";
import CircularLoading from "src/components/Loading";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";

const ImportItemDialog: React.FC = () => {
  const { setDialogImport, shoppingProcesSelect } = useShoppingProcess();
  const { getShoppingProcessItem } = useShoppingProcessItem();
  const { handleUpload, selectedFile, loading } = useImport();

  return (
    <>
      <DialogTitle id="alert-dialog-title">Importar Itens</DialogTitle>
      {!loading && (
        <IconButton
          aria-label="close"
          onClick={async () => {
            await getShoppingProcessItem();
            setDialogImport(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <DialogContent>
        {loading ? (
          <CircularLoading />
        ) : (
          <InputImport
            description="Processo Compra Itens"
            file={FILE_CATEGORY_SHOPPING_PROCCESS_ITEMS}
            name="Supply4Med - Modelo de Importação Processo Compra Itens.xlsx"
            type="itemProcessoCompra"
            isLog={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!loading && (
          <ButtonDefault
            label="Fechar"
            onClick={async () => {
              await getShoppingProcessItem();
              setDialogImport(false);
            }}
          />
        )}
        {!loading && (
          <ButtonSalveForm
            label="Importar"
            onClick={async () =>
              await handleUpload({
                shoppingProcesSelect: shoppingProcesSelect,
              })
            }
            disabled={selectedFile !== null ? false : true}
          />
        )}
      </DialogActions>
    </>
  );
};

export default ImportItemDialog;
