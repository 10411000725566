/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  TextField,
  Button,
  Divider,
} from "@mui/material";

import { marginTop } from "./style";

import LOGO from "src/assets/images/Logo.svg";
import MessageAlert from "src/components/MessageAlert";
import { useWindowSize } from "@react-hook/window-size";
import { palette } from "src/theme";
import usePasswordRecovery from "src/hooks/usePasswordRecovery";
import CircularLoading from "src/components/Loading";
import { ToastContainer } from "react-toastify";
import translations from "src/_i18n/translations.json";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const PasswordRecovery: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    loading,
    handleSendMailToPasswordRecovery,
    message,
    emptyMessage,
    isSendMessage,
    setIsSendMessage,
  } = usePasswordRecovery();
  const [width] = useWindowSize();
  const [sizeWidthCard, setSizeWidthCard] = useState<number>(345);

  useEffect(() => {
    setIsSendMessage(false);
  }, []);

  useEffect(() => {
    calculateSize();
  }, [width]);

  const calculateSize = () => {
    if (width >= 1024) {
      setSizeWidthCard(360);
    } else if (width >= 600) {
      setSizeWidthCard(600);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 2, background: palette.primary.main }}
    >
      <ToastContainer />
      <Card sx={{ padding: 2, maxWidth: sizeWidthCard }}>
        <CardContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {translations[param]["forgotpassword"]}
            </Typography>
          </Grid>
          <Divider style={{ backgroundColor: palette.primary.main }} />
          {!isSendMessage ? (
            <form onSubmit={(e) => handleSendMailToPasswordRecovery(e)}>
              <FormControl fullWidth sx={marginTop}>
                <TextField
                  id="username"
                  name="username"
                  label={
                    translations[param][
                      "please_enter_your_username_or_system_email"
                    ]
                  }
                  variant="outlined"
                  disabled={loading}
                />
              </FormControl>

              <FormControl fullWidth sx={marginTop}>
                <Button variant="contained" type="submit" disabled={loading}>
                  {translations[param]["redefine_password"]}
                </Button>
              </FormControl>
            </form>
          ) : (
            <>
              <MessageAlert
                currentMessage={message}
                handleOnClose={() => {
                  setIsSendMessage(false);
                  emptyMessage();
                }}
              />
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => router(`/${lang}`)}
                sx={{ width: "100%", marginTop: 2 }}
              >
                {translations[param]["back_to_login"]}
              </Button>
            </>
          )}

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <img alt="logo" src={LOGO} />
          </Grid>

          <br />
          {loading && <CircularLoading />}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PasswordRecovery;
