import {
  iShoppingProcessPhases,
  iShoppingProcessPhasesPage,
} from "src/interfaces/shoppingProcessPhases";
import shoppingProcess from "src/http/shoppingProcess";
import ShoppingProcessPhases from "src/models/ShoppingProcessPhases";

class ShoppingProcessPhasesConsumer {
  static get(
    page: iShoppingProcessPhasesPage,
    shoppingProcessId: string,
    number?: string,
    companyId?: string,
    codeItem?: string,
    descriptionItem?: string,
    statusId?: string,
    typeId?: string,
    firstDate?: string,
    endDate?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);
    search.append("ProcessoCompraId", shoppingProcessId);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (number) search.append("Numero", number);
    if (companyId) search.append("EmpresaId", companyId);
    if (codeItem) search.append("CodigoItem", codeItem);
    if (descriptionItem) search.append("DescricaoItem", descriptionItem);
    if (statusId && statusId !== "select") search.append("Status", statusId);
    if (typeId && typeId !== "select") search.append("Tipo", typeId);
    if (firstDate) search.append("dataCadastro", firstDate);
    if (endDate) search.append("dataEncerramento", endDate);

    return shoppingProcess.get(`/ProcessoCompraEtapa?${search.toString()}`);
  }

  static created(shoppingProcessPhases: iShoppingProcessPhases) {
    return shoppingProcess.post(
      `/ProcessoCompraEtapa`,
      ShoppingProcessPhases.adapterToBody(shoppingProcessPhases)
    );
  }

  static updated(shoppingProcessPhases: iShoppingProcessPhases) {
    return shoppingProcess.put(
      `/ProcessoCompraEtapa`,
      ShoppingProcessPhases.adapterToBody(shoppingProcessPhases)
    );
  }

  static updatedWithBody(shoppingProcessPhases: iShoppingProcessPhases) {
    return shoppingProcess.put(`/ProcessoCompraEtapa`, shoppingProcessPhases);
  }

  static deleted(id: string | null | undefined) {
    return shoppingProcess.delete(`/ProcessoCompraEtapa?id=${id}`);
  }
}

export default ShoppingProcessPhasesConsumer;
