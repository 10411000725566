/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import useCompany from "src/hooks/useCompany";
import {
  iPaymentCondition,
  iPaymentConditionContextProps,
  iPaymentConditionPage,
} from "src/interfaces/paymentCondition";
import PaymentConditionConsumer from "src/services/paymentConditions";
import PaymentCondition from "src/models/PaymentCondition";
import { toast } from "react-toastify";

const PaymentConditionContext = createContext<iPaymentConditionContextProps>(
  {} as iPaymentConditionContextProps
);

export function PaymentConditionProvider({ children }: { children: any }) {
  const { companySelected } = useCompany();

  const [loading, setLoading] = useState<boolean>(false);
  const [paymentConditions, setPaymentConditions] = useState<
    Array<iPaymentCondition>
  >([]);
  const [paymentConditionsToSearch, setPaymentConditionsToSearch] = useState<
    Array<any>
  >([]);
  const [page, setPage] = useState<iPaymentConditionPage>({
    page: 0,
    rowsPerPage: 30,
    total: 0,
    change: true,
  });

  const [paymentConditionSelect, setPaymentConditionSelect] =
    useState<iPaymentCondition | null>(null);
  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [daysNumber, setDaysNumber] = useState<number>(0);
  const [installments, setInstallments] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getPaymentConditions = async (company: any) => {
    setLoading(true);
    try {
      const response = await PaymentConditionConsumer.get(
        `${company?.id}`,
        page
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        PaymentCondition.adapterToClass(item)
      );

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      setPaymentConditions(data);
      setPaymentConditionsToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item.id,
            label: item.descricao,
          };
        })
      );
    } catch {
      toast.error(
        "Ops... identificamos um erro ao buscar as condições de pagamento!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (paymentCondition: iPaymentCondition | null) => {
    setPaymentConditionSelect(paymentCondition);
    setCode(paymentCondition?.code ?? "");
    setDescription(paymentCondition?.description ?? "");
    setActive(paymentCondition?.active ?? false);
    setDaysNumber(paymentCondition?.daysNumber ?? 0);
    setInstallments(paymentCondition?.installments ?? 0);
  };

  const handleNewSalve = async () => {
    try {
      setLoading(true);

      const paymentCondition = new PaymentCondition(
        null,
        code,
        description,
        daysNumber,
        installments,
        active,
        companySelected?.id
      );

      const response = await PaymentConditionConsumer.created(paymentCondition);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Nova condição de pagamento cadastrada com sucesso!");

      getPaymentConditions(companySelected);
      handleSelected(null);
    } catch (e) {
      toast.error(
        "Ops... tivemos um problema ao cadastrar a condição de pagamento!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const paymentCondition = new PaymentCondition(
        paymentConditionSelect?.id ?? null,
        code,
        description,
        daysNumber,
        installments,
        active,
        companySelected?.id
      );

      const response = await PaymentConditionConsumer.updated(paymentCondition);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Condição de pagamento alterada com sucesso!");

      getPaymentConditions(companySelected);
      handleSelected(null);
    } catch {
      toast.error(
        "Ops... tivemos um problema ao alterar a condição de pagamento!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (paymentCondition: iPaymentCondition) => {
    try {
      setLoading(true);

      const response = await PaymentConditionConsumer.deleted(
        `${paymentCondition?.id}`
      );

      if (response.status !== 200) throw response;

      toast.success("Condição de pagamento deletada com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelected(null);
      getPaymentConditions(companySelected);
    } catch {
      toast.error("Erro ao deletar a condição de pagamento!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getPaymentConditions,
      loading,
      setLoading,
      paymentConditions,
      setPaymentConditions,
      handleChangePage,
      handleChangeRowsPerPage,
      code,
      setCode,
      description,
      setDescription,
      daysNumber,
      setDaysNumber,
      installments,
      setInstallments,
      active,
      setActive,
      paymentConditionSelect,
      setPaymentConditionSelect,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      handleSelected,
      paymentConditionsToSearch,
      setPaymentConditionsToSearch,
    };
  }, [
    page,
    loading,
    paymentConditions,
    code,
    description,
    daysNumber,
    installments,
    active,
    paymentConditionSelect,
    paymentConditionsToSearch,
  ]);

  return (
    <PaymentConditionContext.Provider value={contextValue}>
      {children}
    </PaymentConditionContext.Provider>
  );
}

export default PaymentConditionContext;
