/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useProduct from "src/hooks/useProduct";
import useProductCompany from "src/hooks/useProductCompany";
import { iCompany } from "src/interfaces/company";
import { iPage } from "src/interfaces/layout";
import { iProduct } from "src/interfaces/products";
import productCompanyConsumer from "src/services/productCompany";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const FromToProductsContext = createContext<any>({} as any);

export function FromToProductsProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { getProducts, description } = useProduct();
  const { getProducts: getProductsCompany, page: pageProductsCompany } =
    useProductCompany();

  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [companySelect, setCompanySelect] = useState<iCompany | null>(null);
  const [productSelect, setProductSelect] = useState<iProduct | null>(null);
  const [productCompanySelect, setProductCompanySelect] = useState<any>(null);
  const [productCompanySearch, setProductCompanySearch] = useState<string>("");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleLink = async () => {
    setLoading(true);

    try {
      const body = {
        listaProdutoEmpresaDePara: [
          {
            id: productCompanySelect?.id,
            ProdutoId: productSelect?.id,
          },
        ],
      };

      const response = await productCompanyConsumer.fromTo(body);

      if (response.status !== 200) throw response;

      await getProducts(false, description);

      if (companySelect?.id) {
        await getProductsCompany(
          companySelect?.id,
          pageProductsCompany.page,
          pageProductsCompany.rowsPerPage,
          true
        );
      }

      toast.success(translations[param]["success_general"]);

      setProductSelect(null);
      setProductCompanySelect(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      handleLink,
      companySelect,
      setCompanySelect,
      productCompanySearch,
      setProductCompanySearch,
      productSelect,
      setProductSelect,
      productCompanySelect,
      setProductCompanySelect,
    };
  }, [
    page,
    loading,
    companySelect,
    productCompanySearch,
    productSelect,
    productCompanySelect,
  ]);

  return (
    <FromToProductsContext.Provider value={contextValue}>
      {children}
    </FromToProductsContext.Provider>
  );
}

export default FromToProductsContext;
