/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";
import AttributesProduct from "src/models/AttributesProduct";
import AttributesDataType from "src/models/AttributesDataType";
import AttributesProductConsumer from "src/services/attributesProduct";

const AttributesProductContext = createContext<any>({} as any);

export function AttributesProductProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [pageDataType, setPageDataType] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [attributes, setAttributes] = useState<AttributesProduct[]>([]);
  const [attributesDataType, setAttributesDataType] = useState<
    AttributesDataType[]
  >([]);
  const [attributeSelect, setAttributeSelect] =
    useState<AttributesProduct | null>(null);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangePageDataType = (_: unknown, newPage: number) => {
    setPageDataType({ ...pageDataType, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleChangeRowsPerPageDataType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageDataType({
      ...pageDataType,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const get = async () => {
    setLoading(true);
    try {
      const response = await AttributesProductConsumer.get(page);

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setAttributes(
        response.data.items.map(
          (item: any) =>
            new AttributesProduct(
              item.descricao,
              item.descricaoDetalhada,
              item.atributosDadosTipoId,
              item.id,
              item.atributosDadosTipo || ""
            )
        )
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao buscar os atríbutos!"
      );
    } finally {
      setLoading(false);
    }
  };

  const post = async () => {
    setLoading(true);
    try {
      const body: any = {
        descricao: attributeSelect?.descricao,
        descricaoDetalhada: attributeSelect?.descricaoDetalhada,
        atributosDadosTipoId: attributeSelect?.atributosDadosTipoId,
      };

      const response = await AttributesProductConsumer.created(body);

      if (response.status !== 200) throw response;

      body["id"] = response.data.id;

      handleSelected(null);
      setAttributes([body, ...attributes]);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao salvar o novo atríbuto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const put = async () => {
    setLoading(true);
    try {
      const body: any = {
        id: attributeSelect?.id,
        descricao: attributeSelect?.descricao,
        descricaoDetalhada: attributeSelect?.descricaoDetalhada,
        atributosDadosTipoId: attributeSelect?.atributosDadosTipoId,
      };

      const response = await AttributesProductConsumer.updated(body);

      if (response.status !== 200) throw response;

      const newAttributes = attributes.filter((e: any) => e.id !== body.id);

      handleSelected(null);
      setAttributes([body, ...newAttributes]);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao alterar o atríbuto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const deleted = async (id: string) => {
    setLoading(true);
    try {
      const response = await AttributesProductConsumer.deleted(id);

      if (response.status !== 200) throw response;

      setAttributes(attributes.filter((e: any) => e.id !== id));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao remover o atríbuto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getDataType = async () => {
    setLoading(true);
    try {
      const response = await AttributesProductConsumer.getDataType(
        pageDataType
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPageDataType({
          ...pageDataType,
          total: response.data.totalItems,
        });
      }

      setAttributesDataType(
        response.data.items.map(
          (item: any) => new AttributesDataType(item.id, item.descricao)
        )
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Erro ao buscar os dados dos atríbutos!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (value: any) => {
    setAttributeSelect(value);
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      attributes,
      setAttributes,
      get,
      post,
      put,
      deleted,
      handleChangePage,
      handleChangeRowsPerPage,
      handleChangePageDataType,
      handleChangeRowsPerPageDataType,
      attributeSelect,
      setAttributeSelect,
      attributesDataType,
      setAttributesDataType,
      page,
      setPage,
      pageDataType,
      setPageDataType,
      getDataType,
      handleSelected,
    };
  }, [
    loading,
    attributes,
    attributeSelect,
    attributesDataType,
    page,
    pageDataType,
  ]);

  return (
    <AttributesProductContext.Provider value={contextValue}>
      {children}
    </AttributesProductContext.Provider>
  );
}

export default AttributesProductContext;
