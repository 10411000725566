/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iNetwork,
  iNetworkContextProps,
  iNetworkPage,
} from "src/interfaces/network";
import NetWork from "src/models/NetWork";
import networkConsumer from "src/services/network";
import { toast } from "react-toastify";

const NetworkContext = createContext<iNetworkContextProps>(
  {} as iNetworkContextProps
);

export function NetworkProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [networks, setNetworks] = useState<Array<iNetwork>>([]);
  const [page, setPage] = useState<iNetworkPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getNetworks = async () => {
    setLoading(true);
    try {
      const response = await networkConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        NetWork.adapterToClass(item)
      );

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setNetworks(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as redes cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getNetworks,
      loading,
      setLoading,
      networks,
      handleChangePage,
      handleChangeRowsPerPage,
    };
  }, [page, loading, networks]);

  return (
    <NetworkContext.Provider value={contextValue}>
      {children}
    </NetworkContext.Provider>
  );
}

export default NetworkContext;
