import { iShoppingProcessType } from "src/interfaces/shoppingProcessType";

export default class ShoppingProcessType implements iShoppingProcessType {
  id?: string | null;
  description: string;

  constructor(id: string | null, description: string) {
    this.id = id;
    this.description = description;
  }

  public static adapterToClass(shoppingProcessType: any) {
    return new ShoppingProcessType(
      shoppingProcessType.id,
      shoppingProcessType.descricao
    );
  }

  public static adapterToJson(shoppingProcess: any) {
    return JSON.parse(shoppingProcess);
  }

  public static adapterToBody(shoppingProcessType: iShoppingProcessType) {
    if (shoppingProcessType.id) {
      return {
        id: shoppingProcessType.id,
        descricao: shoppingProcessType.description,
      };
    }

    return {
      descricao: shoppingProcessType.description,
    };
  }
}
