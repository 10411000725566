export function maskCnpj(cnpj: string) {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

export function maskForManyTypesString(value: string, pattern: string) {
  let i = 0;
  const v = value?.toString();
  return pattern?.replace(/#/g, () => v[i++] || "");
}

export function formatCurrency(e: any) {
  const prefix = "R$";

  let value = e.target.value;

  value = value + "";
  value = parseInt(value.replace(/[\D]+/g, ""));

  if (isNaN(value)) {
    e.target.value = `${prefix} 0,00`;
    return e;
  }

  value = value + "";
  value = value.replace(/([0-9]{2})$/g, ",$1");

  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  if (value.includes(",")) {
    value = value.length <= 3 ? `0${value}` : value;
  }

  if (value.length === 1) {
    value = `0,0${value}`;
  }

  e.target.value = `${prefix} ${value}`;
  return e;
}

export function breakLine(str: string, size: number) {
  if (!str || str.length <= 100) return str;

  const resultado = [];
  for (let i = 0; i < str.length; i += size) {
    resultado.push(str.substring(i, size));
  }

  return resultado.join("\n");
}
