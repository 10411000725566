export default class AttributesProduct {
  public id?: string;
  public descricao: string;
  public descricaoDetalhada: string;
  public atributosDadosTipoId: string;
  public atributosDadosTipo?: string;

  constructor(
    descricao: string,
    descricaoDetalhada: string,
    atributosDadosTipoId: string,
    id?: string,
    atributosDadosTipo?: string
  ) {
    this.descricao = descricao;
    this.descricaoDetalhada = descricaoDetalhada;
    this.atributosDadosTipoId = atributosDadosTipoId;
    this.id = id;
    this.atributosDadosTipo = atributosDadosTipo;
  }
}
