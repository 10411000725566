import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { Checkbox } from "@mui/material";
import useProduct from "src/hooks/useProduct";
import Product from "src/models/Product";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

function RowGroupCategory(props: { row: any }) {
  const router = useNavigate();
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { productSelect, handleSelectedCheckBox, handleSelected } =
    useProduct();

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.categoriaCodigo}</TableCell>
        <TableCell>{row.categoriaDescricao}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {translations[param]["products"]}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{translations[param]["code"]}</TableCell>
                    <TableCell>{translations[param]["description"]}</TableCell>
                    <TableCell>{translations[param]["active"]}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.produtos.map((product: Product) => (
                    <TableRow key={product.id} sx={{ cursor: "pointer" }}>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          handleSelected(product);
                          router(`/${lang}/settings/products/create`);
                        }}
                      >
                        {product.code}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleSelected(product);
                          router(`/${lang}/settings/products/create`);
                        }}
                      >
                        {product.description}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleSelected(product);
                          router(`/${lang}/settings/products/create`);
                        }}
                      >
                        {product.active ? (
                          <CheckCircleIcon color="success" fontSize="small" />
                        ) : (
                          <DoNotDisturbAltIcon color="error" fontSize="small" />
                        )}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={product.id === productSelect?.id}
                          size="small"
                          onClick={() => handleSelectedCheckBox(product)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default RowGroupCategory;
