import { iProductCompany, iProductCategoryPage } from "src/interfaces/products";
import ProductCompany from "src/models/ProductCompany";
import httpClientRegister from "src/http/register";

class productCompanyConsumer {
  static get(
    idCompany: string,
    page: iProductCategoryPage,
    code?: string,
    description?: string,
    productVinculateInCompany?: boolean
  ) {
    const search = new URLSearchParams();

    search.append("empresaId", idCompany);
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);

    if (code) {
      search.append("CodigoProduto", code);
    }

    if (description) {
      search.append("DescricaoProduto", description);
    }

    if (productVinculateInCompany) {
      search.append("listaProdutoNaoViculado", "true");
    }

    return httpClientRegister.get(`/ProdutoEmpresa?${search.toString()}`);
  }

  static created(product: iProductCompany) {
    return httpClientRegister.post(
      `/ProdutoEmpresa`,
      ProductCompany.adapterToBody(product)
    );
  }

  static updated(product: iProductCompany) {
    return httpClientRegister.put(
      `/ProdutoEmpresa`,
      ProductCompany.adapterToBody(product)
    );
  }

  static fromTo(body: any) {
    return httpClientRegister.post(`/ProdutoEmpresa/depara`, body);
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/ProdutoEmpresa?id=${id}`);
  }
}

export default productCompanyConsumer;
