/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormControl,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import Layout from "src/components/Layout";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useCompany from "src/hooks/useCompany";
import useFromToCategory from "src/hooks/useFromToCategory";
import useProductCategory from "src/hooks/useProductCategory";
import useProductCategoryGeneral from "src/hooks/useProductCategoryGeneral";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";
import { iProductCategory } from "src/interfaces/products";
import { palette } from "src/theme";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const FromToCategories = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { companysToSearch, nameSearch, setNameSearch, getCompanys } =
    useCompany();

  const {
    description,
    setDescription,
    getCategories,
    productGeneralCategorys,
    loading: loadingCategoryGeneral,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useProductCategoryGeneral();

  const {
    description: descriptionCompanyProductCategroy,
    setDescription: setDescriptionCompanyProductCategroy,
    loading: loadingProductCategory,
    productCategorys,
    setProductCategorys,
    getCategories: getCategoriesCompanyProduct,
    page: pageProductCategory,
    handleChangePage: handleChangePageProductCategory,
    handleChangeRowsPerPage: handleChangeRowsPerPageProductCategory,
  } = useProductCategory();

  const {
    companySelect,
    setCompanySelect,
    categoryProductSelect,
    setCategoryProductSelect,
    categoryCompanyProductSelect,
    setCategoryCompanyProductSelect,
    handleLink,
    loading,
  } = useFromToCategory();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["routines"],
    },
    {
      name: translations[param]["from_to_category"],
    },
  ];

  const headerCategory = [
    {
      label: "",
    },
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["parent_category"],
    },
  ];

  useEffect(() => {
    return () => {
      setNameSearch("");
      setDescription("");
      setDescriptionCompanyProductCategroy("");
      setProductCategorys([]);
      setCategoryCompanyProductSelect(null);
      setCategoryProductSelect(null);
      setCompanySelect(null);
    };
  }, []);

  useEffect(() => {
    getCategories();
  }, [page.page]);

  useEffect(() => {
    if (companySelect?.id && pageProductCategory.page !== 0) {
      getCategoriesCompanyProduct(
        companySelect?.id,
        pageProductCategory.page,
        pageProductCategory.rowsPerPage,
        true
      );
    }
  }, [pageProductCategory]);

  useEffect(() => {
    if (nameSearch?.length >= 5) {
      getCompanys({ isFilter: true });
    } else if (nameSearch?.length === 0) {
      getCompanys({ isFilter: true });
    }
  }, [nameSearch]);

  if (loading || loadingCategoryGeneral) {
    return (
      <Layout buttons={[]} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Card sx={{ width: "100%", padding: "2%" }}>
          <Grid xs={12} display="flex" alignItems="center">
            <Grid xs={1}>
              <Typography variant="subtitle1" gutterBottom>
                {translations[param]["company"]}:
              </Typography>
            </Grid>

            <Grid xs={11}>
              <Autocomplete
                disablePortal
                id="show_select_administrator_company"
                options={companysToSearch}
                value={companySelect}
                getOptionLabel={(option) => option.label}
                sx={{ minWidth: "85%" }}
                onChange={(_: any, value: any) => {
                  if (value?.id) {
                    setCompanySelect(value);
                    getCategoriesCompanyProduct(
                      value?.id,
                      pageProductCategory.page,
                      pageProductCategory.rowsPerPage,
                      true
                    );
                  } else {
                    setCompanySelect(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione:"
                    size="small"
                    required
                    onChange={(e) => setNameSearch(e.target.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Card sx={{ width: "100%", padding: "2%", marginTop: "1%" }}>
          <Grid
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              xs={12}
              sm={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ minWidth: "15%" }}
              >
                <strong>{translations[param]["product_category"]}</strong>
              </Typography>
              <FormControl>
                <TextField
                  size="small"
                  id="products"
                  name="products"
                  label={`${translations[param]["search"]} ${translations[param]["description"]}`}
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <ButtonDefault label="Pesquisar" onClick={getCategories} />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{ minWidth: "30%" }}
                variant="subtitle1"
                gutterBottom
              >
                <strong>{`${translations[param]["product_category"]} ${translations[param]["company"]}`}</strong>
                <br />
                {companySelect && (
                  <span style={{ fontSize: "12px" }}>
                    {`(${companySelect?.label})`}
                  </span>
                )}
              </Typography>
              <FormControl>
                <TextField
                  size="small"
                  id="productCompany"
                  name="productCompany"
                  label={`${translations[param]["search"]} ${translations[param]["description"]}`}
                  variant="outlined"
                  value={descriptionCompanyProductCategroy}
                  onChange={(e) =>
                    setDescriptionCompanyProductCategroy(e.target.value)
                  }
                />
              </FormControl>
              <ButtonDefault
                label={translations[param]["search"]}
                onClick={() => {
                  if (companySelect?.id) {
                    getCategoriesCompanyProduct(
                      companySelect?.id,
                      pageProductCategory.page,
                      pageProductCategory.rowsPerPage,
                      true
                    );
                  }
                }}
              />
            </Grid>
          </Grid>

          <Divider
            style={{ backgroundColor: palette.primary.main, marginTop: "2%" }}
          />

          <Grid
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid xs={12} sm={5}>
              {productGeneralCategorys.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={headerCategory}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {productGeneralCategorys
                    ?.sort(
                      (
                        a: iCategoryProductGeneral,
                        b: iCategoryProductGeneral
                      ) =>
                        a.code.localeCompare(b.code, undefined, {
                          numeric: true,
                        })
                    )
                    ?.map((item: iCategoryProductGeneral) => (
                      <TableRow
                        hover
                        key={item.id}
                        selected={item?.id === categoryProductSelect?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={item?.id === categoryProductSelect?.id}
                            onClick={() => {
                              if (item?.id === categoryProductSelect?.id) {
                                return setCategoryProductSelect(null);
                              }

                              setCategoryProductSelect(item);
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.code}
                        </TableCell>
                        <TableCell>{item?.description}</TableCell>
                        <TableCell>{item?.productCategory}</TableCell>
                      </TableRow>
                    ))}
                </TableComponent>
              )}
            </Grid>
            <Grid
              xs={12}
              sm={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ marginY: 2 }}
            >
              <ButtonSalveForm
                label={translations[param]["link"]}
                disabled={
                  categoryProductSelect === null ||
                  categoryCompanyProductSelect == null
                }
                onClick={handleLink}
              />
            </Grid>
            <Grid xs={12} sm={5}>
              {loadingProductCategory ? (
                <CircularLoading />
              ) : productCategorys.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={headerCategory}
                  page={pageProductCategory}
                  handleChangePage={handleChangePageProductCategory}
                  handleChangeRowsPerPage={
                    handleChangeRowsPerPageProductCategory
                  }
                >
                  {productCategorys
                    ?.sort((a: iProductCategory, b: iProductCategory) =>
                      a.code.localeCompare(b.code, undefined, {
                        numeric: true,
                      })
                    )
                    ?.map((item: iProductCategory) => (
                      <TableRow
                        hover
                        key={item.id}
                        selected={item?.id === categoryCompanyProductSelect?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={
                              item?.id === categoryCompanyProductSelect?.id
                            }
                            onClick={() => {
                              if (
                                item?.id === categoryCompanyProductSelect?.id
                              ) {
                                return setCategoryCompanyProductSelect(null);
                              }
                              setCategoryCompanyProductSelect(item);
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.code}
                        </TableCell>
                        <TableCell>{item?.description}</TableCell>
                        <TableCell>{item?.categoryProduct}</TableCell>
                      </TableRow>
                    ))}
                </TableComponent>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Layout>
  );
};

export default FromToCategories;
