import { iRatePCItem } from "src/interfaces/rate";

export default class RatePCItem implements iRatePCItem {
  id: string | null;
  taxaPCId: string;
  processoCompraItensId: string;
  dataInicio: string;
  dataFim: string;
  valorTaxa: number;
  taxa?: string;

  constructor(
    id: string | null,
    taxaPCId: string,
    processoCompraItensId: string,
    dataInicio: string,
    dataFim: string,
    valorTaxa: number,
    taxa?: string
  ) {
    this.id = id;
    this.taxaPCId = taxaPCId;
    this.processoCompraItensId = processoCompraItensId;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.valorTaxa = valorTaxa;
    this.taxa = taxa;
  }

  public static adapterToClass(ratePCItem: any) {
    return new RatePCItem(
      ratePCItem.id,
      ratePCItem.taxaPCId,
      ratePCItem.processoCompraItensId,
      ratePCItem.dataInicio,
      ratePCItem.dataFim,
      ratePCItem.valorTaxa,
      ratePCItem.taxa
    );
  }

  public static adapterToJson(ratePCItem: any) {
    return JSON.parse(ratePCItem);
  }

  public static adapterToBody(ratePCItem: iRatePCItem) {
    const data: any = {
      dataInicio: ratePCItem.dataInicio,
      dataFim: ratePCItem.dataFim,
      valorTaxa: ratePCItem.valorTaxa,
    };

    if (ratePCItem.id) {
      data["id"] = ratePCItem.id;
    }

    if (ratePCItem.taxaPCId && ratePCItem.taxaPCId !== "select") {
      data["taxaPCId"] = ratePCItem.taxaPCId;
    }

    if (
      ratePCItem.processoCompraItensId &&
      ratePCItem.processoCompraItensId !== "select"
    ) {
      data["processoCompraItensId"] = ratePCItem.processoCompraItensId;
    }

    return data;
  }
}
