import { iCompany, iCompanyPage } from "src/interfaces/company";
import httpClientRegister from "src/http/register";
import { httpClientGovBr } from "src/http/govbr";
import Company from "src/models/Company";

class companyConsumer {
  static get(
    page: iCompanyPage,
    cnpj?: string,
    name?: string,
    socialName?: string,
    buyer?: boolean,
    supplier?: boolean,
    active?: string,
    orderBy?: any,
    city?: string,
    state?: string,
    group?: string
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (cnpj) search.append("CNPJ", cnpj.replace(/[^0-9]/g, ""));
    if (name) search.append("Nome", name);
    if (city) search.append("Cidade", city);
    if (state && state !== "select") search.append("Estado", state);
    if (group && group !== "select") search.append("GrupoEmpresaId", group);
    if (socialName) search.append("RazaoSocial", socialName);
    if (buyer) search.append("Comprador", buyer.toString());
    if (supplier) search.append("Fornecedor", supplier.toString());
    if (active && active !== "select" && active !== "all") {
      search.append("Ativo", active);
    }

    return httpClientRegister.get(`/Empresa/filtros?${search.toString()}`);
  }

  static getById(id: number) {
    return httpClientRegister.get(`/Empresa/${id}`);
  }

  static created(company: iCompany) {
    return httpClientRegister.post(`/Empresa`, Company.adapterToBody(company));
  }

  static updated(company: iCompany) {
    return httpClientRegister.put(`/Empresa`, Company.adapterToBody(company));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Empresa?id=${id}`);
  }

  static getByCnpj(cnpj: string) {
    return httpClientRegister.get(`/Empresa/getbycnpjs?cnpjs=${cnpj}`);
  }

  static AuthTokenGovBR() {
    return httpClientGovBr.post(
      `/token`,
      {
        grant_type: "client_credentials",
        client_id: `${process.env.REACT_APP_CONSUMER_KEY}`,
        client_secret: `${process.env.REACT_APP_CONSUMER_SECRET}`,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: btoa(
            `${process.env.REACT_APP_CONSUMER_KEY}:${process.env.REACT_APP_CONSUMER_SECRET}`
          ),
        },
      }
    );
  }

  static verifyCnpj(cnpj: string) {
    return httpClientGovBr.get(`/consulta-cnpj-df/v2/qsa/${cnpj}`);
  }
}

export default companyConsumer;
