/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Card, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import DocumentsCompany from "src/components/DocumentsCompany";
import Empty from "src/components/Empty";
import CircularLoading from "src/components/Loading";
import useCompany from "src/hooks/useCompany";
import useDocuments from "src/hooks/useDocuments";
import { palette } from "src/theme";

const Documents = () => {
  const { getDocumentTypes, loading, typesDocuments, getDocuments, documents } =
    useDocuments();
  const { companySelected } = useCompany();

  useEffect(() => {
    getDocumentTypes();
    getDocuments({ idCompany: `${companySelected?.id}` });
  }, []);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100,
        }}
        open={false}
        onClick={() => {}}
      >
        <CircularLoading />
      </Backdrop>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography gutterBottom component="div">
            Documentos
          </Typography>
          <Typography gutterBottom component="div">
            Validade
          </Typography>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 15,
          }}
        />
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          {typesDocuments?.length === 0 ? (
            <Empty />
          ) : (
            typesDocuments?.map((item: any) => (
              <DocumentsCompany
                description={item?.descricao}
                type={item?.id}
                code={item?.codigo}
                document={documents?.items?.find(
                  (e: any) => e.documentosTipoId === item?.id
                )}
              />
            ))
          )}
        </Grid>
      </Card>
    </>
  );
};

export default Documents;
