/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { palette } from "src/theme";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import useLayout from "src/hooks/useLayout";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import CircularLoading from "src/components/Loading";
import useCompany from "src/hooks/useCompany";
import useCompanyContact from "src/hooks/useCompanyContact";
import { iCompany } from "src/interfaces/company";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import useUnit from "src/hooks/useUnit";
import useShoppingProcessPhasesItem from "src/hooks/useShoppingProcessPhasesItem";
import { formatCurrency } from "src/functions/text";
import DialogComponent from "src/components/Dialog";
import TableComponent from "src/components/TableComponent";
import Empty from "src/components/Empty";
import CloseIcon from "@mui/icons-material/Close";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import useShoppingProcessPhases from "src/hooks/useShoppingProcessPhases";

const numberSumWithPage = 40;

const ItemStagePurchasingProcess: React.FC = () => {
  const {
    setOpenDialogSecondary,
    openDialogThree,
    setOpenDialogThree,
    setLoadingDialog,
    width,
  } = useLayout();

  const {
    loading: loadingShoppingProccessPhaseItem,
    baseValue,
    setBaseValue,
    targetValue,
    setTargetValue,
    unitValue,
    setUnitValue,
    setShoppingProccessItemId,
    code,
    setCode,
    description,
    setDescription,
    setSupplierId,
    quantity,
    setQuantity,
    buyer,
    setBuyer,
    setBuyerId,
    setPaymentConditionId,
    setPaymentCondition,
    unitId,
    setUnitId,
    setUnit,
    handleSelected,
    setBuyerResponsibleId,
    paymentConditionValue,
    setPaymentConditionValue,
    setSupplierResponsibleId,
    buyerResponsible,
    setBuyerResponsible,
    supplierResponsibleValue,
    setSupplierResponsibleValue,
    setSupplierResponsible,
    setSupplier,
    shoppingProcesPhasesItemSelect,
    handleNewSalve,
    handleUpdate,
    setRatesPCI,
    supplierValue,
    setSupplierValue,
    itemShoppingProccess,
    setItemShoppingProccess,
  } = useShoppingProcessPhasesItem();

  const { setShowButtonClosed } = useShoppingProcessPhases();

  const { getTaxaPc, shoppingProccessRates } = useShoppingProccessRate();

  const {
    getShoppingProcessItem,
    shoppingProcessItemToSearch,
    codeSearch,
    setCodeSearch,
    setDescriptionSearch,
    page,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useShoppingProcessItem();

  const {
    loading: loadingCompany,
    companysToSearchSupplier,
    getCompanySupplier,
    nameSupplySearch,
    setNameSupplySearch,
    typingTimeout,
    setTypingTimeout,
  } = useCompany();

  const {
    getPaymentConditions,
    paymentConditionsToSearch,
    setPaymentConditionsToSearch,
  } = usePaymentCondition();

  const {
    companysContactToSearchSupplier,
    getCompanysContacts,
    loading: loadingCompanyContact,
  } = useCompanyContact();

  const { units, getUnits } = useUnit();

  useEffect(() => {
    setPaymentConditionsToSearch([]);
    getUnits();

    return () => {
      getUnits();
      getTaxaPc();

      if (shoppingProccessRates.length !== 0) {
        setRatesPCI(shoppingProccessRates);
      }
    };
  }, []);

  useEffect(() => {
    if (codeSearch.length >= 4) {
      getShoppingProcessItem();
    } else if (codeSearch.length === 0) {
      getShoppingProcessItem();
    }
  }, [codeSearch]);

  useEffect(() => {
    getShoppingProcessItem();
  }, [page]);

  useEffect(() => {
    const handleTypingTimeout = () => {
      if (nameSupplySearch?.length >= 4) {
        getCompanySupplier(numberSumWithPage);
      } else if (nameSupplySearch?.length === 0) {
        getCompanySupplier(numberSumWithPage);
      }
    };

    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(handleTypingTimeout, 1000); // 1000ms = 1 segundo
    setTypingTimeout(newTypingTimeout);

    return () => {
      clearTimeout(newTypingTimeout);
    };
  }, [nameSupplySearch]);

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Item de etapa do processo de compra
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialogSecondary(false);
          handleSelected(null);
          setNameSupplySearch("");
          setShowButtonClosed(false);
          setCodeSearch("");
          setDescriptionSearch("");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      {loadingCompany || loadingShoppingProccessPhaseItem ? (
        <CircularLoading />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!shoppingProcesPhasesItemSelect?.id) {
              handleNewSalve();
            } else {
              handleUpdate();
            }
            setCodeSearch("");
            setPage({
              ...page,
              page: 0,
            });
          }}
        >
          <DialogContent>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>
                      Item de processo de compra
                    </FormLabel>
                  ) : null}
                  <Autocomplete
                    disablePortal
                    id="show_select_code"
                    options={shoppingProcessItemToSearch}
                    fullWidth
                    value={itemShoppingProccess}
                    getOptionLabel={(option) => {
                      if (option?.code) {
                        return `${option.code} - ${option.label}`;
                      }

                      return "";
                    }}
                    onInputChange={(_event, newInputValue) => {
                      if (!newInputValue) {
                        setCodeSearch("");
                        setDescriptionSearch("");
                      }
                    }}
                    onChange={async (_: any, value: any) => {
                      if (value?.id) {
                        setItemShoppingProccess(value);
                        setShoppingProccessItemId(value?.id ?? "");
                        setCode(value?.code ?? "");
                        setDescription(value?.label ?? "");
                        setBuyerId(value?.compradorId ?? "");
                        setBuyer(value?.comprador ?? "");
                        setPaymentConditionValue({
                          id: value?.condicaoPagamentoId,
                          label: value?.condicaoPagamento,
                        });
                        setPaymentConditionId(value?.condicaoPagamentoId ?? "");
                        setPaymentCondition(value?.condicaoPagamento ?? "");
                        setUnitId(value?.unidadeId ?? "select");
                        setUnit(value?.unidade ?? "");
                        setBuyerResponsibleId(value?.responsavelCompradorId);
                        setBuyerResponsible(value?.responsavelComprador);
                        setQuantity(value?.quantidade);

                        setBaseValue(
                          parseFloat(
                            value?.valorBaseline ?? "0.0"
                          )?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        );

                        setTargetValue(
                          parseFloat(value?.valorAlvo ?? "0.0")?.toLocaleString(
                            "pt-br",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        );

                        await getPaymentConditions({
                          id: value?.compradorId,
                        } as iCompany);
                      } else {
                        setItemShoppingProccess(null);
                        setShoppingProccessItemId("");
                        setCode("");
                        setDescription("");
                        setBuyerId("");
                        setBuyer("");
                        setPaymentConditionId("");
                        setPaymentCondition("");
                        setUnitId("select");
                        setUnit("");
                        setBuyerResponsibleId("");
                        setBuyerResponsible("");
                        setSupplierId("");
                        setSupplier("");
                        setSupplierResponsibleId("");
                        setSupplierResponsible("");
                        setBaseValue("");
                        setTargetValue("");
                        setPaymentConditionValue(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e: any) => {
                          const value = e.target.value;

                          if (value) {
                            setCodeSearch(value);
                            setDescriptionSearch(value);
                          } else {
                            setCodeSearch("");
                            setDescriptionSearch("");
                          }
                        }}
                      />
                    )}
                  />
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => {
                      setLoadingDialog(true);

                      setOpenDialogThree(true);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>Código</FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Código do item"
                    disabled
                    value={code}
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>Descrição</FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Descrição do item"
                    disabled
                    value={description}
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>
                      Empresa Compradora
                    </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Descrição do item"
                    disabled
                    value={buyer}
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>
                      Responsável Comprador
                    </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Descrição do item"
                    disabled
                    value={buyerResponsible}
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>
                      Empresa Fornecedora
                    </FormLabel>
                  ) : null}
                  <Autocomplete
                    disablePortal
                    id="show_select_supply_company"
                    options={companysToSearchSupplier}
                    value={supplierValue}
                    fullWidth
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setSupplierValue(value);
                        getCompanysContacts(value.id, value as iCompany);
                        setSupplierId(value.id);
                        setSupplier(value.label);
                      } else {
                        setSupplierValue(null);
                        setSupplierResponsibleValue(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setNameSupplySearch(e.target.value)}
                      />
                    )}
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "12%" }}>
                      Responsável Fornecedor
                    </FormLabel>
                  ) : null}
                  <Autocomplete
                    disablePortal
                    id="show_select_supply_responsible"
                    options={companysContactToSearchSupplier}
                    value={supplierResponsibleValue}
                    fullWidth
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setSupplierResponsibleValue(value);
                        setSupplierResponsibleId(value.id);
                        setSupplierResponsible(value.label);
                      } else {
                        setSupplierResponsibleValue(null);
                      }
                    }}
                    renderInput={(params) =>
                      loadingCompanyContact ? (
                        <CircularLoading />
                      ) : (
                        <TextField
                          {...params}
                          label="Selecione:"
                          size="small"
                          required
                        />
                      )
                    }
                  />
                </ItemCustom>
              </Grid>

              <Grid xs={4} sm={4} md={4}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "36%" }}>
                      Condição de pagamento
                    </FormLabel>
                  ) : null}
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      id="show_select_payment_condition"
                      options={paymentConditionsToSearch}
                      value={paymentConditionValue}
                      onChange={(_: any, value: any) => {
                        if (value?.id) {
                          setPaymentConditionValue(value);
                          setPaymentConditionId(value.id);
                          setPaymentCondition(value.label);
                        } else {
                          setPaymentConditionValue(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione:"
                          size="small"
                          required
                        />
                      )}
                    />
                  </FormControl>
                </ItemCustom>
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <ItemCustomStart>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "30%" }}>
                      Valor Baseline:{" "}
                    </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    id="value_baseline"
                    name="value_baseline"
                    label="R$ ##.###.##0,00"
                    variant="outlined"
                    value={baseValue}
                    disabled
                    fullWidth
                  />
                </ItemCustomStart>
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <ItemCustomStart>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "30%" }}>Valor Alvo: </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    id="value_alvo"
                    name="value_alvo"
                    label="R$ ##.###.##0,00"
                    variant="outlined"
                    value={targetValue}
                    disabled
                    fullWidth
                  />
                </ItemCustomStart>
              </Grid>

              <Grid xs={4} sm={4} md={4}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "36%" }}>
                      Unidade de Medida:{" "}
                    </FormLabel>
                  ) : null}
                  <FormControl fullWidth>
                    <Select
                      labelId="show_select_type"
                      id="show_select_type"
                      value={unitId}
                      required
                      size="small"
                      onChange={(event: SelectChangeEvent) =>
                        setUnitId(event.target.value as string)
                      }
                    >
                      <MenuItem value="select" disabled>
                        {width <= 1500
                          ? "Unidade de Medida - Selecione"
                          : "Selecione"}
                        :
                      </MenuItem>
                      <MenuItem value="">...</MenuItem>
                      {units.map((unit) => (
                        <MenuItem key={unit.id} value={`${unit.id}`}>
                          {unit.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ItemCustom>
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "30%" }}>Quantidade: </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    id="quantity"
                    name="quantity"
                    required
                    label="Quantidade"
                    variant="outlined"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                    fullWidth
                  />
                </ItemCustom>
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <ItemCustom>
                  {width >= 1500 ? (
                    <FormLabel sx={{ minWidth: "30%" }}>
                      Valor Unitário:{" "}
                    </FormLabel>
                  ) : null}
                  <TextField
                    size="small"
                    id="value_unit"
                    name="value_unit"
                    required
                    label="R$ ##.###.##0,00"
                    variant="outlined"
                    value={unitValue}
                    fullWidth
                    onChange={(e) => {
                      e = formatCurrency(e);
                      setUnitValue(e.target.value);
                    }}
                  />
                </ItemCustom>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginBottom: "1%" }}>
            <ButtonDefault
              label="Fechar"
              onClick={() => {
                handleSelected(null);
                setOpenDialogSecondary(false);
                setNameSupplySearch("");
                setShowButtonClosed(false);
                setCodeSearch("");
                setDescriptionSearch("");
              }}
            />
            <ButtonSalveForm label="Salvar" colorIcon="success" type="submit" />
          </DialogActions>
        </form>
      )}

      <DialogComponent open={openDialogThree} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Itens relacionados</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogThree(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {shoppingProcessItemToSearch.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "Código",
                },
                {
                  label: "Descrição",
                },
              ]}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {shoppingProcessItemToSearch?.map((item) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShoppingProccessItemId(item.id);
                    setCode(item.code);
                    setDescription(item.label);
                    setBuyerId(item?.compradorId ?? "");
                    setBuyer(item?.comprador ?? "");
                    setPaymentConditionId(item?.condicaoPagamentoId ?? "");
                    setPaymentCondition(item?.condicaoPagamento ?? "");
                    setUnitId(item?.unidadeId ?? "select");
                    setUnit(item?.unidade ?? "");

                    setOpenDialogThree(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialogThree(false);
            }}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default ItemStagePurchasingProcess;
