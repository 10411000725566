import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";

export default class CategoryProductGeneral implements iCategoryProductGeneral {
  id: string;
  code: string;
  description: string;
  productCategoryId: string;
  active: boolean;
  productCategory?: string;
  productCategoryCode?: string;

  constructor(
    id: string,
    code: string,
    description: string,
    productCategoryId: string,
    active: boolean,
    productCategory?: string,
    productCategoryCode?: string
  ) {
    this.id = id;
    this.code = code;
    this.description = description;
    this.productCategoryId = productCategoryId;
    this.active = active;
    this.productCategory = productCategory;
    this.productCategoryCode = productCategoryCode;
  }

  public static adapterToClass(categoryProductGeneral: any) {
    return new CategoryProductGeneral(
      categoryProductGeneral.id,
      categoryProductGeneral.codigo,
      categoryProductGeneral.descricao,
      categoryProductGeneral.categoriaProdutoId,
      categoryProductGeneral.ativo,
      categoryProductGeneral.categoriaProdutoDescricao,
      categoryProductGeneral.categoriaProdutoCodigo
    );
  }

  public static adapterToJson(network: any) {
    return JSON.parse(network);
  }

  public static adapterToBody(categoryProductGeneral: iCategoryProductGeneral) {
    const data: any = {
      codigo: categoryProductGeneral.code,
      descricao: categoryProductGeneral.description,
      ativo: categoryProductGeneral.active,
    };

    if (categoryProductGeneral?.id) {
      data["id"] = categoryProductGeneral.id;
    }

    if (
      categoryProductGeneral?.productCategoryId &&
      categoryProductGeneral?.productCategoryId !== "select"
    ) {
      data["categoriaProdutoId"] = categoryProductGeneral.productCategoryId;
    }

    return data;
  }
}
