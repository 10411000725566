/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Autocomplete,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import useCompany from "src/hooks/useCompany";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { iProductCompany } from "src/interfaces/products";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import useProductCompany from "src/hooks/useProductCompany";
import useProductCategory from "src/hooks/useProductCategory";
import useProduct from "src/hooks/useProduct";
import { ItemCustom } from "src/components/ItemCustom";
import Empty from "src/components/Empty";
import SearchIcon from "@mui/icons-material/Search";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const Product: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { setOpenDialog, openDialog } = useLayout();
  const { companySelected } = useCompany();

  const {
    getProducts,
    productsToSearch,
    page: pageProduct,
    handleChangePage: handleChangePageProduct,
    handleChangeRowsPerPage: handleChangeRowsPerPageProduct,
    loading: loadingProduct,
  } = useProduct();

  const {
    loading,
    page,
    getProducts: getProductsCompany,
    products: productsCompany,
    productSelected,
    handleSalveNewProduct,
    handleUpdateProduct,
    handleDeleteProduct,
    toFillUSerDataOnForm,
    handleChangePage,
    handleChangeRowsPerPage,
    codeProductCompany,
    setCodeProductCompany,
    description,
    setDescription,
    unit,
    setUnit,
    highCubage,
    setHighCubage,
    sku,
    setSku,
    factorCash,
    setFactorCash,
    active,
    setActive,
    defaultProd,
    setDefaultProd,
    setProductId,
    categoryProduct,
    setCategoryProduct,
    productSelect,
    setProductSelect,
  } = useProductCompany();

  const {
    productCategorysToSearch,
    getCategories,
    description: descriptionMainProduct,
    setDescription: setDescriptionMainProduct,
    descriptionSearch,
    setDescriptionSearch,
  } = useProductCategory();

  useEffect(() => {
    toFillUSerDataOnForm(null);
  }, []);

  useEffect(() => {
    if (descriptionMainProduct?.length >= 4) {
      getProducts(false, descriptionMainProduct);
    } else if (descriptionMainProduct?.length === 0) {
      getProducts(false, descriptionMainProduct);
    }
  }, [descriptionMainProduct]);

  useEffect(() => {
    getProductsCompany(companySelected?.id, page.page, page.rowsPerPage);
  }, [page]);

  useEffect(() => {
    if (descriptionSearch.length >= 4) {
      getCategories(companySelected?.id, page.page, page.rowsPerPage);
    } else if (descriptionSearch.length === 0) {
      getCategories(companySelected?.id, page.page, page.rowsPerPage);
    }
  }, [descriptionSearch]);

  if (loading) return <CircularLoading />;

  const header = [
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["unit"],
    },
    {
      label: translations[param]["category"],
    },
    {
      label: translations[param]["active"],
    },
  ];

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!productSelected?.id) {
              handleSalveNewProduct();
            } else {
              handleUpdateProduct();
            }
          }}
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "10%" }}>
                  {translations[param]["main_product"]}:{" "}
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productsToSearch}
                  value={productSelect}
                  getOptionLabel={(option) => option.label}
                  sx={{ minWidth: "90%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setProductSelect(value);
                      setProductId(value?.id);
                    } else {
                      setProductSelect(null);
                      setProductId("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translations[param]["select"]}
                      size="small"
                      onChange={(e) =>
                        setDescriptionMainProduct(e.target.value)
                      }
                    />
                  )}
                />
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => setOpenDialog(true)}
                >
                  <SearchIcon />
                </IconButton>
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={6} md={6} lg={6}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "21%" }}>
                  {translations[param]["code"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="codeProductCompany"
                    name="codeProductCompany"
                    label={translations[param]["code"]}
                    variant="outlined"
                    size="small"
                    value={codeProductCompany}
                    onChange={(e) => setCodeProductCompany(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid
              xs={4}
              sm={4}
              smOffset={2}
              md={4}
              mdOffset={2}
              lg={4}
              lgOffset={2}
              display="flex"
              justifyContent="end"
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="highCubage"
                      name="highCubage"
                      size="small"
                      checked={highCubage}
                      onChange={(e) => setHighCubage(e.target.checked)}
                    />
                  }
                  label={translations[param]["high_cubage"]}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="defaultProd"
                      name="defaultProd"
                      size="small"
                      checked={defaultProd}
                      onChange={(e) => setDefaultProd(e.target.checked)}
                    />
                  }
                  label={translations[param]["default"]}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="blocked"
                      name="blocked"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={12} md={12} lg={12}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "10.5%" }}>
                  {translations[param]["description"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="description"
                    name="description"
                    label={translations[param]["description"]}
                    required
                    variant="outlined"
                    size="small"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "33%" }}>
                  {translations[param]["category"]}:{" "}
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productCategorysToSearch}
                  value={categoryProduct}
                  getOptionLabel={(option) => option.label}
                  sx={{ minWidth: "70%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setCategoryProduct(value);
                    } else {
                      setCategoryProduct(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translations[param]["select"]}
                      size="small"
                      onChange={(e) => setDescriptionSearch(e.target.value)}
                    />
                  )}
                />
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={3} md={3} lg={3}>
              <ItemCustom>
                <FormLabel>{translations[param]["unit"]}: </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="unit"
                    name="unit"
                    label={translations[param]["unit"]}
                    required
                    variant="outlined"
                    size="small"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={2} md={2} lg={2}>
              <ItemCustom>
                <FormLabel>{translations[param]["box_factor"]}: </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="factorCash"
                    name="factorCash"
                    label={translations[param]["box_factor"]}
                    type="number"
                    variant="outlined"
                    size="small"
                    value={factorCash}
                    onChange={(e) => setFactorCash(parseInt(e.target.value))}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={3} md={3} lg={3}>
              <ItemCustom>
                <FormLabel>{translations[param]["sku"]}: </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="sku"
                    name="sku"
                    label={translations[param]["sku"]}
                    variant="outlined"
                    size="small"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: 3 }}
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => toFillUSerDataOnForm(null)}
            />
            <FormControl>
              {productSelected?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {productsCompany.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {productsCompany?.map((data: iProductCompany) => (
                <TableRow
                  hover
                  key={data.productId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => toFillUSerDataOnForm(data)}
                  >
                    {data.codeProductCompany}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.description}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.unit}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.category}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        Swal.fire({
                          title: translations[param]["attention"] + "!",
                          text: translations[param][
                            "delete_product_confirmation"
                          ],
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["delete"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) handleDeleteProduct(data);
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          {translations[param]["main_product"]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingProduct ? (
            <CircularLoading />
          ) : productsToSearch?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: translations[param]["code"],
                },
                {
                  label: translations[param]["description"],
                },
              ]}
              page={pageProduct}
              handleChangePage={handleChangePageProduct}
              handleChangeRowsPerPage={handleChangeRowsPerPageProduct}
            >
              {productsToSearch.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item?.id) {
                      setProductSelect(item);
                    } else {
                      setProductSelect(null);
                    }

                    setOpenDialog(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label={translations[param]["close"]}
            onClick={() => setOpenDialog(false)}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default Product;
