import { iProduct } from "src/interfaces/products";

export default class Product implements iProduct {
  id;
  code;
  description;
  categoryProductId;
  categoryProduct;
  unitsId;
  codeManufacturer;
  ncm;
  active;
  ean;
  atributos: any[];

  constructor(
    id: string | null | undefined,
    code: string,
    description: string,
    categoryProductId: string,
    categoryProduct: string,
    unitsId: string,
    codeManufacturer: string,
    ncm: string,
    active: boolean,
    ean: string,
    atributos: any[]
  ) {
    this.id = id;
    this.code = code;
    this.description = description;
    this.categoryProductId = categoryProductId;
    this.categoryProduct = categoryProduct;
    this.unitsId = unitsId;
    this.codeManufacturer = codeManufacturer;
    this.ncm = ncm;
    this.active = active;
    this.ean = ean;
    this.atributos = atributos;
  }

  public static adapterToClass(product: any) {
    return new Product(
      product.id,
      product.codigo,
      product.descricao,
      product.categoriaProdutoId,
      product.categoriaProduto,
      product.unidadesId,
      product.codigoFabricante,
      product.ncm,
      product.ativo,
      product.ean,
      product.atributos
    );
  }

  public static adapterToJson(product: any) {
    return JSON.parse(product);
  }

  public static adapterToBody(product: iProduct) {
    if (product.id) {
      return {
        id: product.id,
        codigo: product.code,
        descricao: product.description,
        categoriaProdutoId: product.categoryProductId,
        unidadesId: product.unitsId,
        codigoFabricante: product.codeManufacturer,
        ncm: product.ncm,
        ativo: product.active,
        ean: product.ean,
      };
    }

    return {
      codigo: product.code,
      descricao: product.description,
      categoriaProdutoId: product.categoryProductId,
      unidadesId: product.unitsId,
      codigoFabricante: product.codeManufacturer,
      ncm: product.ncm,
      ativo: product.active,
      ean: product.ean,
    };
  }
}
