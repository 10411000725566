/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { iRate, iRateContextProps, iRatePage } from "src/interfaces/rate";
import ratesConsumer from "src/services/rates";
import Rate from "src/models/Rate";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const RateContext = createContext<iRateContextProps>({} as iRateContextProps);

export function RateProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const [loading, setLoading] = useState<boolean>(false);
  const [rates, setRates] = useState<Array<iRate>>([]);
  const [page, setPage] = useState<iRatePage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: false,
  });

  const [rateSelect, setRateSelect] = useState<iRate | null>(null);
  const [description, setDescription] = useState<string>("");
  const [rate, setRate] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<any>(null);
  const [companyAdministrator, setCompanyAdministrator] = useState<any>(null);
  const [partnerCompany, setPartnerCompany] = useState<any>(null);
  const [companyReceivedId, setCompanyReceivedId] = useState<any>(null);
  const [networkId, setNetworkId] = useState<string>("select");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: 20,
      change: true,
    });
  };

  const getRates = async () => {
    setLoading(true);
    try {
      const response = await ratesConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        Rate.adapterToClass(item)
      );

      if (response.data.items === 0 && page.total === 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setRates(data);
      handleSelected(null);
    } catch (e) {
      toast.error(translations[param]["get_network_error"]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (rate: iRate | null) => {
    setRateSelect(rate);
    setDescription(rate?.description ?? "");
    setRate(rate?.rate ?? 0);
    setCompanyId(rate?.companyId ?? null);
    setCompanyReceivedId(rate?.companyReceivedId);
    setNetworkId(rate?.networkId ?? "select");
    setActive(rate?.active ?? false);
    setCompanyAdministrator({
      id: rate?.companyId ?? "",
      label: rate?.companyDescription ?? "",
    });
    setPartnerCompany({
      id: rate?.companyReceivedId ?? "",
      label: rate?.companyReceived ?? "",
    });
  };

  const handleNewSalve = async () => {
    try {
      setLoading(true);

      const body = new Rate(
        "",
        description,
        rate,
        companyId,
        "",
        networkId,
        "",
        active,
        companyReceivedId
      );

      const response = await ratesConsumer.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success(translations[param]["post_network_success"]);

      handleSelected(null);
      getRates();
    } catch (e) {
      toast.error(translations[param]["post_network_error"]);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const body = new Rate(
        rateSelect?.id ?? "",
        description,
        rate,
        companyId,
        "",
        networkId,
        "",
        active,
        companyReceivedId
      );

      const response = await ratesConsumer.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success(translations[param]["put_network_success"]);

      handleSelected(null);
      getRates();
    } catch {
      toast.error(translations[param]["put_network_error"]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (rate: iRate) => {
    try {
      setLoading(true);

      const response = await ratesConsumer.deleted(`${rate?.id}`);

      if (response.status !== 200) throw response;

      toast.success(translations[param]["delete_network_success"]);

      setPage({
        ...page,
        page: 0,
      });

      handleSelected(null);
      getRates();
    } catch {
      toast.error(translations[param]["delete_network_error"]);
    } finally {
      setLoading(false);
    }
  };

  const handleSortData = (data: { id: string; order: string }) => {
    const { id, order } = data;

    // ASC: < & >
    // DESC: > & <
    if (order === "asc") {
      const dataOrder = rates.sort(function (a: any, b: any) {
        if (a[id] < b[id]) {
          return -1;
        }
        if (a[id] > b[id]) {
          return 1;
        }
        return 0;
      });

      setRates([...dataOrder]);
    } else {
      const dataOrder = rates.sort(function (a: any, b: any) {
        if (a[id] > b[id]) {
          return -1;
        }
        if (a[id] < b[id]) {
          return 1;
        }
        return 0;
      });

      setRates([...dataOrder]);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      setPage,
      getRates,
      loading,
      setLoading,
      rates,
      handleChangePage,
      handleChangeRowsPerPage,
      rateSelect,
      setRateSelect,
      description,
      setDescription,
      rate,
      setRate,
      companyId,
      setCompanyId,
      networkId,
      setNetworkId,
      handleSelected,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      active,
      setActive,
      companyReceivedId,
      setCompanyReceivedId,
      handleSortData,
      companyAdministrator,
      setCompanyAdministrator,
      partnerCompany,
      setPartnerCompany,
    };
  }, [
    page,
    loading,
    rates,
    rateSelect,
    description,
    rate,
    companyId,
    networkId,
    active,
    companyReceivedId,
    companyAdministrator,
    partnerCompany,
  ]);

  return (
    <RateContext.Provider value={contextValue}>{children}</RateContext.Provider>
  );
}

export default RateContext;
