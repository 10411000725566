import httpClientShoppingProcess from "src/http/shoppingProcess";
import { iRate, iRatePage } from "src/interfaces/rate";
import Rate from "src/models/Rate";

class ratesConsumer {
  static get(page: iRatePage) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientShoppingProcess.get(
      `/ProcessoCompraTaxa?${search.toString()}`
    );
  }

  static created(rate: iRate) {
    return httpClientShoppingProcess.post(
      `/ProcessoCompraTaxa`,
      Rate.adapterToBody(rate)
    );
  }

  static updated(rate: iRate) {
    return httpClientShoppingProcess.put(
      `/ProcessoCompraTaxa`,
      Rate.adapterToBody(rate)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientShoppingProcess.delete(`/ProcessoCompraTaxa?id=${id}`);
  }
}

export default ratesConsumer;
