import httpClientShoppingProcess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";
import RatePCItem from "src/models/RatePCItem";

class TaxaPCItem {
  static get(page: iPage, shoppingProccessId: string) {
    return httpClientShoppingProcess.get(
      `/TaxaPCItem?PageNumber=${page.page}&PageSize=${page.rowsPerPage}&ProcessoCompraItem=${shoppingProccessId}`
    );
  }

  static created(body: any) {
    return httpClientShoppingProcess.post(
      `/TaxaPCItem`,
      RatePCItem.adapterToBody(body)
    );
  }

  static update(body: any) {
    return httpClientShoppingProcess.put(
      `/TaxaPCItem`,
      RatePCItem.adapterToBody(body)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientShoppingProcess.delete(`/TaxaPCItem?id=${id}`);
  }
}

export default TaxaPCItem;
