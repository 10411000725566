/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormControl,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import Layout from "src/components/Layout";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useCompany from "src/hooks/useCompany";
import useProduct from "src/hooks/useProduct";
import useProductCompany from "src/hooks/useProductCompany";
import useFromToProducts from "src/hooks/useFromToProducts";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { iProduct, iProductCompany } from "src/interfaces/products";
import { palette } from "src/theme";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const FromToProducts: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { companysToSearch, nameSearch, setNameSearch, getCompanys } =
    useCompany();

  const {
    getProducts,
    products,
    setDescription,
    description,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    loading: loadingProduct,
  } = useProduct();

  const {
    getProducts: getProductsCompany,
    products: productsCompany,
    setProducts,
    page: pageProductsCompany,
    handleChangePage: handleChangePageProductsCompany,
    handleChangeRowsPerPage: handleChangeRowsPerPageProductsCompany,
    loading: loadingProductsCompany,
    description: descriptionProductCompany,
    setDescription: setDescriptionProductCompany,
  } = useProductCompany();

  const {
    companySelect,
    setCompanySelect,
    productSelect,
    setProductSelect,
    handleLink,
    productCompanySelect,
    setProductCompanySelect,
    loading,
    setLoading,
  } = useFromToProducts();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: "",
    },
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["routines"],
    },
    {
      name: translations[param]["from_to_products"],
    },
  ];

  const headerProducts = [
    {
      label: "",
    },
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["category"],
    },
  ];

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      setCompanySelect("");
      setDescription("");
      setDescriptionProductCompany("");
      setNameSearch("");
      setProducts([]);
      setProductSelect(null);
      setProductCompanySelect(null);
    };
  }, []);

  useEffect(() => {
    getProducts(true);
  }, [page.page]);

  useEffect(() => {
    if (companySelect?.id) {
      getProductsCompany(
        companySelect?.id,
        pageProductsCompany.page,
        pageProductsCompany.rowsPerPage,
        true
      );
    }
  }, [pageProductsCompany]);

  useEffect(() => {
    if (nameSearch?.length >= 5) {
      getCompanys({ isFilter: true });
    } else if (nameSearch?.length === 0) {
      getCompanys({ isFilter: true });
    }
  }, [nameSearch]);

  if (loading) {
    return (
      <Layout buttons={[]} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Card sx={{ width: "100%", padding: "2%" }}>
          <Grid xs={12} display="flex" alignItems="center">
            <Grid xs={1}>
              <Typography variant="subtitle1" gutterBottom>
                {translations[param]["company"]}
              </Typography>
            </Grid>

            <Grid xs={11}>
              <Autocomplete
                disablePortal
                id="show_select_administrator_company"
                options={companysToSearch}
                value={companySelect}
                getOptionLabel={(option) => option?.label ?? ""}
                sx={{ minWidth: "85%" }}
                onChange={(_: any, value: any) => {
                  if (value?.id) {
                    setCompanySelect(value);
                    getProductsCompany(
                      value?.id,
                      pageProductsCompany.page,
                      pageProductsCompany.rowsPerPage,
                      true
                    );
                  } else {
                    setCompanySelect(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione:"
                    size="small"
                    required
                    onChange={(e) => setNameSearch(e?.target?.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Card sx={{ width: "100%", padding: "2%", marginTop: "1%" }}>
          <Grid
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid xs={12} sm={6} display="flex" alignItems="center">
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ minWidth: "15%" }}
              >
                <strong>{translations[param]["products"]}: </strong>
              </Typography>
              <FormControl sx={{ marginRight: "1%", minWidth: "50%" }}>
                <TextField
                  size="small"
                  id="products"
                  name="products"
                  label={`${translations[param]["search"]} ${translations[param]["description"]}`}
                  variant="outlined"
                  value={description}
                  onChange={(e) =>
                    setDescription(e.target.value.toLocaleUpperCase())
                  }
                />
              </FormControl>
              <ButtonDefault
                label={translations[param]["search"]}
                onClick={() => {
                  if (description.length !== 0) {
                    getProducts(false, description);
                  } else {
                    getProducts(true);
                  }
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} display="flex" alignItems="center">
              <Typography
                sx={{ minWidth: "18%" }}
                variant="subtitle1"
                gutterBottom
              >
                <strong>
                  {`${translations[param]["product"]} ${translations[param]["company"]}`}
                  :
                </strong>
                <br />
                {companySelect && (
                  <span style={{ fontSize: "small" }}>
                    {`(${companySelect?.label})`}
                  </span>
                )}
              </Typography>
              <FormControl sx={{ marginRight: "1%", minWidth: "45%" }}>
                <TextField
                  size="small"
                  id="productCompany"
                  name="productCompany"
                  label={`${translations[param]["search"]} ${translations[param]["description"]}`}
                  variant="outlined"
                  value={descriptionProductCompany}
                  onChange={(e) =>
                    setDescriptionProductCompany(
                      e.target.value.toLocaleUpperCase()
                    )
                  }
                />
              </FormControl>
              <ButtonDefault
                label="Pesquisar"
                disabled={!companySelect?.id}
                onClick={() =>
                  getProductsCompany(
                    companySelect?.id,
                    pageProductsCompany.page,
                    pageProductsCompany.rowsPerPage,
                    true
                  )
                }
              />
            </Grid>
          </Grid>

          <Divider
            style={{ backgroundColor: palette.primary.main, marginTop: "2%" }}
          />

          <Grid
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid xs={12} sm={5}>
              {loadingProduct ? (
                <CircularLoading />
              ) : products.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={headerProducts}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {products
                    ?.sort((a: iProduct, b: iProduct) =>
                      a.code.localeCompare(b.code, undefined, {
                        numeric: true,
                      })
                    )
                    ?.map((item: iProduct) => (
                      <TableRow
                        hover
                        key={item.id}
                        selected={item?.id === productSelect?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={item?.id === productSelect?.id}
                            onClick={() => {
                              if (item?.id === productSelect?.id) {
                                return setProductSelect(null);
                              }

                              setProductSelect(item);
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.code}
                        </TableCell>
                        <TableCell>{item?.description}</TableCell>
                        <TableCell>{item?.categoryProduct}</TableCell>
                      </TableRow>
                    ))}
                </TableComponent>
              )}
            </Grid>
            <Grid
              xs={12}
              sm={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonSalveForm
                label={translations[param]["link"]}
                disabled={
                  productSelect === null || productCompanySelect == null
                }
                onClick={handleLink}
              />
            </Grid>
            <Grid xs={12} sm={5}>
              {loadingProductsCompany ? (
                <CircularLoading />
              ) : productsCompany.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={headerProducts}
                  page={pageProductsCompany}
                  handleChangePage={handleChangePageProductsCompany}
                  handleChangeRowsPerPage={
                    handleChangeRowsPerPageProductsCompany
                  }
                >
                  {productsCompany
                    ?.sort((a: iProductCompany, b: iProductCompany) =>
                      a.codeProductCompany.localeCompare(
                        b.codeProductCompany,
                        undefined,
                        {
                          numeric: true,
                        }
                      )
                    )
                    ?.map((item: iProductCompany) => (
                      <TableRow
                        hover
                        key={item.id}
                        selected={item?.id === productCompanySelect?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={item?.id === productCompanySelect?.id}
                            onClick={() => {
                              if (item?.id === productCompanySelect?.id) {
                                return setProductCompanySelect(null);
                              }
                              setProductCompanySelect(item);
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.codeProductCompany}
                        </TableCell>
                        <TableCell>{item?.description}</TableCell>
                        <TableCell>{item?.category}</TableCell>
                      </TableRow>
                    ))}
                </TableComponent>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Layout>
  );
};

export default FromToProducts;
