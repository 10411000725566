import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import { useParams } from "react-router-dom";

export default function CircularLoading() {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <strong>{translations[param]["loading"]}</strong>
    </Box>
  );
}
