import { eMessage } from "src/enums/messages";
import Alert from "@mui/material/Alert";

const MessageAlert = ({
  currentMessage,
  handleOnClose,
}: {
  currentMessage: { message: String; type: eMessage };
  handleOnClose: () => void;
}) => {
  if (currentMessage.type === eMessage.success) {
    return (
      <Alert onClose={handleOnClose} severity="success">
        {currentMessage.message}
      </Alert>
    );
  } else if (currentMessage.type === eMessage.error) {
    return (
      <Alert onClose={handleOnClose} severity="error">
        {currentMessage.message}
      </Alert>
    );
  } else if (currentMessage.type === eMessage.info) {
    return (
      <Alert onClose={handleOnClose} severity="info">
        {currentMessage.message}
      </Alert>
    );
  } else if (currentMessage.type === eMessage.warning) {
    return (
      <Alert onClose={handleOnClose} severity="warning">
        {currentMessage.message}
      </Alert>
    );
  } else {
    return <></>;
  }
};

export default MessageAlert;
