/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { theme } from "src/theme";
import Grid from "@mui/material/Unstable_Grid2";
import ReactInputMask from "react-input-mask";
import { MaskStyle } from "src/styles/globalStyle";
import { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import { iAddressType } from "src/interfaces/address";

const Logistic = ({
  addresses,
  statesOfCountry,
  addressTypes,
}: {
  addresses: any[];
  statesOfCountry: any[];
  addressTypes: any[];
}) => {
  const {
    isLargeScreen,
    address,
    setAddress,
    demand,
    daysOfWeek,
    setDaysOfWeek,
    expandedAccordion,
    setExpandedAccordion,
  } = useDemandCollectionForm();

  useEffect(() => {
    setAddress(null);
    setAddress({
      ...address,
      typeAddressId:
        addressTypes?.find(
          (e) => e.description.toUpperCase() === "Entrega".toUpperCase()
        )?.id || "select",
    });
  }, []);

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid xs={12} display="flex" alignItems="center">
        {isLargeScreen && (
          <Grid xs={1}>
            <Typography
              variant="body2"
              style={{ marginRight: theme.spacing(1) }}
            >
              Endereço de entrega
            </Typography>
          </Grid>
        )}
        <Grid xs={11} width={"100%"}>
          <Autocomplete
            disablePortal
            options={[
              {
                label: "+ Adicionar novo endereço",
              },
              ...addresses,
            ]}
            getOptionLabel={(option) => {
              if (option?.label) {
                return option?.label;
              }
              return "";
            }}
            freeSolo
            id="show_select_administrator_code"
            value={address}
            size="small"
            onChange={(_, value) => {
              if (value) {
                setAddress(value);
                setExpandedAccordion(true);

                if (value.horariosEntrega) {
                  const hours = value.horariosEntrega?.map((el: any) => {
                    const createDateWithTime = (time: string) => {
                      const [hours, minutes] = time.split(":")?.map(Number);
                      const date = new Date();
                      date.setHours(hours, minutes, 0, 0);
                      return date;
                    };

                    return {
                      day: el.diaSemana,
                      checked: true,
                      startHour: createDateWithTime(el.horarioInicio),
                      endHour: createDateWithTime(el.horarioFim),
                    };
                  });

                  setDaysOfWeek(hours);
                }
              } else {
                setExpandedAccordion(false);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione"
                size="small"
                onChange={(e) => {}}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid xs={12} md={11} mdOffset={1}>
        <Accordion expanded={expandedAccordion} disabled={!expandedAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Detalhes do endereço</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid xs={12} sm={4}>
                <MaskStyle padding="0px">
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    value={demand?.cnpj || ""}
                    disabled
                    style={{ paddingLeft: "10px" }}
                  />
                </MaskStyle>
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  value={demand?.razaoSocial || ""}
                  disabled
                />
              </Grid>

              <Grid xs={12} sm={8}>
                <TextField
                  fullWidth
                  placeholder="Endereço"
                  size="small"
                  value={address?.endereco}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      endereco: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Número"
                  size="small"
                  value={address?.numero}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      numero: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>

              <Grid xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Bairro"
                  size="small"
                  value={address?.bairro}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      bairro: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Complemento"
                  size="small"
                  value={address?.complemento}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      complemento: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <FormControl fullWidth>
                  <Select
                    labelId="show_select_type_address"
                    id="show_select_type_address"
                    value={
                      addressTypes?.find(
                        (e) =>
                          e.description.toUpperCase() ===
                          "Entrega".toUpperCase()
                      )?.id || "select"
                    }
                    required
                    disabled
                    size="small"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value="select" disabled>
                      Selecione:{" "}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {addressTypes?.map((item: iAddressType, _) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Cidade"
                  size="small"
                  value={address?.cidade}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      cidade: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>
              <Grid xs={6} sm={2}>
                <FormControl fullWidth>
                  <Select
                    value={address?.estado || "select"}
                    size="small"
                    onChange={(event: SelectChangeEvent) =>
                      setAddress({
                        ...address,
                        estado: event.target.value as string,
                      })
                    }
                  >
                    <MenuItem value="select" disabled>
                      Selecione:{" "}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {statesOfCountry?.map((item) => (
                      <MenuItem key={item.id} value={item.nome}>
                        {item.nome?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} sm={3}>
                <TextField
                  fullWidth
                  label="PAÍS"
                  value="BRASIL"
                  size="small"
                  disabled
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <MaskStyle padding="0px">
                  <ReactInputMask
                    mask="99999-999"
                    name="cep"
                    placeholder="CEP"
                    style={{ paddingLeft: "10px" }}
                    value={address?.cep}
                    onChange={(e) => {
                      setAddress({
                        ...address,
                        cep: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </MaskStyle>
              </Grid>

              <Grid xs={12}>
                <Typography variant="subtitle1">
                  Entregas (Dias da Semana)
                </Typography>
              </Grid>
              {["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"].map(
                (day, i) => {
                  const dayData =
                    daysOfWeek.find((d: any) => d.day === day) || {};

                  return (
                    <Grid
                      xs={12}
                      sm={6}
                      md={1.7}
                      key={day}
                      container
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid
                        container
                        xs={12}
                        md={12}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Checkbox
                          checked={dayData?.checked || false}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDaysOfWeek([
                                ...daysOfWeek,
                                {
                                  day: day,
                                  checked: e.target.checked,
                                },
                              ]);
                            } else {
                              const days = daysOfWeek.filter(
                                (e: any) => e.day !== day
                              );
                              setDaysOfWeek(days);
                            }
                          }}
                        />
                        <Typography>{day}</Typography>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        md={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        style={{ marginTop: 4 }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={ptBR}
                        >
                          <TimePicker
                            value={dayData.startHour || null}
                            onChange={(e: any) => {
                              const days = daysOfWeek?.map((element: any) => {
                                if (element?.day === day) {
                                  return {
                                    ...element,
                                    startHour: e,
                                  };
                                }

                                return element;
                              });

                              setDaysOfWeek(days);
                            }}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={ptBR}
                        >
                          <TimePicker
                            value={dayData.endHour || null}
                            sx={{ marginTop: 2 }}
                            onChange={(e: any) => {
                              const days = daysOfWeek?.map((element: any) => {
                                if (element?.day === day) {
                                  return {
                                    ...element,
                                    endHour: e,
                                  };
                                }

                                return element;
                              });

                              setDaysOfWeek(days);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Logistic;
