/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const RefreshContext = createContext<any>({} as any);

export function RefreshProvider({ children }: { children: any }) {
  const router = useNavigate();
  let location = useLocation();
  const { lang } = useParams();

  window.addEventListener("beforeunload", (e) => {
    if (
      location.pathname.replace(`/${lang}`, "") !==
      "/shopping/process/demand/collection/form"
    ) {
      onUnload(e);
    }
  });

  useEffect(() => {
    onAfterUnload();
  }, []);

  const onUnload = (e: any) => {
    e.preventDefault();
    sessionStorage.reloadAfterPageLoad = true;
    e.returnValue = true;
  };

  const onAfterUnload = () => {
    if (sessionStorage.reloadAfterPageLoad) {
      Swal.fire({
        title: "Atenção",
        text: "A página foi reiniciada e os dados salvos em memória perdidos, você será redirecionado para a página anterior!",
        icon: "warning",
      }).then((_) => {
        sessionStorage.clear();
        router(`/${lang}/home`);
      });
    }
  };

  const contextValue = useMemo(() => {
    return {};
  }, []);

  return (
    <RefreshContext.Provider value={contextValue}>
      {children}
    </RefreshContext.Provider>
  );
}

export default RefreshContext;
