/* eslint-disable react-hooks/exhaustive-deps */
import TableComponent from "src/components/TableComponent";
import { iAddress, iAddressType } from "src/interfaces/address";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useAddress from "src/hooks/useAddress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { useEffect } from "react";
import { MaskStyle } from "src/styles/globalStyle";
import ReactInputMask from "react-input-mask";
import CircularLoading from "src/components/Loading";
import useCompany from "src/hooks/useCompany";
import Empty from "src/components/Empty";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import {
  ButtonClosedForm,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const Address: React.FC = () => {
  const { lang } = useParams();
  const param = lang as keyof tLanguage;
  const { companySelected } = useCompany();

  const header = [
    {
      label: translations[param]["address"],
    },
    {
      label: translations[param]["number"],
    },
    {
      label: translations[param]["neighborhood"],
    },
    {
      label: translations[param]["city"],
    },
    {
      label: translations[param]["type"],
    },
    {
      label: translations[param]["main"],
    },
  ];

  const {
    loading,
    addresses,
    page,
    addressSelect,
    handleNewSalve,
    handleUpdate,
    getAddress,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    handleDelete,
    cep,
    setCep,
    address,
    setAddress,
    number,
    setNumber,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    main,
    setMain,
    typeAddressId,
    setTypeAddressId,
    getCep,
    getStates,
    statesOfCountry,
    addressTypes,
    getTypesId,
    handleCleanForm,
  } = useAddress();

  useEffect(() => {
    getAddress(companySelected);
    getStates();
    getTypesId();
  }, []);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!addressSelect?.id) {
              handleNewSalve();
            } else {
              handleUpdate();
            }
          }}
        >
          <Grid container>
            {/* Column 1 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <MaskStyle>
                <ItemCustom>
                  <FormLabel sx={{ minWidth: "31%" }}>
                    {translations[param]["CEP"]}:
                  </FormLabel>
                  <ReactInputMask
                    mask="99.999-999"
                    name="cep"
                    placeholder={translations[param]["CEP"]}
                    value={cep}
                    onChange={(e) => {
                      let cep = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                      setCep(cep);

                      if (cep.length === 8) {
                        getCep(cep);
                      }
                    }}
                  />
                </ItemCustom>
              </MaskStyle>
            </Grid>
            {/* Column 2 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustomStart>
                <FormLabel>{translations[param]["type"]}: </FormLabel>
                <Select
                  labelId="show_select_type_address"
                  id="show_select_type_address"
                  value={typeAddressId}
                  required
                  size="small"
                  sx={{ minWidth: "87%", marginLeft: "2%" }}
                  onChange={(event: SelectChangeEvent) => {
                    setTypeAddressId(event.target.value as string);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="select" disabled>
                    Selecione:{" "}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {addressTypes?.map((item: iAddressType, _) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </ItemCustomStart>
            </Grid>
            {/* Column 3 */}
            <Grid xs={12} sm={1} smOffset={3} md={1} lg={1}>
              <ItemCustom>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="main"
                        name="main"
                        size="small"
                        checked={main}
                        onChange={(e) => setMain(e.target.checked)}
                      />
                    }
                    label={translations[param]["main"]}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>

            {/* Column 1 and 2 */}
            <Grid xs={12} sm={8} md={8} lg={8}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "15%" }}>
                  {translations[param]["address"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="address"
                    name="address"
                    label={translations[param]["address"]}
                    required
                    variant="outlined"
                    size="small"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            {/* Column 3 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "20%" }}>
                  {translations[param]["number"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="number"
                    name="number"
                    label={translations[param]["number"]}
                    required
                    variant="outlined"
                    size="small"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </FormControl>
              </ItemCustomStart>
            </Grid>

            {/* Column 1 and 2 */}
            <Grid xs={12} sm={8} md={8} lg={8}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "15%" }}>
                  {translations[param]["complement"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="complement"
                    name="complement"
                    label={translations[param]["complement"]}
                    variant="outlined"
                    size="small"
                    value={complement}
                    onChange={(e) => setComplement(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            {/* Column 3 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "20%" }}>
                  {translations[param]["neighborhood"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="neighborhood"
                    name="neighborhood"
                    label={translations[param]["neighborhood"]}
                    required
                    variant="outlined"
                    size="small"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                  />
                </FormControl>
              </ItemCustomStart>
            </Grid>
            {/* Column 1 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "31%" }}>
                  {translations[param]["city"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="city"
                    name="city"
                    label={translations[param]["city"]}
                    required
                    variant="outlined"
                    size="small"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            {/* Column 2 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustom>
                <FormLabel>{translations[param]["state"]}: </FormLabel>
                <Select
                  labelId="show_select_type_state"
                  id="show_select_type_state"
                  value={state}
                  size="small"
                  sx={{ minWidth: "70%", marginRight: "5%" }}
                  onChange={(event: SelectChangeEvent) => {
                    setState(event.target.value as string);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="select" disabled>
                    {translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {statesOfCountry?.map((item) => (
                    <MenuItem key={item.id} value={item.sigla}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </ItemCustom>
            </Grid>
            {/* Column 3 */}
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "20%" }}>
                  {translations[param]["country"]}:
                </FormLabel>
                <FormControl fullWidth>
                  <Select
                    labelId="show_select_type_country"
                    id="show_select_type_country"
                    value={country}
                    size="small"
                    sx={{ minWidth: "43%" }}
                    onChange={(event: SelectChangeEvent) => {
                      setCountry(event.target.value as string);
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value="select" disabled>
                      {translations[param]["select"]}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    <MenuItem value="BR">Brasil</MenuItem>
                  </Select>
                </FormControl>
              </ItemCustomStart>
            </Grid>
            <Grid xs={4} xsOffset={8} sx={{ marginTop: "1%" }}>
              <Grid display="flex" alignItems="center" justifyContent="end">
                <ButtonClosedForm
                  label="Limpar formulário"
                  onClick={handleCleanForm}
                />
                {addressSelect?.id ? (
                  <ButtonSalveForm label="Alterar" type="submit" />
                ) : (
                  <ButtonInclude label="Incluir" type="submit" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {addresses.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {addresses?.map((data: iAddress) => (
                <TableRow
                  hover
                  key={data.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelected(data)}
                  >
                    {data.address}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.number}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.neighborhood}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.city}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.typeAddress}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.main ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        Swal.fire({
                          title: "Atenção!",
                          text: "O endereço será deletado da base de dados, deseja continuar ? ",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: "Sim, deletar!",
                          cancelButtonText: "Não",
                        }).then((result) => {
                          if (result.isConfirmed) handleDelete(data);
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default Address;
