import useLayout from "src/hooks/useLayout";
import { iAppBar } from "src/interfaces/appBar";
import { stringAvatar } from "src/functions/avatar";
import { AvatarClick, TitleHeader } from "./style";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useLogin from "src/hooks/useLogin";
import { palette } from "src/theme";
import { localStorageStrings } from "src/constants/localStorageStings";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

export default function AppBarComponent() {
  const {
    open,
    handleDrawerOpen,
    drawerWidth,
    openMenu,
    handleClickMenu,
    handleCloseMenu,
    showAvatarUser,
    setOpenDialogLanguage,
  } = useLayout();

  const { logout, getAvatarNameAppBar } = useLogin();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<iAppBar>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        sx={
          process.env.REACT_APP_TITLE === localStorageStrings.titleApp
            ? { backgroundColor: palette.secondary.light }
            : { backgroundColor: palette.primary.main }
        }
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <TitleHeader>
          <Typography variant="h6" noWrap component="div">
            Supply4Med{" "}
            {process.env.REACT_APP_TITLE === localStorageStrings.titleApp &&
              "- HML"}
          </Typography>
          {showAvatarUser && (
            <>
              <AvatarClick onClick={handleClickMenu}>
                <Avatar
                  {...stringAvatar(getAvatarNameAppBar())}
                  id="avatar-button"
                  aria-controls="menu"
                  aria-haspopup="true"
                />
              </AvatarClick>
              <Menu
                id="menu"
                open={openMenu}
                onClose={handleCloseMenu}
                disableAutoFocusItem
                keepMounted={false}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginTop: 5,
                  marginRight: 2,
                }}
              >
                <MenuItem onClick={() => setOpenDialogLanguage(true)}>
                  <TitleHeader>
                    <span style={{ marginRight: 25 }}>
                      {translations[param]["define_language"]}
                    </span>
                  </TitleHeader>
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                  <TitleHeader>
                    <span style={{ marginRight: 25 }}>
                      {translations[param]["logout"]}
                    </span>
                    <LogoutIcon color="error" />
                  </TitleHeader>
                </MenuItem>
              </Menu>
            </>
          )}
        </TitleHeader>
      </Toolbar>
    </AppBar>
  );
}
