import {
  iShoppingProcess,
  iShoppingProcessPage,
} from "src/interfaces/shoppingProcess";
import httpShoppingProcess from "src/http/shoppingProcess";
import ShoppingProcess from "src/models/ShoppingProcess";

class shoppingProcessConsumer {
  static get(
    page: iShoppingProcessPage,
    number?: string,
    description?: string,
    companyId?: string,
    codeItem?: string,
    descriptionItem?: string,
    statusId?: string,
    typeId?: string,
    firstDate?: string,
    endDate?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();

    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    if (number) search.append("NumeroProcesso", number);
    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (description) search.append("Descricao", description);
    if (codeItem) search.append("CodigoProduto", codeItem);
    if (descriptionItem) search.append("DescricaoProduto", descriptionItem);
    if (statusId && statusId !== "select")
      search.append("ProcessoCompraStatusId", statusId);
    if (typeId && typeId !== "select")
      search.append("ProcessoCompraTipoId", typeId);
    if (firstDate) search.append("DataInicio", firstDate);
    if (endDate) search.append("DataFim", endDate);

    if (companyId) {
      const isHaveNumber = companyId.replace(/[^0-9]/g, "");

      if (isHaveNumber && isHaveNumber.length >= 14) {
        search.append("CNPJ", `${isHaveNumber}`);
      } else {
        search.append("RazaoSocial", `${companyId?.toLocaleUpperCase()}`);
      }
    }

    return httpShoppingProcess.get(`/ProcessoCompra?${search.toString()}`);
  }

  static getById(id: string) {
    return httpShoppingProcess.get(`/ProcessoCompra/${id}`);
  }

  static getLastShoppingProccessPhase(id: string) {
    return httpShoppingProcess.get(`/ProcessoCompra/ItensEtapa/${id}`);
  }

  static created(shoppingProcess: iShoppingProcess) {
    return httpShoppingProcess.post(
      `/ProcessoCompra`,
      ShoppingProcess.adapterToBody(shoppingProcess)
    );
  }

  static updated(shoppingProcess: iShoppingProcess) {
    return httpShoppingProcess.put(
      `/ProcessoCompra`,
      ShoppingProcess.adapterToBody(shoppingProcess)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpShoppingProcess.delete(`/ProcessoCompra?id=${id}`);
  }

  static statusPC(body: any) {
    return httpShoppingProcess.post(`/AndamentoStatusPC`, body);
  }

  static getStatusPC({
    shoppingProccessId,
    shoppingProccessPhaseId,
  }: {
    shoppingProccessId?: string;
    shoppingProccessPhaseId?: string;
  }) {
    const search = new URLSearchParams();

    if (shoppingProccessId)
      search.append("ProcessoCompraId", `${shoppingProccessId}`);

    if (shoppingProccessPhaseId)
      search.append("ProcessoCompraEtapaId", `${shoppingProccessPhaseId}`);

    return httpShoppingProcess.get(`/AndamentoStatusPC?${search.toString()}`);
  }
}

export default shoppingProcessConsumer;
