/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";

const ConsolidateDemandContext = createContext<any>({} as any);

export function ConsolidateDemandProvider({ children }: { children: any }) {
  const [openRow, setOpenRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleRowClick = (rowIndex: any) => {
    setOpenRow(openRow === rowIndex ? null : rowIndex);
  };

  const contextValue = useMemo(() => {
    return {
      openRow,
      setOpenRow,
      openDialog,
      setOpenDialog,
      handleRowClick,
    };
  }, [openRow, openDialog]);

  return (
    <ConsolidateDemandContext.Provider value={contextValue}>
      {children}
    </ConsolidateDemandContext.Provider>
  );
}

export default ConsolidateDemandContext;
