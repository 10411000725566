/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import { ItemCustom } from "src/components/ItemCustom";
import Layout from "src/components/Layout";
import TableComponent from "src/components/TableComponent";
import useProductCategoryGeneral from "src/hooks/useProductCategoryGeneral";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { iCategoryProductGeneral } from "src/interfaces/categoryProductGeneral";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import CircularLoading from "src/components/Loading";
import SearchIcon from "@mui/icons-material/Search";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useTable from "src/hooks/useTable";
import RowGroupCategory from "./RowGroupCategory";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

const numberSumWithPage = 40;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ProductCategoryGeneral: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { openDialog, setOpenDialog } = useLayout();
  const { setFieldOrder } = useTable();

  const {
    code,
    setCode,
    description,
    setDescription,
    descriptionSearch,
    setDescriptionSearch,
    productCategoryGeneralSelected,
    handleSalveNewProductCategory,
    handleUpdateProductCategory,
    fatherCategory,
    setFatherCategory,
    productGeneralCategorys,
    productGeneralCategorysToSearch,
    getCategories,
    handleSelect,
    page,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
    pageSearch,
    handleChangePageSearch,
    handleChangeRowsPerPageSearch,
    handleDeleteProductCategory,
    loading,
    loadingSearch,
    getCategoriesFather,
    active,
    setActive,
    orderBy,
    setOrderBy,
    valueTabScreen,
    handleChangeTabScreen,
    pageGroupCategory,
    getForCategoryFather,
    orderByGroupCategory,
    categoriesForCategory,
    handleChangePageGroupCategory,
    handleChangeRowsPerPageGroupCategory,
    setOrderByGroupCategory,
  } = useProductCategoryGeneral();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["product_category"],
    },
  ];

  const header = [
    {
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
    {
      id: "productCategory",
      label: translations[param]["parent_category"],
      isOrder: false,
      order: 3,
    },
    {
      id: "active",
      label: translations[param]["active"],
      isOrder: false,
      order: 4,
    },
  ];

  const headerForCategories = [
    {},
    {
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
  ];

  useEffect(() => {
    handleSelect(null);
    setFieldOrder({
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    });
  }, []);

  useEffect(() => {
    getCategories(0, true);
  }, [page.page, orderBy]);

  useEffect(() => {
    getForCategoryFather();
  }, [pageGroupCategory.page, orderByGroupCategory]);

  useEffect(() => {
    if (openDialog) getCategoriesFather(numberSumWithPage);
  }, [pageSearch]);

  useEffect(() => {
    if (descriptionSearch.length >= 4) {
      getCategoriesFather(numberSumWithPage);
    } else if (descriptionSearch.length === 0 && !openDialog) {
      getCategoriesFather(numberSumWithPage);
    }
  }, [descriptionSearch]);

  return (
    <Layout
      breadcrumbs={breadcrumb}
      buttons={[]}
      callMenu={() =>
        setPage({
          ...page,
          page: 0,
          total: 0,
        })
      }
    >
      <>
        <Card sx={{ padding: 5, width: "100%" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (productCategoryGeneralSelected?.id) {
                handleUpdateProductCategory();
              } else {
                handleSalveNewProductCategory();
              }
            }}
          >
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid xs={12} sm={3} md={3} lg={3}>
                <ItemCustom>
                  <FormLabel sx={{ minWidth: "40%" }}>
                    {translations[param]["code"]}:
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="code"
                      name="code"
                      label={translations[param]["code"]}
                      required
                      variant="outlined"
                      size="small"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </FormControl>
                </ItemCustom>
              </Grid>

              <Grid xs={12} sm={8} md={8} lg={8}>
                <ItemCustom>
                  <FormLabel sx={{ minWidth: "11%" }}>
                    {translations[param]["description"]}:{" "}
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="description"
                      name="description"
                      label={translations[param]["description"]}
                      required
                      variant="outlined"
                      size="small"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </ItemCustom>
              </Grid>

              <Grid xs={1}>
                <FormControl sx={{ marginLeft: 3 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    }
                    label="Ativo"
                  />
                </FormControl>
              </Grid>

              <Grid xs={12}>
                <ItemCustom>
                  <FormLabel sx={{ minWidth: "10%" }}>
                    {translations[param]["parent_category"]}:
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="show_select_code"
                    options={productGeneralCategorysToSearch}
                    value={fatherCategory}
                    sx={{ minWidth: "90%" }}
                    getOptionLabel={(option) =>
                      option?.id ? `${option?.code} - ${option.label}` : ""
                    }
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setFatherCategory(value);
                      } else {
                        setFatherCategory(null);
                      }
                    }}
                    renderInput={(params) =>
                      loadingSearch ? (
                        <CircularLoading />
                      ) : (
                        <TextField
                          {...params}
                          label="Selecione:"
                          size="small"
                          onChange={(e) => setDescriptionSearch(e.target.value)}
                        />
                      )
                    }
                  />
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                </ItemCustom>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ marginTop: 3 }}
            >
              <ButtonClosedForm
                label={translations[param]["clear_form"]}
                onClick={() => handleSelect(null)}
              />
              <FormControl>
                {productCategoryGeneralSelected?.id ? (
                  <ButtonSalveForm
                    label={translations[param]["update"]}
                    type="submit"
                  />
                ) : (
                  <ButtonInclude
                    label={translations[param]["include"]}
                    type="submit"
                  />
                )}
              </FormControl>
            </Grid>
          </form>
        </Card>

        <Grid container spacing={2} sx={{ marginTop: 5 }}>
          <Card sx={{ padding: 2, width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTabScreen}
                  onChange={handleChangeTabScreen}
                  aria-label="tabs products"
                  centered
                  variant="fullWidth"
                >
                  <Tab label="Visão por Tabela" />
                  <Tab label="Visão por Árvore" />
                </Tabs>
              </Box>
            </Box>
            <CustomTabPanel value={valueTabScreen} index={0}>
              {loading ? (
                <CircularLoading />
              ) : productGeneralCategorys.length === 0 ? (
                <Empty />
              ) : (
                <TableComponent
                  header={header}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  funtionToOrder={(item) =>
                    item?.orderBackend &&
                    setOrderBy({
                      number: item?.orderBackend,
                      order: item?.order === "desc" ? true : false,
                    })
                  }
                >
                  {productGeneralCategorys?.map(
                    (data: iCategoryProductGeneral) => (
                      <TableRow
                        hover
                        key={data.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => handleSelect(data)}
                        >
                          {data.code}
                        </TableCell>
                        <TableCell onClick={() => handleSelect(data)}>
                          {data.description}
                        </TableCell>
                        <TableCell onClick={() => handleSelect(data)}>
                          {data.productCategory}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleSelect(data);
                          }}
                        >
                          {data.active ? (
                            <CheckCircleIcon color="success" fontSize="small" />
                          ) : (
                            <DoNotDisturbAltIcon
                              color="error"
                              fontSize="small"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            color="error"
                            fontSize="small"
                            onClick={() =>
                              Swal.fire({
                                title: "Atenção!",
                                text: "A categoria será deletado da base de dados, deseja continuar ? ",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: palette.primary.main,
                                cancelButtonColor: palette.error.main,
                                confirmButtonText: "Sim, deletar!",
                                cancelButtonText: "Não",
                              }).then((result) => {
                                if (result.isConfirmed)
                                  handleDeleteProductCategory(data);
                              })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableComponent>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={valueTabScreen} index={1}>
              <TableComponent
                header={headerForCategories}
                page={pageGroupCategory}
                handleChangePage={handleChangePageGroupCategory}
                handleChangeRowsPerPage={handleChangeRowsPerPageGroupCategory}
                funtionToOrder={(item) =>
                  item?.orderBackend &&
                  setOrderByGroupCategory({
                    number: item?.orderBackend,
                    order: item?.order === "desc" ? true : false,
                  })
                }
              >
                {categoriesForCategory.map((row: any) => (
                  <RowGroupCategory row={row} />
                ))}
              </TableComponent>
            </CustomTabPanel>
          </Card>
        </Grid>
      </>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Categória Pai</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingSearch ? (
            <CircularLoading />
          ) : productGeneralCategorysToSearch?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "Código",
                },
                {
                  label: "Descrição",
                },
              ]}
              page={{
                ...pageSearch,
              }}
              handleChangePage={handleChangePageSearch}
              handleChangeRowsPerPage={handleChangeRowsPerPageSearch}
            >
              {productGeneralCategorysToSearch.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFatherCategory(item.id);
                    setOpenDialog(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault label="Fechar" onClick={() => setOpenDialog(false)} />
        </DialogActions>
      </DialogComponent>
    </Layout>
  );
};

export default ProductCategoryGeneral;
