/* eslint-disable react-hooks/exhaustive-deps */
import {
  Backdrop,
  Button,
  Card,
  Divider,
  FormLabel,
  Grid,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CircularLoading from "src/components/Loading";
import useAttachments from "src/hooks/useAttachments";
import { palette } from "src/theme";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { ButtonSalveForm } from "src/components/ButtonsCommons";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import TableComponent from "src/components/TableComponent";
import Empty from "src/components/Empty";
import { format } from "date-fns";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const header = [
  {
    id: "date",
    label: "Data",
    isOrder: false,
  },
  {
    id: "name",
    label: "Nome do arquivo",
    isOrder: false,
  },
  {
    id: "download",
    label: "Download",
    isOrder: false,
  },
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Attachments: React.FC = () => {
  const {
    loading,
    attachmentField,
    setAttachmentField,
    get,
    post,
    deleted,
    attachments,
    download,
  } = useAttachments();

  const { shoppingProcesSelect } = useShoppingProcess();

  useEffect(() => {
    setAttachmentField(null);

    get(shoppingProcesSelect?.id);
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100,
        }}
        open={loading}
        onClick={() => {}}
      >
        <CircularLoading />
      </Backdrop>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid xs={12} container>
          <Grid item xs={1}>
            <FormLabel>Anexo: </FormLabel>
          </Grid>
          <Grid item xs={10}>
            <Button
              component="label"
              size="medium"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onChange={(event: any) => {
                const file = event.target.files[0];
                setAttachmentField(file);
              }}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              disabled={loading}
            >
              Escolher aquivos
              <VisuallyHiddenInput type="file" />
            </Button>
            <TextField
              size="small"
              id="company"
              sx={{ width: "72%" }}
              value={attachmentField?.name ?? "Nenhum arquivo..."}
              InputProps={{
                sx: {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
                style: {
                  height: 37,
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setAttachmentField(null)}
                  >
                    <CloseOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <ButtonSalveForm
              label="Anexar"
              onClick={() => post(shoppingProcesSelect?.id)}
            />
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ padding: 2, width: "100%", marginTop: 5 }}>
        <Typography gutterBottom variant="h6" component="div">
          Anexos
        </Typography>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 15,
          }}
        />
        {attachments.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={{
              page: 0,
              rowsPerPage: 10,
              total: 10,
            }}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          >
            {attachments.map((value: any) => (
              <TableRow
                hover
                key={value.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  {value.data
                    ? format(new Date(value.data), "dd/MM/yyyy")
                    : "Não informado"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {value.nomeArquivo}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => download(value)}
                >
                  <CloudDownloadOutlinedIcon color="info" />
                </TableCell>
                <TableCell
                  onClick={() =>
                    Swal.fire({
                      title: "Atenção!",
                      text: "O anexo será deletada, deseja continuar ? ",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: palette.primary.main,
                      cancelButtonColor: palette.error.main,
                      confirmButtonText: "Sim, deletar!",
                      cancelButtonText: "Não",
                    }).then((result) => {
                      if (result.isConfirmed) deleted(value.id);
                    })
                  }
                >
                  <DeleteIcon color="error" />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>
    </>
  );
};

export default Attachments;
