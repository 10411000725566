import axios from "axios";
import { localStorageStrings } from "src/constants/localStorageStings";

export const httpClientGovBr = axios.create({
  baseURL: process.env.REACT_APP_URL_CONECT_GOV,
});

httpClientGovBr.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem(
    localStorageStrings.apiTokenSerpros
  );

  if (token) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClientGovBr.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
