import iDocuments from "src/interfaces/documents";
import RpaDocuments from "src/services/rpaDocuments";

export default class Documents implements iDocuments {
  cnpj: string;
  companyId: string;
  typeDocumentId: string;

  constructor(cnpj: string, companyId: string, typeDocumentId: string) {
    this.cnpj = cnpj;
    this.companyId = companyId;
    this.typeDocumentId = typeDocumentId;
  }

  async fgtsRun() {
    try {
      const response = await RpaDocuments.fgtsRun({
        cnpj: this.cnpj,
        companyId: this.companyId,
        typeDocumentId: this.typeDocumentId,
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  async regularityFgtsRun() {
    try {
      const response = await RpaDocuments.regularityFgtsRun({
        cnpj: this.cnpj,
        companyId: this.companyId,
        typeDocumentId: this.typeDocumentId,
      });

      return response;
    } catch (e) {
      return e;
    }
  }
}
