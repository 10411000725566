import {
  iShoppingProcessPhasesItem,
  iShoppingProcessPhasesItemPage,
} from "src/interfaces/shoppingProcessPhasesItem";
import shoppingProcess from "src/http/shoppingProcess";
import ShoppingProcessPhasesItem from "src/models/ShoppingProcessPhasesItem";

class ShoppingProcessPhasesItemConsumer {
  static get(
    page: iShoppingProcessPhasesItemPage,
    shoppingProccessPhaseId: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);
    search.append("ProcessoCompraEtapaId", `${shoppingProccessPhaseId}`);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return shoppingProcess.get(`/ProcessoCompraEtapaItem?${search.toString()}`);
  }

  static created(shoppingProcessPhasesItem: iShoppingProcessPhasesItem) {
    return shoppingProcess.post(
      `/ProcessoCompraEtapaItem`,
      ShoppingProcessPhasesItem.adapterToBody(shoppingProcessPhasesItem)
    );
  }

  static updated(shoppingProcessPhasesItem: iShoppingProcessPhasesItem) {
    return shoppingProcess.put(
      `/ProcessoCompraEtapaItem`,
      ShoppingProcessPhasesItem.adapterToBody(shoppingProcessPhasesItem)
    );
  }

  static deleted(id: string | null | undefined) {
    return shoppingProcess.delete(`/ProcessoCompraEtapaItem?id=${id}`);
  }
}

export default ShoppingProcessPhasesItemConsumer;
