import httpClientImport from "src/http/import";

class importConsumer {
  static upload(body: any, type: string, shoppingProccess?: any) {
    const search = new URLSearchParams();
    search.append("importacaoTipo", type);

    if (shoppingProccess?.id)
      search.append("processoCompraId", shoppingProccess.id);

    return httpClientImport.post(`/Importacao?${search.toString()}`, body);
  }

  static audit(type: string) {
    const search = new URLSearchParams();
    search.append("importacaoTipo", type);

    return httpClientImport.get(`/Auditoria?${search.toString()}`);
  }
}

export default importConsumer;
