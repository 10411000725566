/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  Typography,
  Divider,
  Collapse,
  Box,
} from "@mui/material";
import {
  Add,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { theme } from "src/theme";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";
import DialogComponent from "src/components/Dialog";
import SuggestedItemForm from "./SuggestedItemForm";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import CircularLoading from "src/components/Loading";
import { useEffect } from "react";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";

const ConsolidateDemand: React.FC = () => {
  const { openRow, openDialog, setOpenDialog, handleRowClick } =
    useConsolidateDemand();

  const { get, loading, itemsSuggstionsCreated } = useDemandCollectionForm();

  const { shoppingProcesSelect } = useShoppingProcess();

  const { getDemands, demands } = useShoppingProccessDemand();

  useEffect(() => {
    if (shoppingProcesSelect?.id) {
      get(shoppingProcesSelect.id, true);
      getDemands(shoppingProcesSelect?.id);
    }
  }, []);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="body1">GMV Inicial Esperado</Typography>
          </Grid>
          <Grid item>
            <TextField size="small" label="GMV Inicial Esperado" disabled />
          </Grid>
          <Grid item>
            <Button size="small" variant="contained">
              Enviar E-mail
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="contained">
              Exportar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Demandas</Typography>
        <Divider
          sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main }}
        />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Código</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>GMV Inicial</TableCell>
                <TableCell>
                  <Checkbox />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demands.map((demand: any, index: number) => (
                <>
                  <TableRow
                    key={demand.produtoCodigo}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell onClick={() => handleRowClick(index)}>
                      <IconButton size="small">
                        {openRow === index ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{demand.produtoCodigo}</TableCell>
                    <TableCell>{demand.produtoDescricao}</TableCell>
                    <TableCell>{demand.quantidade}</TableCell>
                    <TableCell>
                      {demand.gmvInicial?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {!demand?.solicitacaoCompras ? (
                            <></>
                          ) : (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>CNPJ</TableCell>
                                  <TableCell>Razão Social</TableCell>
                                  <TableCell>Quantidade</TableCell>
                                  <TableCell>Valor Referência</TableCell>
                                  <TableCell>Valor Alvo</TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {demand?.solicitacaoCompras?.map(
                                  (item: any, subIndex: number) => (
                                    <TableRow key={subIndex}>
                                      <TableCell>
                                        {item?.empresaSolicitacao?.cnpj}
                                      </TableCell>
                                      <TableCell>
                                        {item?.empresaSolicitacao?.razaoSocial}
                                      </TableCell>
                                      <TableCell>{item.quantidade}</TableCell>
                                      <TableCell>
                                        {item.valorReferencia?.toLocaleString(
                                          "pt-br",
                                          {
                                            style: "currency",
                                            currency: "BRL",
                                          }
                                        ) ?? "R$ 0,00"}
                                      </TableCell>
                                      <TableCell>
                                        {item.valorAlvo?.toLocaleString(
                                          "pt-br",
                                          {
                                            style: "currency",
                                            currency: "BRL",
                                          }
                                        ) ?? "R$ 0,00"}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton size="small">
                                          <Close color="error" />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Botão Encaminhar para Negociação */}
      <Grid item xs={12} container justifyContent="flex-end">
        <Button variant="contained" color="primary" size="small">
          Encaminhar para Negociação
        </Button>
      </Grid>

      {/* Título da tabela de Itens Sugeridos */}
      <Grid item xs={12}>
        <Typography variant="h6">Itens Sugeridos</Typography>
        <Divider
          sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.main }}
        />
      </Grid>

      <Grid item xs={12}>
        {/* Tabela de Itens Sugeridos */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>CNPJ</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell>Código Item</TableCell>
                <TableCell>Descrição Item</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Valor Referência</TableCell>
                <TableCell>Valor Alvo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsSuggstionsCreated.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{item.cnpjEmpresa}</TableCell>
                  <TableCell>{item.nomeFantasiaEmpresa}</TableCell>
                  <TableCell>{item.codigoProduto}</TableCell>
                  <TableCell>{item.descricaoProduto}</TableCell>
                  <TableCell>{item.quantidade}</TableCell>
                  <TableCell>
                    {parseFloat(item.valorReferencia)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) ?? "0,00"}
                  </TableCell>
                  <TableCell>
                    {parseFloat(item.valorAlvo)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) ?? "0,00"}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "100px",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setOpenDialog(true)}
                    >
                      <Add color="primary" />
                    </IconButton>
                    <IconButton size="small">
                      <Close color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <SuggestedItemForm />
      </DialogComponent>
    </Grid>
  );
};

export default ConsolidateDemand;
