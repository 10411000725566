import styled from "styled-components";

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

export const DivMessageAlert = styled.div`
  width: 30%;
  float: right;
`;

export const DivContent = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ButtonsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const LayoutBreadCrumbAndButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LayoutdButton = styled.div`
  margin-right: 10px;
`;

export const BreadcrumbLink = styled.div`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
`;
