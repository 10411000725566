/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useLayout from "src/hooks/useLayout";
import useInvoices from "src/hooks/useInvoices";
import { palette } from "src/theme";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import useRequest from "src/hooks/useRequest";
import { MaskStyle } from "src/styles/globalStyle";
import {
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import useInvoiceitems from "src/hooks/useInvoiceitems";
import { iInvoicesItems } from "src/interfaces/invoicesItem";
import DialogComponent from "src/components/Dialog";
import CretaeorUpdateInvoiceItem from "../CretaeorUpdateInvoiceItem";
import { formatCurrency } from "src/functions/text";
import { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { addDays, format } from "date-fns";

const header = [
  {
    id: "",
    label: "Código",
    isOrder: false,
  },
  {
    id: "",
    label: "Descrição",
    isOrder: false,
  },
  {
    id: "",
    label: "Unidade",
    isOrder: false,
  },
  {
    id: "",
    label: "Quantidade",
    isOrder: false,
  },
  {
    id: "",
    label: "Valor Unitário",
    isOrder: false,
  },
  {
    id: "",
    label: "Valor Total",
    isOrder: false,
  },
];

const CreateOrUpdateInvoices: React.FC = () => {
  const { setOpenDialog, openDialogSecondary, setOpenDialogSecondary, width } =
    useLayout();

  const {
    handleSelect,
    invoiceSelect,
    handleNewSalve,
    handleUpdate,
    number,
    setNumber,
    registerDate,
    setRegisterDate,
    value,
    netValue,
    setNetValue,
    paymentPrevisionDate,
    setPaymentPrevisionDate,
    setEmissor,
    setTomador,
  } = useInvoices();

  const {
    invoiceItems,
    loading,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelect: handleSelectItems,
    getInvoiceItems,
    handleDelete,
  } = useInvoiceitems();

  const { requestSelect } = useRequest();

  useEffect(() => {
    setEmissor(requestSelect?.supplier?.id);
    setTomador(requestSelect?.buyer?.id);

    if (invoiceSelect?.id) {
      getInvoiceItems();
    }
  }, [invoiceSelect]);

  useEffect(() => {
    if (!openDialogSecondary) handleSelectItems(null);
  }, [openDialogSecondary]);

  return (
    <>
      <DialogTitle id="alert-dialog-title">Notas Fiscais</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialog(false);
          handleSelect(null);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (!invoiceSelect?.id) {
            handleNewSalve(requestSelect?.buyerId);
          } else {
            handleUpdate(requestSelect?.buyerId);
          }
        }}
      >
        <DialogContent>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid xs={12}>
              <ItemCustom>
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "9%" }}>Número *</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    value={number}
                    placeholder="Número da nota fiscal"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12}>
              <ItemCustom>
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "9%" }}>Emissor *</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    disabled
                    value={requestSelect?.supplier?.razaoSocial}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12}>
              <ItemCustom>
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "9%" }}>Tomador *</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    disabled
                    value={requestSelect?.buyer?.razaoSocial}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} container>
              <Grid xs={12} sm={6} md={6}>
                <ItemCustomStart>
                  {width >= 1400 ? (
                    <FormLabel sx={{ minWidth: "18%" }}>
                      Data emissão *
                    </FormLabel>
                  ) : null}
                  <FormControl fullWidth>
                    <MaskStyle>
                      <input
                        type="date"
                        name="end_date"
                        min="1970-01-01"
                        required
                        value={registerDate}
                        onChange={(e) => {
                          setRegisterDate(e.target.value);
                          setPaymentPrevisionDate(
                            format(
                              addDays(
                                new Date(e.target.value),
                                requestSelect?.paymentCondition?.numeroDias + 1
                              ),
                              "yyyy-MM-dd"
                            )
                          );
                        }}
                      />
                    </MaskStyle>
                  </FormControl>
                </ItemCustomStart>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <ItemCustomStart>
                  <FormLabel sx={{ minWidth: "25%" }}>
                    Data de previsão de pagamento
                  </FormLabel>
                  <FormControl fullWidth>
                    <MaskStyle>
                      <input
                        type="date"
                        name="end_date"
                        min="1970-01-01"
                        value={paymentPrevisionDate}
                        onChange={(e) =>
                          setPaymentPrevisionDate(e.target.value)
                        }
                      />
                    </MaskStyle>
                  </FormControl>
                </ItemCustomStart>
              </Grid>
            </Grid>
            <Grid xs={12} container>
              <Grid xs={12} sm={6} md={6}>
                <ItemCustom>
                  {width >= 1400 ? (
                    <FormLabel sx={{ minWidth: "18%" }}>Valor</FormLabel>
                  ) : null}
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      placeholder={width >= 1400 ? "R$ 0,00" : "Valor: R$ 0,00"}
                      value={
                        value?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "R$ 0,00"
                      }
                      disabled
                    />
                  </FormControl>
                </ItemCustom>
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <ItemCustom>
                  {width >= 1400 ? (
                    <FormLabel sx={{ minWidth: "25%" }}>
                      Valor Líquido
                    </FormLabel>
                  ) : null}
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      placeholder={
                        width >= 1400 ? "R$ 0,00" : "Valor Líquido: R$ 0,00"
                      }
                      value={
                        netValue?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "R$ 0,00"
                      }
                      onChange={(e) => {
                        e = formatCurrency(e);
                        setNetValue(e.target.value);
                      }}
                    />
                  </FormControl>
                </ItemCustom>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <FormLabel>Itens</FormLabel>
            <Divider
              sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
            />
          </Grid>
          <Grid>
            <ButtonInclude
              label="Incluir"
              onClick={() => {
                if (!invoiceSelect?.id) {
                  handleNewSalve(requestSelect?.buyerId);
                } else {
                  setOpenDialogSecondary(true);
                }
              }}
            />
          </Grid>
          <Grid>
            {loading ? (
              <CircularLoading />
            ) : invoiceItems?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {invoiceItems?.map((data: iInvoicesItems) => (
                  <TableRow
                    hover
                    key={data.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {data.productCompany?.codigoProdutoEmpresa ??
                        "Não informado"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {data?.productCompany?.descricao ?? "Não informado"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {data?.productCompany?.unidade ?? "Não informado"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {data?.quantity}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {parseFloat(data?.value)?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }) ?? "0, 00"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelectItems(data);
                        setOpenDialogSecondary(true);
                      }}
                    >
                      {(
                        parseFloat(data.value) * parseInt(data.quantity)
                      )?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }) ?? "0, 00"}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <IconButton
                        onClick={() =>
                          Swal.fire({
                            title: "Cuidado!",
                            text: `O item será deletado, deseja continuar ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: palette.primary.main,
                            cancelButtonColor: palette.error.main,
                            confirmButtonText: "Sim",
                            cancelButtonText: "Não",
                          }).then((result) => {
                            if (result.isConfirmed) handleDelete(data);
                          })
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialog(false);
              handleSelect(null);
            }}
          />
          <ButtonSalveForm label="Salvar" type="submit" />
        </DialogActions>
      </form>

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <CretaeorUpdateInvoiceItem />
      </DialogComponent>
    </>
  );
};

export default CreateOrUpdateInvoices;
