/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { addHours, format } from "date-fns";
import { useEffect } from "react";
import {
  ButtonDelete,
  ButtonInclude,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import DialogComponent from "src/components/Dialog";
import Empty from "src/components/Empty";
import { ItemCustom } from "src/components/ItemCustom";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useInvoices from "src/hooks/useInvoices";
import { iInvoices } from "src/interfaces/invoices";
import { MaskStyle } from "src/styles/globalStyle";
import { palette } from "src/theme";
import CreateOrUpdateInvoices from "./CreateOrUpdateInvoices";
import useLayout from "src/hooks/useLayout";
import useRequest from "src/hooks/useRequest";
import Swal from "sweetalert2";
import useTable from "src/hooks/useTable";

const header = [
  {
    id: "",
    label: "Número NF",
    isOrder: false,
    order: 1,
  },
  {
    id: "",
    label: "Empresa",
    isOrder: false,
    order: 2,
  },
  {
    id: "",
    label: "Data Emissão",
    isOrder: false,
    order: 3,
  },
  {
    id: "",
    label: "Valor Total",
    isOrder: false,
    order: 4,
  },
];

const Invoices: React.FC = () => {
  const {
    openDialog,
    setOpenDialog,
    disableButtons,
    setDisableButtons,
    width,
  } = useLayout();

  const { setFieldOrder } = useTable();

  const {
    loading,
    number,
    setNumber,
    value,
    setValue,
    registerDate,
    setRegisterDate,
    endDate,
    setEndDate,
    invoices,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getInvoices,
    handleSelect,
    invoiceSelect,
    setInvoiceSelect,
    handleDelete,
    setInvoices,
    orderBy,
    setOrderBy,
  } = useInvoices();

  const { requestSelect } = useRequest();

  useEffect(() => {
    setInvoices([]);
    setFieldOrder({
      id: "",
      label: "Número NF",
      isOrder: false,
      order: 1,
    });
  }, []);

  useEffect(() => {
    if (!openDialog) getInvoices(requestSelect!);
  }, [openDialog, orderBy]);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          <ItemCustom display="flex" alignItems="center">
            {width >= 1400 ? (
              <FormLabel sx={{ minWidth: "10%" }}>Pedido</FormLabel>
            ) : null}
            <TextField
              fullWidth
              size="small"
              id="request"
              name="request"
              value={requestSelect?.number}
              variant="outlined"
              disabled
            />
          </ItemCustom>

          <ItemCustom display="flex" alignItems="center">
            {width >= 1400 ? (
              <FormLabel sx={{ minWidth: "10%" }}>Número NF</FormLabel>
            ) : null}
            <TextField
              fullWidth
              size="small"
              id="number"
              name="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              label="Número da NF"
              variant="outlined"
            />
          </ItemCustom>

          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid xs={12} sm={6}>
              <ItemCustom display="flex" alignItems="center">
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "20%" }}>Valor NF</FormLabel>
                ) : null}
                <TextField
                  fullWidth
                  size="small"
                  id="number"
                  name="number"
                  value={value?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  onChange={(e) => setValue(e.target.value)}
                  label="R$ ##.###,00"
                  variant="outlined"
                />
              </ItemCustom>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <ItemCustom>
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "20%" }}>De:</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <MaskStyle>
                    <input
                      type="date"
                      name="begin_date"
                      placeholder="dd/mm/yyyy"
                      value={registerDate}
                      onChange={(e) => setRegisterDate(e.target.value)}
                      min="1970-01-01"
                    />
                  </MaskStyle>
                </FormControl>
              </ItemCustom>
              <ItemCustom>
                {width >= 1400 ? (
                  <FormLabel sx={{ minWidth: "20%" }}>Até:</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <MaskStyle>
                    <input
                      type="date"
                      name="end_date"
                      placeholder="dd/mm/yyyy"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      min="1970-01-01"
                    />
                  </MaskStyle>
                </FormControl>
              </ItemCustom>
            </Grid>
          </Grid>

          <Grid
            display="flex"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: "15px" }}
          >
            <ButtonSearch
              label="Pesquisar"
              onClick={() => getInvoices(requestSelect!)}
            />
          </Grid>
        </Card>

        <Card sx={{ padding: 2, marginTop: 3, width: "100%" }}>
          <FormLabel>Notas Fiscais</FormLabel>
          <Divider
            sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
          />

          <Grid display="flex" justifyContent="start" alignItems="center">
            <ButtonInclude
              label="Incluir"
              onClick={() => {
                handleSelect(null);
                setOpenDialog(true);
              }}
              disabled={!disableButtons}
            />
            <ButtonDelete
              label="Excluir"
              disabled={disableButtons}
              onClick={() =>
                Swal.fire({
                  title: "Atenção!",
                  text: "A nota será deletada, deseja continuar ? ",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: palette.primary.main,
                  cancelButtonColor: palette.error.main,
                  confirmButtonText: "Sim",
                  cancelButtonText: "Não",
                }).then((result) => {
                  if (result.isConfirmed) handleDelete(invoiceSelect);
                })
              }
            />
          </Grid>

          <Grid>
            {loading ? (
              <CircularLoading />
            ) : invoices?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                funtionToOrder={(item) =>
                  item?.orderBackend &&
                  setOrderBy({
                    number: item?.orderBackend,
                    order: item?.order === "asc" ? true : false,
                  })
                }
              >
                {invoices?.map((data: iInvoices) => (
                  <TableRow
                    hover
                    key={data.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      onClick={() => {
                        handleSelect(data);
                        setOpenDialog(true);
                      }}
                    >
                      {data.number}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(data);
                        setOpenDialog(true);
                      }}
                    >
                      {requestSelect?.supplier?.razaoSocial}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(data);
                        setOpenDialog(true);
                      }}
                    >
                      {data.emissionDate
                        ? format(
                            addHours(new Date(data.emissionDate), 3),
                            "dd/MM/yyy"
                          )
                        : null}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(data);
                        setOpenDialog(true);
                      }}
                    >
                      {parseFloat(`${data.value}`).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={data?.number === invoiceSelect?.number}
                        size="small"
                        onChange={(e) => {
                          if (!e.target.checked) {
                            setInvoiceSelect(null);
                            setDisableButtons(true);
                          }
                        }}
                        onClick={() => {
                          setInvoiceSelect(data);
                          setDisableButtons(false);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Card>
      </Grid>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <CreateOrUpdateInvoices />
      </DialogComponent>
    </>
  );
};

export default Invoices;
