/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { ButtonInclude } from "src/components/ButtonsCommons";
import SearchRequest from "src/pages/Requests/Search";
import Table from "src/pages/Requests/Table";
import { palette } from "src/theme";
import translations from "src/_i18n/translations.json";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useRequest from "src/hooks/useRequest";
import useShoppingProcess from "src/hooks/useShoppingProcess";

const Requests: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { page, getRequests, shoppingProccessId, setRequests } = useRequest();
  const { shoppingProcesSelect } = useShoppingProcess();

  const fetchData = useCallback(async () => {
    getRequests();
  }, [shoppingProccessId]);

  useEffect(() => {
    fetchData();
  }, [page.page, fetchData]);

  useEffect(() => {
    return () => {
      setRequests([]);
    };
  }, []);

  return (
    <>
      <SearchRequest shoppingProccessId={shoppingProcesSelect?.id} />
      <Typography gutterBottom component="div" sx={{ marginTop: 5 }}>
        Pedidos
      </Typography>
      <Divider
        style={{
          backgroundColor: palette.primary.main,
          marginBottom: 15,
        }}
      />
      <ButtonInclude
        label={translations[param]["include"]}
        onClick={() => router(`/${lang}/request/register`)}
      />
      <Table />
    </>
  );
};

export default Requests;
