/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { palette } from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import useLayout from "src/hooks/useLayout";
import useRatePCItem from "src/hooks/useRatePCItem";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import { MaskStyle } from "src/styles/globalStyle";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { iRatePCItem } from "src/interfaces/rate";
import { addHours, format } from "date-fns";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";

const header = [
  {
    label: "Descrição",
  },
  {
    label: "Valor taxa",
  },
  {
    label: "Data Inicio",
  },
  {
    label: "Data Fim",
  },
];

const RatePCITem: React.FC = () => {
  const { setOpenDialogThree } = useLayout();
  const { shoppingProcesItemSelect, shoppingProcessItem } =
    useShoppingProcessItem();

  const { shoppingProccessRates, getTaxaPc } = useShoppingProccessRate();

  const {
    handleSelected,
    ratePCItemSelect,
    handleUpdate,
    handleNewSalve,
    processoCompraItensId,
    setProcessoCompraItensId,
    taxaPCId,
    setTaxaPCId,
    valorTaxa,
    setValorTaxa,
    dataInicio,
    setDataInicio,
    dataFim,
    setDataFim,
    loading,
    ratesPCItem,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDelete,
    getRatesPCItem,
  } = useRatePCItem();

  useEffect(() => {
    getRatesPCItem(shoppingProcesItemSelect?.id ?? "");
    getTaxaPc();

    setProcessoCompraItensId(shoppingProcesItemSelect?.id ?? "");
  }, []);

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Gestão de taxas vinculadas
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialogThree(false);
          handleSelected(null);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!ratePCItemSelect?.id) {
              handleNewSalve(shoppingProcesItemSelect?.id ?? "");
            } else {
              handleUpdate(shoppingProcesItemSelect?.id ?? "");
            }
          }}
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "8%" }}>Item: </FormLabel>
                <FormControl fullWidth>
                  <Select
                    labelId="show_select_type"
                    id="show_select_type"
                    value={processoCompraItensId}
                    size="small"
                    disabled
                  >
                    <MenuItem value="select" disabled>
                      Selecione:{" "}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {shoppingProcessItem.map((item) => (
                      <MenuItem key={item.id} value={`${item.id}`}>
                        {item.codeProduct} - {item.descrptionProduct}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={8} md={8}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "12%" }}>Taxa: </FormLabel>
                <FormControl fullWidth>
                  <Select
                    labelId="show_select_type"
                    id="show_select_type"
                    value={taxaPCId}
                    size="small"
                    onChange={(event: SelectChangeEvent) =>
                      setTaxaPCId(event.target.value as string)
                    }
                  >
                    <MenuItem value="select" disabled>
                      Selecione:{" "}
                    </MenuItem>
                    <MenuItem value="">...</MenuItem>
                    {shoppingProccessRates.map((rate) => (
                      <MenuItem key={rate.id} value={`${rate.id}`}>
                        {rate.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <ItemCustom>
                <FormLabel sx={{ width: "50%" }}>Valor Taxa (%): </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    id="monthNumber"
                    name="monthNumber"
                    label="Valor Taxa (%)"
                    variant="outlined"
                    type="number"
                    value={valorTaxa}
                    onChange={(e) => setValorTaxa(parseInt(e.target.value))}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={3} md={3}>
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "33%" }}>Data Inicio:</FormLabel>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={dataInicio}
                    onChange={(e) => setDataInicio(e.target.value)}
                  />
                </MaskStyle>
              </ItemCustomStart>
            </Grid>
            <Grid
              xs={12}
              sm={3}
              smOffset={2}
              md={3}
              display="flex"
              justifyContent="end"
            >
              <ItemCustomStart>
                <FormLabel sx={{ minWidth: "35%" }}>Data Fim:</FormLabel>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={dataFim}
                    onChange={(e) => setDataFim(e.target.value)}
                  />
                </MaskStyle>
              </ItemCustomStart>
            </Grid>

            <Grid
              xs={12}
              sm={4}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              <ButtonClosedForm
                label="Limpar formulário"
                onClick={() => handleSelected(null)}
              />
              {ratePCItemSelect?.id ? (
                <ButtonSalveForm
                  label="Alterar"
                  colorIcon="success"
                  onClick={() =>
                    handleUpdate(shoppingProcesItemSelect?.id ?? "")
                  }
                />
              ) : (
                <ButtonSalveForm
                  label="Incluir"
                  colorIcon="success"
                  onClick={() =>
                    handleNewSalve(shoppingProcesItemSelect?.id ?? "")
                  }
                />
              )}
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ backgroundColor: palette.primary.main, marginY: 5 }} />
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid xs={12} sm={12} md={12}>
            {loading ? (
              <CircularLoading />
            ) : ratesPCItem.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {ratesPCItem.map((item: iRatePCItem) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleSelected(item)}
                    >
                      {item.taxa}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleSelected(item)}
                    >
                      {item.valorTaxa}%
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleSelected(item)}
                    >
                      {item.dataInicio
                        ? format(
                            addHours(new Date(item.dataInicio), 3),
                            "dd/MM/yyyy"
                          )
                        : null}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => handleSelected(item)}
                    >
                      {item.dataFim
                        ? format(
                            addHours(new Date(item.dataFim), 3),
                            "dd/MM/yyyy"
                          )
                        : null}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        Swal.fire({
                          title: "Atenção!",
                          text: "A taxa será deletado, deseja continuar ? ",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: "Sim, deletar!",
                          cancelButtonText: "Não",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDelete(
                              item,
                              shoppingProcesItemSelect?.id ?? ""
                            );
                          }
                        })
                      }
                    >
                      <DeleteIcon color="error" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "5%" }}>
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            handleSelected(null);
            setOpenDialogThree(false);
          }}
        />
      </DialogActions>
    </>
  );
};

export default RatePCITem;
