import httpClientRequest from "src/http/request";
import { iPage } from "src/interfaces/layout";
import { iRequests } from "src/interfaces/requests";
import Requests from "src/models/Requests";

class requestConsumer {
  static get(
    page: iPage,
    statusFilter?: string,
    proccessNumberFilter?: string,
    descriptionFilter?: string,
    requestNumberFilter?: string,
    companyFilter?: string,
    NfFilter?: string,
    codeItemFilter?: string,
    descriptionItemFilter?: string,
    startDateFilter?: string,
    endDateFilter?: string,
    withShoppingProccess?: boolean,
    shoppingProccessId?: string,
    orderBy?: any,
    typeFilterDate?: any,
    startDateFilterNf?: string,
    endDateFilterNf?: string
  ) {
    const search = new URLSearchParams();
    search.append("Pagina", `${page.page}`);
    search.append("TamanhoPagina", page.rowsPerPage.toPrecision());

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (statusFilter && statusFilter !== "select")
      search.append("Status", `${statusFilter}`);
    if (proccessNumberFilter)
      search.append("NumeroProcesso", `${proccessNumberFilter}`);
    if (descriptionFilter)
      search.append("DescricaoProcesso", `${descriptionFilter}`);
    if (requestNumberFilter)
      search.append("NumeroPedido", `${requestNumberFilter}`);
    if (NfFilter) search.append("NumeroNotaFiscal", `${NfFilter}`);
    if (codeItemFilter) search.append("CodigoItem", `${codeItemFilter}`);
    if (descriptionItemFilter)
      search.append("DescricaoItem", `${descriptionItemFilter}`);
    if (withShoppingProccess) search.append("ComProcessoDeCompra", `${false}`);
    if (shoppingProccessId) {
      search.append("ProcessoCompraId", `${shoppingProccessId}`);
    }

    if (typeFilterDate && typeFilterDate === "emission") {
      if (startDateFilter)
        search.append("DataEmissaoInicial", `${startDateFilter}`);
      if (endDateFilter) search.append("DataEmissaoFinal", `${endDateFilter}`);
    } else if (typeFilterDate && typeFilterDate === "emissonNF") {
      if (startDateFilterNf)
        search.append("DataEmissaoNFInicial", `${startDateFilterNf}`);
      if (endDateFilterNf)
        search.append("DataEmissaoNFFinal", `${endDateFilterNf}`);
    }

    if (companyFilter) {
      const isHaveNumber = companyFilter.replace(/[^0-9]/g, "");

      if (isHaveNumber) {
        search.append("CNPJ", `${isHaveNumber}`);
      } else {
        search.append("NomeFantasia", `${companyFilter}`);
      }
    }

    return httpClientRequest.get(`/Pedido?${search.toString()}`);
  }

  static created(request: iRequests) {
    return httpClientRequest.post(`/Pedido`, Requests.adapterToBody(request));
  }

  static updated(request: iRequests) {
    return httpClientRequest.put(
      `/Pedido/${request.id}`,
      Requests.adapterToBody(request)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRequest.delete(`/Pedido/${id}`);
  }

  static statusRequest(body: any) {
    return httpClientRequest.post(`/AndamentoStatusPedido`, body);
  }

  static requestListLink(body: any[]) {
    return httpClientRequest.put(`/Pedido/lista`, {
      listaVinculoPedidoProcessoCompras: body,
    });
  }
}

export default requestConsumer;
