import { iShoppingProccessStatus } from "src/interfaces/shoppingProccessStatus";

export default class ShoppingProccessStatus implements iShoppingProccessStatus {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    this.id = id;
    this.description = description;
  }

  public static adapterToClass(shoppingProccessStatus: any) {
    return new ShoppingProccessStatus(
      shoppingProccessStatus.id,
      shoppingProccessStatus.descricao
    );
  }
}
