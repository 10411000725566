/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iShoppingProcessType,
  iShoppingProcessTypeContextProps,
  iShoppingProcessPageType,
} from "src/interfaces/shoppingProcessType";
import shoppingProcessConsumerType from "src/services/shoppingProcessType";
import ShoppingProcess from "src/models/ShoppingProcess";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import shoppingProcessPhaseTypeConsumer from "src/services/shoppingProcessPhaseType";

const ShoppingProcessTypeContext =
  createContext<iShoppingProcessTypeContextProps>(
    {} as iShoppingProcessTypeContextProps
  );

export function ShoppingProcessTypeProvider({ children }: { children: any }) {
  const { disableButtons, setDisableButtons } = useLayout();

  const [loading, setLoading] = useState<boolean>(false);
  const [shoppingProcessTypeToSearch, setShoppingProcessTypeToSearch] =
    useState<Array<any>>([]);
  const [shoppingProcessType, setShoppingProcessType] = useState<
    Array<iShoppingProcessType>
  >([]);
  const [shoppingProcessPhaseType, setShoppingProcessPhaseType] = useState<
    Array<any>
  >([]);
  const [page, setPage] = useState<iShoppingProcessPageType>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [shoppingProcesTypeSelect, setShoppingProcesTypeSelect] =
    useState<iShoppingProcessType | null>(null);
  const [description, setDescription] = useState<string>("");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProcessPhaseType = async () => {
    setLoading(true);
    try {
      const response = await shoppingProcessPhaseTypeConsumer.get(page);

      if (response.status !== 200) throw response;

      setShoppingProcessPhaseType(response?.data?.items);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os tipos das etapas do processos de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getShoppingProcessType = async () => {
    setLoading(true);
    try {
      const response = await shoppingProcessConsumerType.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcess.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setShoppingProcessType(data);
      setShoppingProcessTypeToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item.id,
            label: item.descricao,
          };
        })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os tipos de processos de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (shoppingProcessType: iShoppingProcessType | null) => {
    setShoppingProcesTypeSelect(shoppingProcessType);
    setDescription(shoppingProcessType?.description ?? "");
  };

  const handleSelectedCheckBox = (
    shoppingProcessType: iShoppingProcessType | null
  ) => {
    if (shoppingProcessType?.id === shoppingProcesTypeSelect?.id) {
      setDisableButtons(true);
      return setShoppingProcesTypeSelect(null);
    }

    setDisableButtons(false);
    setShoppingProcesTypeSelect(shoppingProcessType);
  };

  const handleDelete = async (shoppingProcessType: iShoppingProcessType) => {
    try {
      setLoading(true);

      const response = await shoppingProcessConsumerType.deleted(
        `${shoppingProcessType?.id}`
      );

      if (response.status !== 200) throw response;

      toast.success("Tipo de processo de compra deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      getShoppingProcessType();
    } catch {
      toast.error("Erro ao deletar o tipo de processo de compra!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getShoppingProcessType,
      loading,
      setLoading,
      shoppingProcessType,
      handleChangePage,
      handleChangeRowsPerPage,
      shoppingProcesTypeSelect,
      setShoppingProcesTypeSelect,
      description,
      setDescription,
      handleSelected,
      handleDelete,
      disableButtons,
      handleSelectedCheckBox,
      shoppingProcessTypeToSearch,
      getShoppingProcessPhaseType,
      shoppingProcessPhaseType,
    };
  }, [
    page,
    loading,
    shoppingProcessType,
    description,
    disableButtons,
    shoppingProcessPhaseType,
  ]);

  return (
    <ShoppingProcessTypeContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessTypeContext.Provider>
  );
}

export default ShoppingProcessTypeContext;
