export const localStorageStrings = {
  dataLoginUserSalveInNavegator: "dataLoginUserSalveInNavegator",
  apiToken: "api-token",
  apiTokenSerpros: "api-token-serpros",
  refreshToken: "refresh-token",
  userLogged: "user-logged",
  reloaded: "reloaded",
  titleApp: "Gestão_HML - Supply4Med",
  hoursRefreshToken: "hours-refresh-token",
};
