import shoppingProcess from "src/http/shoppingProcess";
import { iPage } from "src/interfaces/layout";

class ShoppingProcessDemandItemsConsumer {
  static get(page: iPage, shoppingProccessId: string, orderBy?: any) {
    const search = new URLSearchParams();
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", `${page.rowsPerPage}`);
    search.append("ProcessoCompraid", shoppingProccessId);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    return shoppingProcess.get(
      `/ProcessoCompraDemandaItens?${search.toString()}`
    );
  }

  static created(body: any) {
    return shoppingProcess.post(`/ProcessoCompraDemandaItens`, body);
  }

  static updated(body: any) {
    return shoppingProcess.put(`/ProcessoCompraDemandaItens`, body);
  }

  static delete(id: string) {
    return shoppingProcess.delete(`/ProcessoCompraDemandaItens?Id=${id}`);
  }
}

export default ShoppingProcessDemandItemsConsumer;
