import httpClientRegister from "src/http/register";
import { iPage } from "src/interfaces/layout";

class Documents {
  static async getTypes({ page }: { page: iPage }) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());

    const response = await httpClientRegister.get(
      `/DocumentosTipo?${search.toString()}`
    );

    return response;
  }

  static async getDocuments({ empresaId }: { empresaId: string }) {
    const search = new URLSearchParams();
    search.append("EmpresaId", empresaId);

    const response = await httpClientRegister.get(
      `/DocumentosEmpresas?${search.toString()}`
    );

    return response;
  }

  static async downloadDocumentById({ documentoId }: { documentoId: string }) {
    const response = await httpClientRegister.get(
      `/DocumentosEmpresas/${documentoId}`
    );

    return response;
  }

  static async uploadDocument({ body }: { body: any }) {
    const response = await httpClientRegister.post(`/DocumentosEmpresas`, body);

    return response;
  }
}

export default Documents;
