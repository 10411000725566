/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useInvoiceitems from "src/hooks/useInvoiceitems";
import useLayout from "src/hooks/useLayout";
import CloseIcon from "@mui/icons-material/Close";
import { palette } from "src/theme";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useRequestItem from "src/hooks/useRequestItem";
import { useEffect } from "react";
import useRequest from "src/hooks/useRequest";
import { ItemCustom } from "src/components/ItemCustom";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import SearchIcon from "@mui/icons-material/Search";
import useProductCompany from "src/hooks/useProductCompany";
import CircularLoading from "src/components/Loading";
import DialogComponent from "src/components/Dialog";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";

const CretaeorUpdateInvoiceItem: React.FC = () => {
  const { setOpenDialogSecondary, openDialogThree, setOpenDialogThree } =
    useLayout();
  const {
    handleSelect,
    itemRequestInvoice,
    setItemRequestInvoice,
    codeProduct,
    setCodeProduct,
    descriptionProduct,
    setDescriptionProduct,
    unitProduct,
    setUnitProduct,
    quantity,
    setQuantity,
    value,
    setValue,
    handleNewSalve,
    handleUpdate,
    productCompanyItem,
    setProductCompanyItem,
    loading,
    invoiceItemSelect,
  } = useInvoiceitems();
  const { itemsToSearch, getRequestItem, setRequestItems } = useRequestItem();
  const { requestSelect } = useRequest();
  const {
    productsToSearch,
    description,
    setDescription,
    getProducts,
    page: pageProductCompany,
    loading: loadingProductCompany,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useProductCompany();

  useEffect(() => {
    if (requestSelect?.id) {
      getRequestItem(requestSelect.id);
    } else {
      setRequestItems([]);
    }
  }, []);

  useEffect(() => {
    searchProductCompany();
  }, [description]);

  const searchProductCompany = () => {
    if (description.length >= 4) {
      getProductsCompany();
    } else if (description.length === 0) {
      getProductsCompany();
    }
  };

  const getProductsCompany = () =>
    getProducts(
      `${requestSelect?.supplierId}`,
      pageProductCompany.page,
      pageProductCompany.rowsPerPage,
      false
    );

  if (loading)
    return (
      <>
        <DialogTitle id="alert-dialog-title">Item de Notas Fiscais</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogSecondary(false);
            handleSelect(null);
            setItemRequestInvoice(null);
            setProductCompanyItem(null);
            setCodeProduct("");
            setDescriptionProduct("");
            setUnitProduct("");
            setQuantity("");
            setValue("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ backgroundColor: palette.primary.main }} />
        <CircularLoading />
      </>
    );

  return (
    <>
      <DialogTitle id="alert-dialog-title">Item de Notas Fiscais</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setOpenDialogSecondary(false);
          handleSelect(null);
          setItemRequestInvoice(null);
          setProductCompanyItem(null);
          setCodeProduct("");
          setDescriptionProduct("");
          setUnitProduct("");
          setQuantity("");
          setValue("");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
      <DialogContent>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid xs={12}>
            <ItemCustom>
              <FormLabel sx={{ width: "10%" }}>Item do pedido</FormLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="show_select_code"
                  options={itemsToSearch}
                  value={itemRequestInvoice}
                  getOptionLabel={(option) =>
                    `${option?.code} - ${option?.label}`
                  }
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setQuantity(value?.quantidade ?? 0);
                      setValue(value?.valorUnitario ?? 0);
                      setItemRequestInvoice(value);
                    } else {
                      setQuantity("");
                      setValue("");
                      setItemRequestInvoice(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione:" size="small" />
                  )}
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12}>
            <ItemCustom>
              <FormLabel sx={{ width: "10%" }}>Item nota fiscal *</FormLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="show_select_code"
                  options={productsToSearch}
                  value={productCompanyItem}
                  getOptionLabel={(option) =>
                    `${option?.code} - ${option?.label}`
                  }
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setProductCompanyItem(value);
                      setCodeProduct(value?.code);
                      setDescriptionProduct(value?.label);
                      setUnitProduct(value?.unidade);
                    } else {
                      setProductCompanyItem(null);
                      setCodeProduct("");
                      setDescriptionProduct("");
                      setUnitProduct("");
                    }
                  }}
                  renderInput={(params) =>
                    loadingProductCompany ? (
                      <CircularLoading />
                    ) : (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    )
                  }
                />
              </FormControl>
              <IconButton
                aria-label="search"
                color="primary"
                onClick={() => setOpenDialogThree(true)}
              >
                <SearchIcon />
              </IconButton>
            </ItemCustom>
          </Grid>
          <Grid xs={12}>
            <ItemCustom>
              <FormLabel sx={{ width: "10%" }}>Código</FormLabel>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="code"
                  name="code"
                  value={codeProduct}
                  onChange={(e) => setCodeProduct(e.target.value)}
                  placeholder="Código"
                  required
                  variant="outlined"
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12}>
            <ItemCustom>
              <FormLabel sx={{ width: "10%" }}>Descrição</FormLabel>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="description"
                  name="description"
                  value={descriptionProduct}
                  onChange={(e) => setDescriptionProduct(e.target.value)}
                  placeholder="Descrição"
                  required
                  variant="outlined"
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <ItemCustom>
              <FormLabel sx={{ width: "38%" }}>Unidade de medida</FormLabel>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="unit"
                  name="unit"
                  value={unitProduct}
                  onChange={(e) => setUnitProduct(e.target.value)}
                  placeholder="Unidade de medida"
                  variant="outlined"
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <ItemCustom>
              <FormLabel sx={{ width: "38%" }}>Quantidade *</FormLabel>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="quantity"
                  required
                  name="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder="Quantidade"
                  variant="outlined"
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <ItemCustom>
              <FormLabel sx={{ width: "38%" }}>Valor unitário *</FormLabel>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="value"
                  name="value"
                  disabled
                  value={
                    value
                      ? value?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      : "0,00"
                  }
                  placeholder="Valor unitário"
                  required
                  variant="outlined"
                />
              </FormControl>
            </ItemCustom>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "5%" }}>
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            setOpenDialogSecondary(false);
            handleSelect(null);
            setProductCompanyItem(null);
            setItemRequestInvoice(null);
            setCodeProduct("");
            setDescriptionProduct("");
            setUnitProduct("");
            setQuantity("");
            setValue("");
          }}
        />
        {!invoiceItemSelect?.id ? (
          <ButtonSalveForm label="Incluir" onClick={handleNewSalve} />
        ) : (
          <ButtonSalveForm label="Salvar" onClick={handleUpdate} />
        )}
      </DialogActions>

      <DialogComponent open={openDialogThree} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Itens</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogThree(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {productsToSearch.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={[
              {
                label: "Código",
              },
              {
                label: "Descrição",
              },
            ]}
            page={pageProductCompany}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {productsToSearch.map((item: any) => (
              <TableRow
                hover
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProductCompanyItem(item);
                  setCodeProduct(item?.code);
                  setDescriptionProduct(item?.label);
                  setUnitProduct(item?.unidade);
                  setOpenDialogThree(false);
                }}
              >
                <TableCell component="th" scope="row">
                  {item.code}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.label}
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </DialogComponent>
    </>
  );
};

export default CretaeorUpdateInvoiceItem;
