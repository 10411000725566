import httpShoppingProcess from "src/http/shoppingProcess";

class AttachmentsConsumer {
  static get(processoCompraId: string) {
    const search = new URLSearchParams();

    search.append("ProcessoCompraId", processoCompraId);

    return httpShoppingProcess.get(`/ProcessoCompraAnexo?${search.toString()}`);
  }

  static getById(id: string) {
    return httpShoppingProcess.get(`/ProcessoCompraAnexo/${id}`);
  }

  static post(body: any) {
    return httpShoppingProcess.post(`/ProcessoCompraAnexo`, body);
  }

  static deleted(id: string) {
    const search = new URLSearchParams();

    search.append("Id", id);
    return httpShoppingProcess.delete(
      `/ProcessoCompraAnexo?${search.toString()}`
    );
  }
}

export default AttachmentsConsumer;
