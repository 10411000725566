/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iShoppingProcessItem,
  iShoppingProcessItemContextProps,
  iShoppingProcessItemPage,
} from "src/interfaces/shoppingProcessItem";
import shoppingProcessConsumerItem from "src/services/shoppingProcessItem";
import ShoppingProcessItem from "src/models/ShoppingProcessItem";
import { toast } from "react-toastify";
import useLayout from "src/hooks/useLayout";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { iShoppingProcess } from "src/interfaces/shoppingProcess";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import TaxaPCItem from "src/services/taxaPCItem";
import useCompany from "src/hooks/useCompany";
import shoppingProcessConsumer from "src/services/shoppingProcess";
import ShoppingProcess from "src/models/ShoppingProcess";
import useImport from "src/hooks/useImport";

const ShoppingProcessItemContext =
  createContext<iShoppingProcessItemContextProps>(
    {} as iShoppingProcessItemContextProps
  );

export function ShoppingProcessItemProvider({ children }: { children: any }) {
  const { setOpenDialog } = useLayout();

  const {
    shoppingProcesSelect,
    handleSelected: handleSelectedShoppingProccess,
    page: pageShoppingProccess,
  } = useShoppingProcess();
  const { getTaxaPc, shoppingProccessRates } = useShoppingProccessRate();
  const { setName } = useCompany();
  const { setCallItems } = useImport();

  const [loading, setLoading] = useState<boolean>(false);
  const [shoppingProcessItem, setShoppingProcessItem] = useState<
    Array<iShoppingProcessItem>
  >([]);
  const [shoppingProcessItemToSearch, setShoppingProcessItemToSearch] =
    useState<Array<any>>([]);
  const [page, setPage] = useState<iShoppingProcessItemPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [shoppingProcesItemSelect, setShoppingProcesItemSelect] =
    useState<iShoppingProcessItem | null>(null);

  const [quantity, setQuantity] = useState<number>(0);
  const [referenceValue, setReferenceValue] = useState<string>("R$ 0");
  const [alvoValue, setAlvoValue] = useState<string>("R$ 0");
  const [productId, setProductId] = useState<string>("");
  const [productGeneralId, setProductGeneralId] = useState<string>("");
  const [buyerId, setBuyerId] = useState<string>("");
  const [buyerName, setBuyerName] = useState<string>("");
  const [buyerValue, setBuyerValue] = useState<any>(null);
  const [buyerResponsibleId, setBuyerResponsibleId] = useState<string>("");
  const [buyerResponsibleName, setBuyerResponsibleName] = useState<string>("");
  const [paymentConditionId, setPaymentConditionId] = useState<string>("");
  const [paymentConditionValue, setPaymentConditionValue] = useState<any>(null);
  const [paymentCondition, setPaymentCondition] = useState<string>("");
  const [unitId, setUnitId] = useState<string>("");
  const [shoppingProcessId, setShoppingProcessId] = useState<string>("");
  const [companyContactId, setCompanyContactId] = useState<string>("");
  const [companyContactValue, setCompanyContactValue] = useState<any>(null);
  const [companyContact, setCompanyContact] = useState<string>("");
  const [orderBy, setOrderBy] = useState<any>({ number: 2, order: true });
  const [productSelect, setProductSelect] = useState<any | null>(null);
  const [productLabel, setProductLabel] = useState<any | null>(null);
  const [productCode, setProductCode] = useState<any | null>(null);

  const [codeSearch, setCodeSearch] = useState<string>("");
  const [descriptionSearch, setDescriptionSearch] = useState<string>("");

  const [calculateTotalShoppingProcess, setCalculateTotalShoppingProcess] =
    useState<string>("");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProcessItem = async (
    shoppingProccessId?: string,
    totalItemsPage?: number
  ) => {
    setLoading(true);
    setCallItems(false);

    try {
      let pageItems = {
        ...page,
      };

      if (totalItemsPage && totalItemsPage > 0) {
        pageItems = {
          ...page,
          rowsPerPage: totalItemsPage,
        };
      }
      const response = await shoppingProcessConsumerItem.get(
        pageItems,
        shoppingProcesSelect?.id ?? shoppingProccessId ?? "",
        orderBy,
        codeSearch.trim(),
        descriptionSearch.trim()
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcessItem.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setShoppingProcessItem(data);
      setShoppingProcessItemToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item?.id,
            label: item?.descricaoProdutoEmpresa,
            code: item?.codigoProdutoEmpresa,
            compradorId: item?.compradorId,
            comprador: item?.comprador,
            condicaoPagamentoId: item?.condicaoPagamentoId,
            condicaoPagamento: item?.condicaoPagamento,
            unidadeId: item?.unidadeId,
            unidade: item?.unidade,
            responsavelComprador: item?.responsavelComprador,
            responsavelCompradorId: item?.responsavelCompradorId,
            valorBaseline: item?.valorReferencia,
            valorAlvo: item?.valorAlvo,
            quantidade: item?.quantidade,
          };
        })
      );
      setCalculateTotalShoppingProcess(
        data
          ?.map(
            (item: iShoppingProcessItem) =>
              item.quantity * parseInt(item.referenceValue)
          )
          ?.reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          )
          .toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
      );

      return data;
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os itens do processos de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const changeShoppingProccess = async () => {
    try {
      const shoppingProccessActualResponse = await shoppingProcessConsumer.get(
        pageShoppingProccess,
        shoppingProcesSelect?.number
      );

      if (
        shoppingProccessActualResponse.status === 200 &&
        shoppingProccessActualResponse.data.items.length !== 0
      ) {
        const dataActuallyShoppingProccess =
          shoppingProccessActualResponse.data.items.map((item: any) =>
            ShoppingProcess.adapterToClass(item)
          );
        handleSelectedShoppingProccess(dataActuallyShoppingProccess[0]);
      }
    } catch (error: any) {
      toast.error(
        `Erro ao atualizar o processo de compras: \n ${error.message}`
      );
    }
  };

  const handleNewSalve = async (shoppingProccess: iShoppingProcess) => {
    if (!productId)
      return toast.warning("O campo de código e descrição são obrigatórios!");

    if (!buyerId)
      return toast.warning("O campo de empresa compradora é obrigatório!");

    if (!companyContactId)
      return toast.warning("O campo de responsável é obrigatório!");

    if (!paymentConditionId)
      return toast.warning("O campo de condição de pagamento é obrigatório!");

    if (!quantity) return toast.warning("O campo de quantidade é obrigatório!");

    if (!referenceValue)
      return toast.warning("O campo de valor de referência é obrigatório!");

    if (!alvoValue)
      return toast.warning("O campo de valor alvo é obrigatório!");

    setLoading(true);

    try {
      const body = new ShoppingProcessItem(
        null,
        quantity,
        referenceValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        productId,
        buyerId,
        companyContactId,
        paymentConditionId,
        unitId,
        shoppingProccess!.id ?? "",
        "",
        "",
        "",
        "",
        "",
        "",
        alvoValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        productGeneralId
      );

      const response = await shoppingProcessConsumerItem.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      await changeShoppingProccess();
      await getTaxaPc();

      if (shoppingProccessRates?.length !== 0) {
        shoppingProccessRates.map(async (item) => {
          await TaxaPCItem.created({
            taxaPCId: item.id,
            processoCompraItensId: response?.data?.id,
            dataInicio: shoppingProcesSelect?.registerDate,
            dataFim: item.dataFim,
            valorTaxa: item.valorTaxa,
          });
        });
      }

      toast.success("Item cadastrado com sucesso!");

      setOpenDialog(false);
      handleSelected(null);
      setName("");
      getShoppingProcessItem();
    } catch (e) {
      toast.error("Ops... Ocorreu um erro ao salvar o item!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (shoppingProccess: iShoppingProcess) => {
    if (!productId)
      return toast.warning("O campo de código e descrição são obrigatórios!");

    if (!buyerId)
      return toast.warning("O campo de empresa compradora é obrigatório!");

    if (!buyerResponsibleId)
      return toast.warning("O campo de responsável é obrigatório!");

    if (!paymentConditionId)
      return toast.warning("O campo de condição de pagamento é obrigatório!");

    if (!quantity) return toast.warning("O campo de quantidade é obrigatório!");

    if (!referenceValue)
      return toast.warning("O campo de valor de referência é obrigatório!");

    if (!alvoValue)
      return toast.warning("O campo de valor alvo é obrigatório!");

    try {
      setLoading(true);

      const body = new ShoppingProcessItem(
        shoppingProcesItemSelect?.id ?? "",
        quantity,
        referenceValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        productId,
        buyerId,
        companyContactId,
        paymentConditionId,
        unitId,
        shoppingProccess!.id ?? "",
        "",
        "",
        "",
        "",
        "",
        "",
        alvoValue
          .replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        productGeneralId
      );

      const response = await shoppingProcessConsumerItem.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      await changeShoppingProccess();

      toast.success("Item alterado com sucesso!");
      setOpenDialog(false);
      handleSelected(null);
      setName("");
      getShoppingProcessItem();
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o item!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (shoppingProcessItem: iShoppingProcessItem | null) => {
    setShoppingProcesItemSelect(shoppingProcessItem);
    setQuantity(shoppingProcessItem?.quantity ?? 0);
    setProductId(shoppingProcessItem?.productId ?? "");
    setProductGeneralId(shoppingProcessItem?.productGenerealId ?? "");
    setBuyerId(shoppingProcessItem?.buyerId ?? "");
    setBuyerName(shoppingProcessItem?.buyerDescription ?? "");
    setPaymentConditionId(shoppingProcessItem?.paymentConditionId ?? "");
    setPaymentCondition(shoppingProcessItem?.paymentCondition ?? "");
    setUnitId(shoppingProcessItem?.unitId ?? "");
    setShoppingProcessId(shoppingProcessItem?.shoppingProcessId ?? "");
    setCompanyContactId(shoppingProcessItem?.companyContactId ?? "");
    setCompanyContact(shoppingProcessItem?.companyContact ?? "");
    setProductLabel(shoppingProcessItem?.descrptionProduct ?? "");
    setProductCode(shoppingProcessItem?.codeProduct ?? "");
    setBuyerResponsibleId(shoppingProcessItem?.companyContactId ?? "");

    setCodeSearch("");
    setDescriptionSearch("");

    if (shoppingProcessItem?.paymentConditionId) {
      setPaymentConditionValue({
        id: shoppingProcessItem.paymentConditionId,
        label: shoppingProcessItem?.paymentCondition,
      });
    } else {
      setPaymentConditionValue(null);
    }

    if (shoppingProcessItem?.companyContactId) {
      setCompanyContactValue({
        id: shoppingProcessItem.companyContactId,
        label: shoppingProcessItem?.companyContact,
      });
    } else {
      setCompanyContactValue(null);
    }

    if (shoppingProcessItem?.productId) {
      setProductSelect({
        id: shoppingProcessItem.productId,
        code: shoppingProcessItem.codeProduct,
        label: shoppingProcessItem.descrptionProduct,
      });
    } else {
      setProductSelect(null);
    }

    if (shoppingProcessItem?.buyerId) {
      setBuyerValue({
        id: shoppingProcessItem.buyerId,
        label: shoppingProcessItem?.buyerDescription,
      });
    } else {
      setBuyerValue(null);
    }

    if (shoppingProcessItem?.referenceValue) {
      setReferenceValue(
        parseFloat(shoppingProcessItem.referenceValue)?.toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        )
      );
    }

    if (shoppingProcessItem?.alvoValue) {
      setAlvoValue(
        parseFloat(shoppingProcessItem.alvoValue)?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }

    if (shoppingProcessItem === null) {
      setProductId("");
      setProductGeneralId("");
      setReferenceValue("R$ 0");
      setAlvoValue("R$ 0");
      setProductSelect(null);
    }
  };

  const handleDelete = async (shoppingProcessItem: iShoppingProcessItem) => {
    try {
      setLoading(true);

      const response = await shoppingProcessConsumerItem.deleted(
        `${shoppingProcessItem?.id}`
      );

      if (response.status !== 200) throw response;

      await changeShoppingProccess();

      toast.success("Item deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelected(null);
      getShoppingProcessItem();
    } catch {
      toast.error("Erro ao deletar o item do processo de compra!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      setPage,
      getShoppingProcessItem,
      loading,
      setLoading,
      shoppingProcessItem,
      setShoppingProcessItem,
      handleChangePage,
      handleChangeRowsPerPage,
      shoppingProcesItemSelect,
      setShoppingProcesItemSelect,
      handleSelected,
      handleDelete,
      quantity,
      setQuantity,
      referenceValue,
      setReferenceValue,
      productId,
      setProductId,
      productGeneralId,
      setProductGeneralId,
      buyerId,
      setBuyerId,
      paymentConditionId,
      setPaymentConditionId,
      paymentCondition,
      setPaymentCondition,
      unitId,
      setUnitId,
      shoppingProcessId,
      setShoppingProcessId,
      calculateTotalShoppingProcess,
      productSelect,
      setProductSelect,
      buyerResponsibleId,
      setBuyerResponsibleId,
      buyerName,
      setBuyerName,
      buyerResponsibleName,
      setBuyerResponsibleName,
      handleNewSalve,
      companyContactId,
      setCompanyContactId,
      handleUpdate,
      shoppingProcessItemToSearch,
      companyContact,
      setCompanyContact,
      productLabel,
      setProductLabel,
      productCode,
      setProductCode,
      alvoValue,
      setAlvoValue,
      buyerValue,
      setBuyerValue,
      companyContactValue,
      setCompanyContactValue,
      paymentConditionValue,
      setPaymentConditionValue,
      setShoppingProcessItemToSearch,
      orderBy,
      setOrderBy,
      codeSearch,
      setCodeSearch,
      descriptionSearch,
      setDescriptionSearch,
    };
  }, [
    page,
    orderBy,
    loading,
    shoppingProcessItem,
    quantity,
    referenceValue,
    productId,
    buyerId,
    paymentConditionId,
    paymentCondition,
    unitId,
    shoppingProcessId,
    calculateTotalShoppingProcess,
    productSelect,
    buyerResponsibleId,
    buyerName,
    buyerResponsibleName,
    companyContactId,
    shoppingProcessItemToSearch,
    companyContact,
    productLabel,
    alvoValue,
    buyerValue,
    companyContactValue,
    paymentConditionValue,
    productGeneralId,
    codeSearch,
    descriptionSearch,
  ]);

  return (
    <ShoppingProcessItemContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessItemContext.Provider>
  );
}

export default ShoppingProcessItemContext;
