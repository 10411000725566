import { iAddressType } from "src/interfaces/address";

export default class AddressType implements iAddressType {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    this.id = id;
    this.description = description;
  }

  public static adapterToClass(addressType: any) {
    return new AddressType(addressType.id, addressType.descricao);
  }

  public static adapterToJson(addressType: any) {
    return JSON.parse(addressType);
  }

  public static adapterToBody(addressType: iAddressType) {
    return {
      id: addressType.id,
      descricao: addressType.description,
    };
  }
}
