import { localStorageStrings } from "src/constants/localStorageStings";
import { iShoppingProcessPhases } from "src/interfaces/shoppingProcessPhases";

export default class ShoppingProcessPhases implements iShoppingProcessPhases {
  id?: string | null;
  number: string;
  description: string;
  startDatePC: any;
  endDatePC: any;
  previsionEndDate: string;
  valueTotal: number;
  shoppingProcessPhaseTypeId: string;
  shoppingProcessPhaseType: string;
  shoppingProcessId: string;
  status: string;
  items?: any;
  statusId?: string;

  constructor(
    id: string | null,
    number: string,
    description: string,
    startDatePC: any,
    endDatePC: any,
    previsionEndDate: string,
    valueTotal: number,
    shoppingProcessPhaseTypeId: string,
    shoppingProcessPhaseType: string,
    shoppingProcessId: string,
    status: string,
    items?: any,
    statusId?: string
  ) {
    this.id = id;
    this.number = number;
    this.description = description;
    this.startDatePC = startDatePC;
    this.endDatePC = endDatePC;
    this.previsionEndDate = previsionEndDate;
    this.valueTotal = valueTotal;
    this.shoppingProcessPhaseTypeId = shoppingProcessPhaseTypeId;
    this.shoppingProcessPhaseType = shoppingProcessPhaseType;
    this.shoppingProcessId = shoppingProcessId;
    this.status = status;
    this.items = items;
    this.statusId = statusId;
  }

  public static adapterToClass(shoppingProcessPhases: any) {
    return new ShoppingProcessPhases(
      shoppingProcessPhases.id,
      shoppingProcessPhases.numero,
      shoppingProcessPhases.descricao,
      shoppingProcessPhases.dataInicioPC,
      shoppingProcessPhases.dataFimPC,
      shoppingProcessPhases.dataPrevistaFim,
      shoppingProcessPhases.valorTotal,
      shoppingProcessPhases.processoCompraEtapaTipoId,
      shoppingProcessPhases.processoCompraEtapaTipo,
      shoppingProcessPhases.processoCompraId,
      shoppingProcessPhases.processoCompraEtapaStatus,
      shoppingProcessPhases.itens,
      shoppingProcessPhases.processoCompraEtapaStatusId
    );
  }

  public static adapterToJson(shoppingProcess: any) {
    return JSON.parse(shoppingProcess);
  }

  public static adapterToBody(shoppingProcessPhases: iShoppingProcessPhases) {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);
    const userJson = JSON.parse(`${userLogged}`);

    const data: any = {
      numero: shoppingProcessPhases.number,
      descricao: shoppingProcessPhases.description,
      dataInicioPC: shoppingProcessPhases.startDatePC,
      dataFimPC: shoppingProcessPhases.endDatePC,
      processoCompraEtapaTipoId:
        shoppingProcessPhases.shoppingProcessPhaseTypeId,
      processoCompraId: shoppingProcessPhases.shoppingProcessId,
      processoCompraStatusId: shoppingProcessPhases.statusId,
      valorTotal: shoppingProcessPhases.valueTotal,
      usuarioSistemaResponsavel: userJson.login,
    };

    if (shoppingProcessPhases.previsionEndDate) {
      data["dataPrevistaFim"] = shoppingProcessPhases.previsionEndDate;
    }

    if (shoppingProcessPhases.id) {
      return {
        id: shoppingProcessPhases.id,
        ...data,
      };
    }

    return {
      ...data,
    };
  }
}
