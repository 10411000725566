/* eslint-disable react-hooks/exhaustive-deps */
import { eMessage } from "src/enums/messages";
import { useWindowSize } from "@react-hook/window-size";
import { useState, createContext, useMemo } from "react";
import { iLayoutContextProps } from "src/interfaces/layout";
import { iMenuRoutes } from "src/interfaces/menuRoutes";

const LayoutContext = createContext<iLayoutContextProps>(
  {} as iLayoutContextProps
);

const INITIAL_VALUE = { message: "", type: eMessage.empty };

export function LayoutProvider({ children }: { children: any }) {
  const [width, height] = useWindowSize();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);

  const [openDialogSecondary, setOpenDialogSecondary] =
    useState<boolean>(false);
  const [openDialogLanguage, setOpenDialogLanguage] = useState<boolean>(false);
  const [openDialogThree, setOpenDialogThree] = useState<boolean>(false);
  const [openDialogFour, setOpenDialogFour] = useState<boolean>(false);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
  const [openSubMenuClicked, setOpenSubMenuClicked] = useState<Array<any>>([]);
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(true);
  const [openDialogAlert, setOpenDialogAlert] = useState(false);
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  const [selectMenuDrawer, setSelectMenuDrawer] = useState<iMenuRoutes | null>(
    null
  );
  const [drawerWidth, setDrawerWidth] = useState<number>(
    width <= 600 ? width + 10 : 290
  );

  const [message, setMessage] = useState<{ message: String; type: eMessage }>(
    INITIAL_VALUE
  );

  const handleScroll = (e: any, callback: () => void) => {
    const bottom =
      parseInt(e.target.scrollTop) + e.target.clientHeight + 100 >
      e.target.scrollHeight;

    if (bottom) {
      callback();
    }
  };

  const emptyMessage = () => setMessage(INITIAL_VALUE);

  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickSubMenu = (menu: any) => {
    if (openSubMenuClicked.find((e) => e?.path === menu?.path)) {
      setOpenSubMenu(false);

      const menusClicked = [...openSubMenuClicked].filter(
        (e) => e?.path !== menu?.path
      );
      setOpenSubMenuClicked([...menusClicked]);
    } else {
      setOpenSubMenu(true);
      setOpenSubMenuClicked([...openSubMenuClicked, { ...menu, open: true }]);
    }
  };

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const showAvatarUser = width >= 1024;

  const contextValue = useMemo(() => {
    return {
      open,
      setOpen,
      handleDrawerOpen,
      handleDrawerClose,
      drawerWidth,
      setDrawerWidth,
      anchorEl,
      handleClickMenu,
      handleCloseMenu,
      openMenu,
      showAvatarUser,
      openSubMenu,
      handleClickSubMenu,
      message,
      setMessage,
      emptyMessage,
      openDialogAlert,
      setOpenDialogAlert,
      disableButtons,
      setDisableButtons,
      openDialog,
      setOpenDialog,
      openDialogSecondary,
      setOpenDialogSecondary,
      loadingDialog,
      setLoadingDialog,
      openMenuDrawer,
      setOpenMenuDrawer,
      selectMenuDrawer,
      setSelectMenuDrawer,
      openDialogThree,
      setOpenDialogThree,
      width,
      height,
      handleScroll,
      openSubMenuClicked,
      openDialogLanguage,
      setOpenDialogLanguage,
      click,
      setClick,
      openDialogFour,
      setOpenDialogFour,
    };
  }, [
    open,
    drawerWidth,
    anchorEl,
    openMenu,
    openSubMenu,
    message,
    openDialogAlert,
    disableButtons,
    openDialog,
    openDialogSecondary,
    loadingDialog,
    openMenuDrawer,
    selectMenuDrawer,
    openDialogThree,
    width,
    height,
    openSubMenuClicked,
    openDialogLanguage,
    click,
    openDialogFour,
  ]);

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContext;
