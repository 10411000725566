import { iUnit } from "src/interfaces/unit";

export default class Unit implements iUnit {
  id: string;
  code: string;
  description: string;

  constructor(id: string, code: string, description: string) {
    this.id = id;
    this.code = code;
    this.description = description;
  }

  public static adapterToClass(unit: any) {
    return new Unit(unit.id, unit.codigo, unit.descricao);
  }

  public static adapterToJson(unit: any) {
    return JSON.parse(unit);
  }

  public static adapterToBody(unit: iUnit) {
    if (unit.id) {
      return {
        id: unit.id,
        codigo: unit.code,
        descricao: unit.description,
      };
    }

    return {
      codigo: unit.code,
      descricao: unit.description,
    };
  }
}
