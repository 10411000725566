import styled from "styled-components";

export const DrawerDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DivListItemIconCustom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ListItemCustom = styled.div<{ isOpen?: boolean }>`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  opacity: 0.75;
  margin-left: ${(props) => (props.isOpen ? "1.5%" : "10%")};
  width: 89%;
`;

export const DivDrawerScroll = styled.div<{ height: number }>`
  overflow: scroll;
  overflow-x: hidden;
  height: ${(props) => `${props.height}px`};

  ::-webkit-scrollbar {
    width: 1px;
  }
`;
