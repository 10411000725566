import { useParams } from "react-router-dom";
import Layout from "src/components/Layout";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const Home: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: `${translations[param]["home"]}`,
    },
  ];

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <></>
    </Layout>
  );
};

export default Home;
