import httpClientRequest from "src/http/request";
import { iInvoicesItems } from "src/interfaces/invoicesItem";
import { iPage } from "src/interfaces/layout";
import InvoiceItems from "src/models/InvoiceItems";

class invoiceItemsConsumer {
  static get(page: iPage, invoiceId: string) {
    const search = new URLSearchParams();

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());
    search.append("NotaFiscalId", invoiceId);

    return httpClientRequest.get(`/NotaFiscalItem?${search.toString()}`);
  }

  static created(items: iInvoicesItems) {
    return httpClientRequest.post(
      `/NotaFiscalItem`,
      InvoiceItems.adapterToBody(items)
    );
  }

  static updated(items: iInvoicesItems) {
    return httpClientRequest.put(
      `/NotaFiscalItem/${items?.id}`,
      InvoiceItems.adapterToBody(items)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRequest.delete(`/NotaFiscalItem/${id}`);
  }

  static createdRelationWIthRequestItem(body: any) {
    return httpClientRequest.post(`/NotaFiscalItemPedidoItem`, {
      notaFiscalItemId: body?.notaFiscalItemId,
      pedidoItemId: body?.pedidoItemId,
    });
  }

  static deletedRelationWIthRequestItem(id: string | null | undefined) {
    return httpClientRequest.delete(`/NotaFiscalItemPedidoItem/${id}`);
  }
}

export default invoiceItemsConsumer;
