import { localStorageStrings } from "src/constants/localStorageStings";
import axios from "axios";
import redirectToLogin from "src/functions/redirectToLogin";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE_REQUEST_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem(localStorageStrings.apiToken);

  if (token) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) return redirectToLogin("/pt");

    return Promise.reject(error);
  }
);

export default httpClient;
