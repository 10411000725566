/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import LOGO from "src/assets/images/Logo.svg";
import { useWindowSize } from "@react-hook/window-size";
import { palette } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import useLogin from "src/hooks/useLogin";
import { tLanguage } from "src/types/language";
import CircularLoading from "src/components/Loading";
import { eMessage } from "src/enums/messages";
import { localStorageStrings } from "src/constants/localStorageStings";
import translations from "src/_i18n/translations.json";
import { marginTop } from "./style";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import MessageAlert from "src/components/MessageAlert";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import ValidationCodeInput from "./validation_code_input";

const Login: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    loading,
    validationErrorPassword,
    handleSendCredentials,
    showPassword,
    handleClickShowPassword,
    username,
    setUsername,
    password,
    setPassword,
    getLoginUserInNavegatorMemory,
    showMessage,
    setShowMessage,
    isRedirectScreenCode,
    logout,
    messageError,
  } = useLogin();

  useEffect(() => {
    getLoginUserInNavegatorMemory();
    logout();
  }, []);

  const [width] = useWindowSize();
  const [sizeWidthCard, setSizeWidthCard] = useState<number>(345);

  useEffect(() => {
    calculateSize();
  }, [width]);

  const calculateSize = () => {
    if (width >= 1024) {
      setSizeWidthCard(360);
    } else if (width >= 600) {
      setSizeWidthCard(600);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 2, background: palette.primary.main }}
    >
      {!isRedirectScreenCode ? (
        <Card sx={{ padding: 2, maxWidth: sizeWidthCard }}>
          <CardContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "10px" }}
            >
              <Typography gutterBottom variant="h5" component="div">
                {translations[param]["login"]}
                {process.env.REACT_APP_TITLE === localStorageStrings.titleApp &&
                  "- HML"}
              </Typography>
            </Grid>
            <Divider style={{ backgroundColor: palette.primary.main }} />
            <form onSubmit={(e) => handleSendCredentials(e)}>
              <FormControl fullWidth sx={marginTop}>
                <TextField
                  id="username"
                  name="username"
                  label={translations[param]["user"]}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  variant="outlined"
                  disabled={loading}
                />
              </FormControl>

              <FormControl fullWidth sx={marginTop}>
                <InputLabel htmlFor="password">
                  {translations[param]["password"]}
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label={translations[param]["password"]}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  error={validationErrorPassword}
                  disabled={loading}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {validationErrorPassword && (
                  <FormHelperText error={validationErrorPassword}>
                    {
                      translations[param][
                        "the_password_you_entered_is_not_valid_please_try_again"
                      ]
                    }
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth sx={marginTop}>
                <Button variant="contained" type="submit" disabled={loading}>
                  {translations[param]["login"]}
                </Button>
              </FormControl>
            </form>
            <br />
            {loading && <CircularLoading />}
            {showMessage && (
              <MessageAlert
                currentMessage={{
                  message: `${messageError["ValidationError"]} tentativas restantes: ${messageError["number"]}`,
                  type: eMessage.error,
                }}
                handleOnClose={() => setShowMessage(false)}
              />
            )}
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "10px" }}
            >
              <Button
                variant="text"
                onClick={() => router(`/${lang}/password/recovery`)}
              >
                {translations[param]["forgotpassword"]}
              </Button>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "10px" }}
            >
              <img alt="logo" src={LOGO} />
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <br />
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    cursor: "pointer",
                    color:
                      lang === "pt"
                        ? palette.primary.main
                        : palette.secondary.dark,
                  }}
                  onClick={() => router("/pt")}
                >
                  PT
                </Typography>
                |
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    cursor: "pointer",
                    color:
                      lang === "en"
                        ? palette.primary.main
                        : palette.secondary.dark,
                  }}
                  onClick={() => router("/en")}
                >
                  EN
                </Typography>
                |
                <Typography
                  noWrap
                  component="span"
                  sx={{
                    cursor: "pointer",
                    color:
                      lang === "es"
                        ? palette.primary.main
                        : palette.secondary.dark,
                  }}
                  onClick={() => router("/es")}
                >
                  ES
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <ValidationCodeInput usernameDisplay={username} />
      )}
    </Grid>
  );
};

export default Login;
