import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustomStart } from "src/components/ItemCustom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { styled } from "@mui/material/styles";
import { MaskStyle } from "src/styles/globalStyle";
import Swal from "sweetalert2";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import { palette } from "src/theme";
import useDocuments from "src/hooks/useDocuments";
import useCompany from "src/hooks/useCompany";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DocumentsCompany = ({
  description,
  type,
  code,
  document,
}: {
  description: string;
  type: string;
  code: number;
  document?: any;
}) => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    sendDocuments,
    getDocumentById,
    handleFileChange,
    documentInput,
    handleFileClear,
    upload,
  } = useDocuments();
  const { companySelected } = useCompany();

  return (
    <Grid xs={12} sm={12} md={12}>
      <ItemCustomStart>
        <FormLabel sx={{ width: "13%" }}>{description}: </FormLabel>

        <Button
          component="label"
          size="medium"
          variant="contained"
          disabled={
            documentInput?.documentosTipoId === ""
              ? false
              : documentInput?.documentosTipoId === type
              ? false
              : true
          }
          startIcon={<CloudUploadIcon />}
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            marginLeft: "2%",
          }}
        >
          Escolher aquivos
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => handleFileChange(e, companySelected, type)}
          />
        </Button>
        <TextField
          size="small"
          id="company"
          disabled={
            documentInput?.documentosTipoId === ""
              ? false
              : documentInput?.documentosTipoId === type
              ? false
              : true
          }
          value={
            documentInput?.documentosTipoId === type
              ? documentInput?.nomeArquivo
              : "Nenhum arquivo..."
          }
          sx={{ width: "40%", marginRight: "1%" }}
          InputProps={{
            sx: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            style: {
              height: 37,
            },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={handleFileClear}
              >
                <CloseOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <MaskStyle>
          <input
            type="date"
            name="end_date"
            placeholder="dd/mm/yyyy"
            min="1970-01-01"
            disabled={
              documentInput?.documentosTipoId === ""
                ? false
                : documentInput?.documentosTipoId === type
                ? false
                : true
            }
          />
        </MaskStyle>
        <IconButton
          aria-label="search"
          color="primary"
          disabled={code !== 5 && code !== 6 ? true : false}
          onClick={() =>
            Swal.fire({
              title: translations[param]["attention"],
              text: `A ação irá iniciar nosso robô para buscar o documento da empresa, antes de continuar verifique se o documento já existe em nossa base de dados, tente fazer o download do mesmo. deseja continuar ? `,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: palette.primary.main,
              cancelButtonColor: palette.error.main,
              confirmButtonText: translations[param]["yes"],
              cancelButtonText: translations[param]["no"],
            }).then((result) => {
              if (result.isConfirmed)
                sendDocuments({
                  code: code,
                  typeDocumentId: type,
                  cnpj: companySelected?.cnpj,
                  companyId: companySelected?.id,
                });
            })
          }
        >
          <SearchIcon />
        </IconButton>
        <IconButton aria-label="search" color="primary" onClick={upload}>
          <BackupOutlinedIcon />
        </IconButton>
        <IconButton
          aria-label="search"
          color="primary"
          disabled={document?.id ? false : true}
          onClick={async () => getDocumentById(document.id)}
        >
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </ItemCustomStart>
    </Grid>
  );
};

export default DocumentsCompany;
