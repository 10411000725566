/* eslint-disable react-hooks/exhaustive-deps */
import { addHours, format } from "date-fns";
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import { iPage } from "src/interfaces/layout";
import {
  iShoppingProccessRate,
  iShoppingProccessRateContextProps,
} from "src/interfaces/shoppingProccessRate";
import ShoppingProccessRate from "src/models/ShoppingProccessRate";
import ShoppingProccessRateConsumer from "src/services/taxaPC";
import TaxaPCItem from "src/services/taxaPCItem";

const ShoppingProccessRateContext =
  createContext<iShoppingProccessRateContextProps>(
    {} as iShoppingProccessRateContextProps
  );

export function ShoppingProccessRateProvider({ children }: { children: any }) {
  const { shoppingProcesSelect } = useShoppingProcess();
  const { getShoppingProcessItem } = useShoppingProcessItem();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [shoppingProccessRateSelect, setShoppingProccessRateSelect] =
    useState<iShoppingProccessRate | null>(null);
  const [shoppingProccessRates, setShoppingProccessRates] = useState<
    iShoppingProccessRate[]
  >([]);
  const [taxasId, setTaxasId] = useState<string>("select");
  const [processoCompraId, setProcessoCompraId] = useState<string>("");
  const [dataInicio, setDataInicio] = useState<string>("");
  const [dataFim, setDataFim] = useState<string>("");
  const [valorTaxa, setValorTaxa] = useState<number>(0);
  const [valorTaxaTotal, setValorTaxaTotal] = useState<number>(0);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getTaxaPc = async () => {
    setLoading(true);

    try {
      const response = await ShoppingProccessRateConsumer.get(
        page,
        shoppingProcesSelect?.id ?? ""
      );

      if (response.status !== 200 && response.status !== 201) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProccessRate.adapterToClass(item)
      );

      let totalRates = 0;

      if (data.length !== 0) {
        totalRates = data
          ?.map((item: iShoppingProccessRate) => item.valorTaxa)
          ?.reduce(
            (accumulator: any, curretValue: any) => (accumulator += curretValue)
          );
      }

      setValorTaxaTotal(totalRates);

      setShoppingProccessRates(data);

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }
    } catch (e) {
      toast.error("Ops... tivemos um problema ao buscas as taxas!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (data: iShoppingProccessRate | null) => {
    if (data) {
      setShoppingProccessRateSelect(data);
      setTaxasId(`${data.taxasId}_${data.valorTaxa}`);
      setProcessoCompraId(shoppingProcesSelect?.id ?? "");
      setValorTaxa(data.valorTaxa);

      if (data.dataInicio) {
        setDataInicio(
          format(addHours(new Date(data.dataInicio), 3), "yyyy-MM-dd")
        );
      } else {
        setDataInicio("");
      }

      if (data.dataFim) {
        setDataFim(format(addHours(new Date(data.dataFim), 3), "yyyy-MM-dd"));
      } else {
        setDataFim("");
      }
    } else {
      setShoppingProccessRateSelect(null);
      setTaxasId("select");
      setProcessoCompraId("");
      setDataInicio("");
      setDataFim("");
      setValorTaxa(0);
    }
  };

  const handleSalve = async () => {
    try {
      setLoading(true);

      if (!dataInicio && shoppingProcesSelect?.registerDate) {
        setDataInicio(
          format(
            addHours(new Date(shoppingProcesSelect?.registerDate), 3),
            "yyyy-MM-dd"
          )
        );
      }

      const body = new ShoppingProccessRate(
        "",
        taxasId?.split("_")[0],
        dataInicio,
        dataFim,
        valorTaxa,
        shoppingProcesSelect?.id ?? ""
      );

      const response = await ShoppingProccessRateConsumer.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      const itemsShoppingProccess: any = await getShoppingProcessItem(
        shoppingProcesSelect?.id ?? ""
      );

      if (itemsShoppingProccess?.length !== 0) {
        itemsShoppingProccess.map(async (item: any) => {
          try {
            await TaxaPCItem.created({
              taxaPCId: response?.data?.id,
              processoCompraItensId: item.id,
              dataInicio: dataInicio,
              dataFim: dataFim,
              valorTaxa: valorTaxa,
            });
          } catch {
            toast.warning(
              "Taxa cadastrada, mas tivemos um problema ao vincular com o item!"
            );
          }
        });
      }

      toast.success("Taxa cadastrado com sucesso!");
      handleSelect(null);
      getTaxaPc();
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar a nova taxa!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const body = new ShoppingProccessRate(
        shoppingProccessRateSelect?.id ?? "",
        taxasId?.split("_")[0],
        dataInicio,
        dataFim,
        valorTaxa,
        shoppingProcesSelect?.id ?? ""
      );

      const response = await ShoppingProccessRateConsumer.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Taxa alterada com sucesso!");
      handleSelect(null);
      getTaxaPc();
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar a taxa!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (request: iShoppingProccessRate) => {
    try {
      setLoading(true);

      const response = await ShoppingProccessRateConsumer.deleted(
        `${request?.id}`
      );

      if (response.status !== 200) throw response;

      toast.success("Taxa deletada com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      handleSelect(null);
      getTaxaPc();
    } catch {
      toast.error(
        "Erro ao deletar a taxa, verifique se não existe vinculo da taxa com um item!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      shoppingProccessRateSelect,
      setShoppingProccessRateSelect,
      taxasId,
      setTaxasId,
      processoCompraId,
      setProcessoCompraId,
      dataInicio,
      setDataInicio,
      dataFim,
      setDataFim,
      valorTaxa,
      setValorTaxa,
      shoppingProccessRates,
      setShoppingProccessRates,
      getTaxaPc,
      handleSelect,
      handleSalve,
      handleUpdate,
      handleDelete,
      valorTaxaTotal,
      setValorTaxaTotal,
    };
  }, [
    page,
    loading,
    shoppingProccessRateSelect,
    taxasId,
    processoCompraId,
    dataInicio,
    dataFim,
    valorTaxa,
    shoppingProccessRates,
    valorTaxaTotal,
  ]);

  return (
    <ShoppingProccessRateContext.Provider value={contextValue}>
      {children}
    </ShoppingProccessRateContext.Provider>
  );
}

export default ShoppingProccessRateContext;
