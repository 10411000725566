/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Layout from "src/components/Layout";
import CircularLoading from "src/components/Loading";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import translations from "src/_i18n/translations.json";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useInvoicing from "src/hooks/useInvoicing";
import {
  Card,
  Divider,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { palette } from "src/theme";
import { MaskStyle } from "src/styles/globalStyle";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";

const BatchBilling: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
      href: `/${lang}/invoicing`,
    },
    {
      name: translations[param]["register"],
    },
    {
      name: "Em Lote",
    },
  ];

  const {
    loading,
    getPaymentConditionsAdministrator,
    paymentConditions,
    paymentCondition,
    setPaymentCondition,
    dataInicialPrevPgto,
    setDataInicialPrevPgto,
    dataFinalPrevPgto,
    setDataFinalPrevPgto,
    invoicingDate,
    setInvoicingDate,
    generateBatchBilling,
  } = useInvoicing();

  useEffect(() => {
    getPaymentConditionsAdministrator();
  }, []);

  if (loading) {
    return (
      <Layout buttons={[]} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>Gerar faturamento em lote</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            generateBatchBilling();
          }}
        >
          <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid
              container
              sx={{ width: "100%" }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={2}>
                <FormLabel>{translations[param]["initial_date"]}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    required
                    min="1970-01-01"
                    value={dataInicialPrevPgto}
                    onChange={(e) => setDataInicialPrevPgto(e.target.value)}
                  />
                </MaskStyle>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormLabel>{translations[param]["the_end_date"]}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    required
                    min="1970-01-01"
                    value={dataFinalPrevPgto}
                    onChange={(e) => setDataFinalPrevPgto(e.target.value)}
                  />
                </MaskStyle>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ width: "100%", marginTop: 0.5 }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={2}>
                <FormLabel>{translations[param]["invoicing_date"]}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    required
                    min="1970-01-01"
                    value={invoicingDate}
                    onChange={(e) => setInvoicingDate(e.target.value)}
                  />
                </MaskStyle>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormLabel>
                  {translations[param]["payment_condition"]}
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  labelId="show_select_type"
                  id="show_select_status"
                  value={paymentCondition}
                  size="small"
                  required
                  onChange={(event: SelectChangeEvent) => {
                    setPaymentCondition(event.target.value);
                  }}
                  fullWidth
                >
                  <MenuItem value="select" disabled>
                    {translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {paymentConditions?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="end"
              sx={{ marginTop: 2 }}
            >
              <ButtonDefault
                label="Fechar"
                disabled={loading}
                onClick={() => {
                  router(`/${lang}/invoicing`);
                }}
              />
              <ButtonSalveForm label="Gerar" disabled={loading} type="submit" />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Layout>
  );
};

export default BatchBilling;
