/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { iUser, iUserContextProps } from "src/interfaces/user";

import useLogin from "src/hooks/useLogin";
import Login from "src/models/Login";

import userConsumer from "src/services/user";
import useCompany from "src/hooks/useCompany";
import { iUserPage } from "src/interfaces/user";
import User from "src/models/User";
import { toast } from "react-toastify";
import { Hash } from "src/functions/hash";

const UserContext = createContext<iUserContextProps>({} as iUserContextProps);

export function UserProvider({ children }: { children: any }) {
  const { companySelected } = useCompany();

  const {
    validationErrorPassword,
    setValidationErrorPassword,
    showPassword,
    handleClickShowPassword,
    password,
    setPassword,
  } = useLogin();

  const [loading, setLoading] = useState<boolean>(false);
  const [usersDataTable, setUsersDataTable] = useState<Array<iUser>>([]);

  const [dataUserSelect, setDataUserSelect] = useState<iUser | null>(null);

  const [name, setName] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [main, setMain] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [page, setPage] = useState<iUserPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const visibleRowsTableUSer: Array<any> = useMemo(
    () =>
      usersDataTable.slice(
        page.page * page.rowsPerPage,
        page.page * page.rowsPerPage + page.rowsPerPage
      ),
    [page]
  );

  const [validationErrorEmail, setValidationErrorEmail] =
    useState<boolean>(false);

  const getUsersOfCompany = async (idCompany: string) => {
    setLoading(true);

    try {
      const response = await userConsumer.get(idCompany);

      if (response.status !== 200) throw response;

      setPage({
        ...page,
        total: response.data.length,
      });

      const users = response.data
        ?.filter((user: iUser, _: number) => !user.isDeleted)
        .map((user: iUser) => User.adapterToClass(user));

      setUsersDataTable(users);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as empresas cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSalveNewUser = async () => {
    try {
      setValidationErrorPassword(false);
      setValidationErrorEmail(false);

      if (!Login.verifyPassword(password))
        return setValidationErrorPassword(true);
      if (!Login.verifyEmail(email)) return setValidationErrorEmail(true);

      setLoading(true);

      const user = new User(
        null,
        name,
        username,
        email ?? null,
        Hash({ password: password }) as string,
        active,
        null, // Campo de Conta_Id
        companySelected?.id,
        false,
        main,
        phone.replace(/[^0-9]/g, "")
      );

      const response = await userConsumer.create(user);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Novo usuário cadastrado com sucesso!");
      toFillUSerDataOnForm(null);
      getUsersOfCompany(companySelected?.id);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao cadastrar o novo usuário!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    try {
      if (!Login.verifyEmail(email)) return setValidationErrorEmail(true);

      setValidationErrorEmail(false);

      setLoading(true);

      const user = new User(
        dataUserSelect?.id ?? null,
        name,
        username,
        email ?? null,
        password,
        active,
        null, // Campo de Conta_Id
        companySelected?.id,
        false,
        main,
        phone.replace(/[^0-9]/g, "")
      );

      const response = await userConsumer.update(user);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Usuário alterado com sucesso!");
      toFillUSerDataOnForm(null);
      getUsersOfCompany(companySelected?.id);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o usuário!");
    } finally {
      setLoading(false);
    }
  };

  const toFillUSerDataOnForm = (data: iUser | null) => {
    setDataUserSelect(data);
    setName(data?.name ?? "");
    setPhone(data?.phone ?? "");
    setEmail(data?.email ?? "");
    setActive(data?.active ?? false);
    setMain(data?.isMain ?? false);
    setUsername(data?.login ?? "");
    setPassword(data?.password ?? "");
  };

  const handleDeleteUser = async (user: iUser) => {
    try {
      setLoading(true);

      const response = await userConsumer.delete(`${user?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Usuário alterado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      toFillUSerDataOnForm(null);
      getUsersOfCompany(companySelected?.id);
    } catch {
      toast.error("Erro ao alterar usuário!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      validationErrorEmail,
      validationErrorPassword,
      showPassword,
      handleClickShowPassword,
      email,
      setEmail,
      password,
      setPassword,
      name,
      setName,
      active,
      setActive,
      main,
      setMain,
      username,
      setUsername,
      phone,
      setPhone,
      handleSalveNewUser,
      loading,
      toFillUSerDataOnForm,
      getUsersOfCompany,
      usersDataTable,
      visibleRowsTableUSer,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      dataUserSelect,
      setDataUserSelect,
      handleUpdateUser,
      handleDeleteUser,
    };
  }, [
    validationErrorEmail,
    validationErrorPassword,
    showPassword,
    email,
    password,
    name,
    active,
    main,
    username,
    phone,
    loading,
    usersDataTable,
    visibleRowsTableUSer,
    page,
    dataUserSelect,
  ]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export default UserContext;
