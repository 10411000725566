import httpClientRpas4m from "src/http/rpas4m";

class RpaDocuments {
  static async fgtsRun({
    cnpj,
    companyId,
    typeDocumentId,
  }: {
    cnpj: string;
    companyId: string;
    typeDocumentId: string;
  }) {
    const search = new URLSearchParams();
    search.append("cnpj", cnpj);
    search.append("companyId", companyId);
    search.append("typeDocumentId", typeDocumentId);

    const response = await httpClientRpas4m.get(
      `/certification/fgts?${search.toString()}`
    );

    return response;
  }

  static async regularityFgtsRun({
    cnpj,
    companyId,
    typeDocumentId,
  }: {
    cnpj: string;
    companyId: string;
    typeDocumentId: string;
  }) {
    const search = new URLSearchParams();
    search.append("cnpj", cnpj);
    search.append("companyId", companyId);
    search.append("typeDocumentId", typeDocumentId);

    const response = await httpClientRpas4m.get(
      `/regularity/fgts?${search.toString()}`
    );

    return response;
  }
}

export default RpaDocuments;
