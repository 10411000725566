/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Autocomplete,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import useCompany from "src/hooks/useCompany";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import useProductCategory from "src/hooks/useProductCategory";
import { iProductCategory } from "src/interfaces/products";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import { ItemCustom } from "src/components/ItemCustom";
import Empty from "src/components/Empty";
import useProductCategoryGeneral from "src/hooks/useProductCategoryGeneral";
import useLayout from "src/hooks/useLayout";
import DialogComponent from "src/components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const numberSumWithPage = 40;

const ProductCategoryComponent: React.FC = () => {
  const { setOpenDialog, openDialog } = useLayout();
  const { companySelected } = useCompany();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    productCategorys,
    productCategorySelected,
    getCategories,
    loading,
    code,
    setCode,
    description,
    setDescription,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSalveNewProductCategory,
    toFillUSerDataOnForm,
    handleUpdateProductCategory,
    handleDeleteProductCategory,
    fatherCategory,
    setFatherCategory,
    productCategoryGeneral,
    setProductCategoryGeneral,
    productsToSearch,
    setDescriptionSearch,
    descriptionSearch,
    getCategoriesSearch,
    active,
    setActive,
  } = useProductCategory();

  const header = [
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["parent_category"],
    },
  ];

  const {
    getCategories: getCategoriesGeneral,
    productGeneralCategorysToSearch,
    description: descriptionProductCategoryGeneral,
    setDescription: setDescriptionProductCategoryGeneral,
  } = useProductCategoryGeneral();

  useEffect(() => {
    getCategories(companySelected?.id, page.page, page.rowsPerPage);
  }, [page]);

  useEffect(() => {
    if (descriptionSearch?.length >= 4) {
      getCategoriesSearch(companySelected?.id, page.page, page.rowsPerPage);
    } else if (descriptionSearch?.length === 0) {
      getCategoriesSearch(companySelected?.id, page.page, page.rowsPerPage);
    }
  }, [descriptionSearch]);

  useEffect(() => {
    if (descriptionProductCategoryGeneral?.length >= 4) {
      getCategoriesGeneral(
        numberSumWithPage,
        false,
        descriptionProductCategoryGeneral
      );
    } else if (descriptionProductCategoryGeneral?.length === 0) {
      getCategoriesGeneral(numberSumWithPage, false, "");
    }
  }, [descriptionProductCategoryGeneral]);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!productCategorySelected?.id) {
              handleSalveNewProductCategory();
            } else {
              handleUpdateProductCategory();
            }
          }}
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid xs={10} sm={10} md={10} lg={10}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "14%" }}>
                  {`${translations[param]["category"]} ${translations[param]["main"]}`}
                  :
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productGeneralCategorysToSearch}
                  value={productCategoryGeneral}
                  getOptionLabel={(option) =>
                    option?.id ? `${option.code} - ${option.label}` : ""
                  }
                  sx={{ minWidth: "90%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setProductCategoryGeneral(value);
                    } else {
                      setProductCategoryGeneral(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translations[param]["select"]}
                      size="small"
                      onChange={(e) =>
                        setDescriptionProductCategoryGeneral(e.target.value)
                      }
                    />
                  )}
                />
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => setOpenDialog(true)}
                >
                  <SearchIcon />
                </IconButton>
              </ItemCustom>
            </Grid>
            <Grid xs={2} display="flex" justifyContent="end">
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "35%" }}>
                  {translations[param]["code"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="code"
                    name="code"
                    label={translations[param]["code"]}
                    required
                    variant="outlined"
                    size="small"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={8} md={8} lg={8}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "20%" }}>
                  {translations[param]["description"]}:{" "}
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    id="description"
                    name="description"
                    label={translations[param]["description"]}
                    required
                    variant="outlined"
                    size="small"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>

            <Grid xs={12} sm={4} md={4} lg={4}>
              <ItemCustom>
                <FormLabel sx={{ minWidth: "35%" }}>
                  {translations[param]["parent_category"]}:{" "}
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productsToSearch}
                  value={fatherCategory}
                  getOptionLabel={(option) =>
                    option.id ? `${option.code} - ${option.label}` : ""
                  }
                  sx={{ minWidth: "90%" }}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setFatherCategory(value);
                    } else {
                      setFatherCategory(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translations[param]["select"]}
                      size="small"
                      onChange={(e) => setDescriptionSearch(e.target.value)}
                    />
                  )}
                />
              </ItemCustom>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: 3 }}
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => toFillUSerDataOnForm(null)}
            />
            <FormControl>
              {productCategorySelected?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {productCategorys.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {productCategorys?.map((data: iProductCategory) => (
                <TableRow
                  hover
                  key={data.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => toFillUSerDataOnForm(data)}
                  >
                    {data.code}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.description}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.categoryProduct}
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: translations[param][
                            "delete_category_confirmation"
                          ],
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed)
                            handleDeleteProductCategory(data);
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          {`${translations[param]["category"]} ${translations[param]["main"]}`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loading ? (
            <CircularLoading />
          ) : productGeneralCategorysToSearch?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: translations[param]["code"],
                },
                {
                  label: translations[param]["description"],
                },
              ]}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {productGeneralCategorysToSearch.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item?.id) {
                      setProductCategoryGeneral(item);
                    } else {
                      setProductCategoryGeneral(null);
                    }

                    setOpenDialog(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label={translations[param]["close"]}
            onClick={() => setOpenDialog(false)}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default ProductCategoryComponent;
