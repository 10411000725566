import { localStorageStrings } from "src/constants/localStorageStings";
import { iShoppingProcessItem } from "src/interfaces/shoppingProcessItem";

export default class ShoppingProcessItem implements iShoppingProcessItem {
  id?: string | null;
  quantity: number;
  referenceValue: string;
  productId: string;
  buyerId: string;
  companyContactId: string;
  paymentConditionId: string;
  unitId: string;
  shoppingProcessId: string;
  codeProduct?: string;
  descrptionProduct?: string;
  buyerDescription?: string;
  unitDescription?: string;
  companyContact?: string;
  paymentCondition?: string;
  alvoValue?: string;
  productGenerealId?: string;

  constructor(
    id: string | null,
    quantity: number,
    referenceValue: string,
    productId: string,
    buyerId: string,
    companyContactId: string,
    paymentConditionId: string,
    unitId: string,
    shoppingProcessId: string,
    codeProduct?: string,
    descrptionProduct?: string,
    buyerDescription?: string,
    unitDescription?: string,
    companyContact?: string,
    paymentCondition?: string,
    alvoValue?: string,
    productGenerealId?: string
  ) {
    this.id = id;
    this.quantity = quantity;
    this.referenceValue = referenceValue;
    this.productId = productId;
    this.buyerId = buyerId;
    this.companyContactId = companyContactId;
    this.paymentConditionId = paymentConditionId;
    this.unitId = unitId;
    this.shoppingProcessId = shoppingProcessId;
    this.codeProduct = codeProduct;
    this.descrptionProduct = descrptionProduct;
    this.buyerDescription = buyerDescription;
    this.unitDescription = unitDescription;
    this.companyContact = companyContact;
    this.paymentCondition = paymentCondition;
    this.alvoValue = alvoValue;
    this.productGenerealId = productGenerealId;
  }

  public static adapterToClass(shoppingProcessItem: any) {
    return new ShoppingProcessItem(
      shoppingProcessItem.id,
      shoppingProcessItem.quantidade,
      shoppingProcessItem.valorReferencia,
      shoppingProcessItem.produtoEmpresaId,
      shoppingProcessItem.compradorId,
      shoppingProcessItem.responsavelCompradorId,
      shoppingProcessItem.condicaoPagamentoId,
      shoppingProcessItem.unidadeId,
      shoppingProcessItem.processoCompraId,
      shoppingProcessItem.codigoProdutoEmpresa,
      shoppingProcessItem.descricaoProdutoEmpresa,
      shoppingProcessItem.comprador,
      shoppingProcessItem.unidade,
      shoppingProcessItem.responsavelComprador,
      shoppingProcessItem.condicaoPagamento,
      shoppingProcessItem.valorAlvo,
      shoppingProcessItem.produtoId
    );
  }

  public static adapterToJson(shoppingProcess: any) {
    return JSON.parse(shoppingProcess);
  }

  public static adapterToBody(shoppingProcessItem: iShoppingProcessItem) {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);
    const userJson = JSON.parse(`${userLogged}`);

    const data: any = {
      quantidade: shoppingProcessItem.quantity,
      valorReferencia: shoppingProcessItem.referenceValue,
      produtoEmpresaId: shoppingProcessItem.productId,
      compradorId: shoppingProcessItem.buyerId,
      condicaoPagamentoId: shoppingProcessItem.paymentConditionId,
      unidadeId: shoppingProcessItem.unitId,
      processoCompraId: shoppingProcessItem.shoppingProcessId,
      usuarioSistemaResponsavel: userJson.login,
      responsavelComprador: shoppingProcessItem.companyContactId,
      valorAlvo: shoppingProcessItem.alvoValue,
    };

    if (shoppingProcessItem.id) {
      data["id"] = shoppingProcessItem.id;
    }

    if (shoppingProcessItem?.productGenerealId) {
      data["produtoId"] = shoppingProcessItem.productGenerealId;
    }

    return data;
  }
}
