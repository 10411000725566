import { iInvoicesItems } from "src/interfaces/invoicesItem";

export default class InvoiceItems implements iInvoicesItems {
  id: string;
  quantity: string;
  value: string;
  invoiceId: string;
  productCompanyId: string;
  productCompany?: any;
  notaFiscalItensPedidoItens?: any;

  constructor(
    id: string,
    quantity: string,
    value: string,
    invoiceId: string,
    productCompanyId: string,
    productCompany?: any,
    notaFiscalItensPedidoItens?: any
  ) {
    this.id = id;
    this.quantity = quantity;
    this.value = value;
    this.invoiceId = invoiceId;
    this.invoiceId = invoiceId;
    this.productCompanyId = productCompanyId;
    this.productCompany = productCompany;
    this.notaFiscalItensPedidoItens = notaFiscalItensPedidoItens;
  }

  public static adapterToClass(invoiceItems: any) {
    return new InvoiceItems(
      invoiceItems.id,
      invoiceItems.quantidade,
      invoiceItems.valor,
      invoiceItems.notaFiscalId,
      invoiceItems.produtoEmpresaId,
      invoiceItems.produtoEmpresa,
      invoiceItems.notaFiscalItensPedidoItens
    );
  }

  public static adapterToJson(invoiceItems: any) {
    return JSON.parse(invoiceItems);
  }

  public static adapterToBody(invoicesItems: iInvoicesItems) {
    const data: any = {
      quantidade: invoicesItems.quantity,
      valor: invoicesItems.value,
      notaFiscalId: invoicesItems.invoiceId,
      produtoEmpresaId: invoicesItems.productCompanyId,
    };

    if (invoicesItems.id) {
      data["id"] = invoicesItems.id;
    }

    return data;
  }
}
