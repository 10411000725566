import { iRate } from "src/interfaces/rate";

export default class Rate implements iRate {
  id?: string;
  description: string;
  rate: number;
  companyId?: string;
  companyDescription?: string;
  networkId: string;
  networkDescription: string;
  active: boolean;
  companyReceivedId?: string;
  companyReceived?: string;

  constructor(
    id: string,
    description: string,
    rate: number,
    companyId: string,
    companyDescription: string,
    networkId: string,
    networkDescription: string,
    active: boolean,
    companyReceivedId?: string,
    companyReceived?: string
  ) {
    this.id = id;
    this.description = description;
    this.rate = rate;
    this.companyId = companyId;
    this.companyDescription = companyDescription;
    this.networkId = networkId;
    this.networkDescription = networkDescription;
    this.active = active;
    this.companyReceivedId = companyReceivedId;
    this.companyReceived = companyReceived;
  }

  public static adapterToClass(rate: any) {
    return new Rate(
      rate.id,
      rate.descricao,
      rate.taxa,
      rate.empresaId,
      rate.empresa,
      rate.redeId,
      rate.rede,
      rate.ativo,
      rate.empresaRecebedoraId,
      rate.empresaRecebedora
    );
  }

  public static adapterToJson(address: any) {
    return JSON.parse(address);
  }

  public static adapterToBody(rate: iRate) {
    const data: any = {
      descricao: rate.description,
      taxa: rate.rate,
      ativo: rate.active,
    };

    if (rate.networkId && rate.networkId !== "select") {
      data["redeId"] = rate.networkId;
    }

    if (rate.companyId && rate.companyId !== "select") {
      data["empresaId"] = rate.companyId;
    }

    if (rate.companyReceivedId && rate.companyReceivedId !== "select") {
      data["empresaRecebedoraId"] = rate.companyReceivedId;
    }

    if (rate.id) {
      return {
        id: rate.id,
        ...data,
      };
    }

    return {
      ...data,
    };
  }
}
