import { Card, Checkbox, TableCell, TableRow } from "@mui/material";
import Empty from "src/components/Empty";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import useProduct from "src/hooks/useProduct";
import { useNavigate, useParams } from "react-router-dom";

const RowTableDefault = ({
  header,
  products,
}: {
  header: any;
  products: any;
}) => {
  const router = useNavigate();
  const { lang } = useParams();

  const {
    productSelect,
    loading,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    handleSelectedCheckBox,
    setOrderBy,
  } = useProduct();

  return (
    <Card sx={{ padding: 2, width: "100%" }}>
      {loading ? (
        <CircularLoading />
      ) : products.length === 0 ? (
        <Empty />
      ) : (
        <TableComponent
          header={header}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          funtionToOrder={(item) =>
            item?.orderBackend &&
            setOrderBy({
              number: item?.orderBackend,
              order: item?.order === "desc" ? true : false,
            })
          }
        >
          {products?.map((data: any) => (
            <TableRow
              hover
              key={data.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                onClick={() => {
                  handleSelected(data);
                  router(`/${lang}/settings/products/create`);
                }}
              >
                {data.code}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSelected(data);
                  router(`/${lang}/settings/products/create`);
                }}
              >
                {data.description}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSelected(data);
                  router(`/${lang}/settings/products/create`);
                }}
              >
                {data.categoryProduct}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSelected(data);
                  router(`/${lang}/settings/products/create`);
                }}
              >
                {data.active ? (
                  <CheckCircleIcon color="success" fontSize="small" />
                ) : (
                  <DoNotDisturbAltIcon color="error" fontSize="small" />
                )}
              </TableCell>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={data?.id === productSelect?.id}
                  size="small"
                  onClick={() => handleSelectedCheckBox(data)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableComponent>
      )}
    </Card>
  );
};

export default RowTableDefault;
