import httpClientRegister from "src/http/register";
import { iPage } from "src/interfaces/layout";

class areaConsumer {
  static get(page: iPage) {
    return httpClientRegister.get(
      `/Area?PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }
}

export default areaConsumer;
